import * as M from "./style"
import { useSelector } from "react-redux"
import { hideModal, isModalActive } from "utils/modal"
import BaseModal from "../../base"
import DomainPaymentOptions from "components/parts/payment/domainPaymentOptions"
import { useState } from "react"

const DomainPaymentOptionsModal = () => {

    const show = isModalActive(useSelector((state: any) => state.data.modalStates), "DomainPaymentOptions")

    const [step, setStep] = useState(0)

    return (
        <M.Modal step={step}>
            <BaseModal
                header={false}
                hide={() => hideModal("DomainPaymentOptions", () => setStep(0))}
                show={show}
            >
                <DomainPaymentOptions step={step} setStep={setStep} hide={() => hideModal("DomainPaymentOptions", () => setStep(0))} />
            </BaseModal>
        </M.Modal>
    )

}

export default DomainPaymentOptionsModal