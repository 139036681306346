import styled from 'styled-components'
import { color } from 'styles/theme'

export const TppDeleteVote = styled.div`
    padding:48px;
`

export const Title = styled.div`
    text-align:center;
    font-size:24px;
    font-weight:bold;
    color:${color.black};
    line-height:125%;
`

export const Description = styled.div`
    text-align:center;
    color:${color.gray600};
    font-weight:500;
    font-size:14px;
    margin-top:8px;
    margin-bottom:32px;
`

export const List = styled.div`
    padding:16px;
    background-color:${color.gray50};
    border-radius:8px;
    margin-bottom:32px;
`

export const ListTitle = styled.div`
    font-size:14px;
    color:${color.black};
    font-weight:600;
    line-height:125%;
    margin-bottom:16px;
`

export const ListContainer = styled.div`

`

export const ListItem = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;

    &:not(:last-child){
        margin-bottom:16px;
    }
`

export const ListItemLeft = styled.div`
    display:flex;
    align-items:center;
`

export const ListItemLeftAvatar = styled.div`
    width:48px;
    height:48px;
    margin-right:8px;
    & > img{
        width:100%;
        height:100%;
        border-radius:50%;
    }
`

export const ListItemLeftDetails = styled.div`

`

export const ListItemLeftDetailsName = styled.div`
    font-size:14px;
    font-weight:600;
    color:${color.black};
    line-height:100%;
    margin-bottom:8px;
    display:flex;
    align-items:center;

    & > span{
        margin-left:8px;
        font-size:12px;
        font-weight:500;
        color:${color.blue500};
    }
`

export const ListItemLeftDetailsEmail = styled.div`
    font-size:12px;
    font-weight:500;
    color:${color.gray600};
    line-height:100%;
`

export const ListItemRight = styled.div`

`

export const ListItemRightVoteStatus = styled.div<{votetype:string}>`
    width:116px;
    height:32px;
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius:24px;
    font-size:12px;
    line-height:125%;
    font-weight:500;
    ${({votetype}) => votetype === 'not-voted' && `
        color:${color.gray600};
        background-color:${color.gray100};
    `}
    ${({votetype}) => votetype === 'not-accepted' && `
        color:${color.gray600};
        background-color:${color.gray100};
    `}
    ${({votetype}) => votetype === 'not-accepted-yet' && `
        color:${color.gray600};
        background-color:${color.gray100};
    `}
    ${({votetype}) => votetype === 'voted-delete' && `
        color:${color.green500};
        background-color:${color.green100};
    `}
    ${({votetype}) => votetype === 'voted-no-delete' && `
        color:${color.orange500};
        background-color:${color.orange100};
    `}
    ${({votetype}) => votetype === 'lost-rights' && `
        color:${color.red500};
        background-color:${color.red100};
    `}
`

export const Actions = styled.div`
    display:flex;
    & > button{
        flex:1;
        &:not(:last-child){
            margin-right:8px;
        }
    }
`