import { PublicKey, sendAndConfirmTransaction, Keypair, ComputeBudgetProgram } from '@solana/web3.js'
import * as anchor from "@project-serum/anchor"
import { SOLANA_CONNECTION, WaitForTransaction, anchorWallet } from '../../solana/connection'
import { IDL } from 'modules/solana/mxidl'
import config from 'config'
import { getATokenAccountsNeedCreate } from 'modules/solana/spltoken'
import * as spltoken from "@solana/spl-token";
import toast from 'react-hot-toast'

export const IsDomainExists = async (domain: string) => {

    const anchorConnection = new anchor.AnchorProvider(SOLANA_CONNECTION, anchorWallet(), anchor.AnchorProvider.defaultOptions())
    const program = new anchor.Program(IDL as anchor.Idl, config.WEB3.SOLANA.MX_PROGRAM, anchorConnection)

    const [domainPDA, _] = anchor.web3.PublicKey.findProgramAddressSync(
        [anchor.utils.bytes.utf8.encode('domain'), anchor.utils.bytes.utf8.encode(domain)],
        program.programId
    )

    try {
        const domainAccount = await program.account.domainAccount.fetch(domainPDA)

        if (!domainAccount)
            return false

        return true
    } catch (e) {
        return false
    }

}

export const BuyDomain = async (domain: string, authority: any, cloudWallet: Keypair, setTxStatus:any) => {

    const anchorConnection = new anchor.AnchorProvider(SOLANA_CONNECTION, anchorWallet(), anchor.AnchorProvider.defaultOptions())
    const program = new anchor.Program(IDL as anchor.Idl, config.WEB3.SOLANA.MX_PROGRAM, anchorConnection)

    let isExists = await IsDomainExists(domain)

    if (isExists)
        throw new Error("Domain already exists")

    const authorityPubkey = new PublicKey((await authority.address())[0])
    const programConfig = (await program.account.config.all())[0]
    const adminPk : any= programConfig.account.admin

    const { atokenix, destatokenAccount } = await getATokenAccountsNeedCreate(SOLANA_CONNECTION, authorityPubkey, adminPk, new PublicKey(config.WEB3.SOLANA.USDC_PK))

    const preInstructions = [];

    if (atokenix) {
        preInstructions.push(atokenix);
    }

    

    const tokenAccount = await spltoken.getAssociatedTokenAddress(new PublicKey(config.WEB3.SOLANA.USDC_PK), authorityPubkey)

    const [domainAccountPDA, ____] = anchor.web3.PublicKey.findProgramAddressSync(
        [anchor.utils.bytes.utf8.encode('domain'), new TextEncoder().encode(domain)],
        program.programId
    )

    const modifyComputeUnits = ComputeBudgetProgram.setComputeUnitLimit({
        units: 1000000
    });

    const addPriorityFee = ComputeBudgetProgram.setComputeUnitPrice({
        microLamports: 1
    });

    const buytx = await program.methods.buyDomain(
        Buffer.from((new TextEncoder()).encode(domain)),
    ).accounts({
        payer: authorityPubkey,
        admin: adminPk,
        owner: cloudWallet.publicKey,
        config: programConfig.publicKey,
        mintAccount: new PublicKey(config.WEB3.SOLANA.USDC_PK),
        adminTokenAccount: destatokenAccount,
        tokenAccount,
        domainAccount: domainAccountPDA,
        tokenProgram: spltoken.TOKEN_PROGRAM_ID,
        associatedTokenProgram: spltoken.ASSOCIATED_TOKEN_PROGRAM_ID,
        systemProgram: anchor.web3.SystemProgram.programId,

    }).preInstructions(preInstructions).transaction()

    const tx = new anchor.web3.Transaction().add(modifyComputeUnits).add(addPriorityFee).add(buytx)

    tx.recentBlockhash = (await SOLANA_CONNECTION.getLatestBlockhash("finalized")).blockhash
    tx.feePayer = authorityPubkey
    tx.partialSign(cloudWallet)

    const signed = await authority.signTransaction(tx)

    const sig = await SOLANA_CONNECTION.sendRawTransaction(signed.serialize(), { skipPreflight: false, preflightCommitment: "finalized" });
    setTxStatus("pending")
    console.log("Transaction sent", sig)

    let confirm = await WaitForTransaction(sig)

    return true
}