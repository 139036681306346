export function IconArrowLeft() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 16 16"
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M8.707.293a1 1 0 010 1.414L3.414 7H15a1 1 0 110 2H3.414l5.293 5.293a1 1 0 11-1.414 1.414l-7-7a1 1 0 010-1.414l7-7a1 1 0 011.414 0z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export function IconChevronDown() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 8"
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M.293.293a1 1 0 011.414 0L7 5.586 12.293.293a1 1 0 111.414 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export function IconInfoCircle() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 22 22"
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M11 2a9 9 0 100 18 9 9 0 000-18zM0 11C0 4.925 4.925 0 11 0s11 4.925 11 11-4.925 11-11 11S0 17.075 0 11zm10-4a1 1 0 011-1h.01a1 1 0 110 2H11a1 1 0 01-1-1zm1 3a1 1 0 011 1v4a1 1 0 11-2 0v-4a1 1 0 011-1z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export function IconPassword() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="16"
            fill="none"
            viewBox="0 0 19 16"
        >
            <path
                fill="#101828"
                fillRule="evenodd"
                d="M3.8.333H15.2c.439 0 .818 0 1.13.026.33.027.657.086.972.247a2.5 2.5 0 011.093 1.092c.16.316.22.643.247.973.025.312.025.69.025 1.13v2.366a.833.833 0 01-1.666 0V3.833c0-.48-.001-.79-.02-1.027-.019-.226-.05-.31-.071-.351a.833.833 0 00-.364-.364c-.042-.021-.126-.053-.352-.071A14.17 14.17 0 0015.166 2H3.833c-.48 0-.79 0-1.027.02-.226.018-.31.05-.351.07a.833.833 0 00-.365.365c-.02.041-.052.125-.07.351C2 3.043 2 3.353 2 3.833v3c0 .48 0 .79.02 1.027.018.226.05.31.07.352.08.156.208.284.365.364.04.02.125.052.351.07.236.02.547.02 1.027.02h4.833a.833.833 0 110 1.667H3.801c-.44 0-.818 0-1.13-.025-.33-.027-.658-.087-.973-.247A2.5 2.5 0 01.605 8.968c-.16-.315-.22-.643-.247-.972-.025-.312-.025-.691-.025-1.13V3.8c0-.44 0-.818.025-1.13.027-.33.087-.657.247-.973A2.5 2.5 0 011.698.606c.315-.16.643-.22.972-.247.313-.026.692-.026 1.13-.026zm.491 5a1.042 1.042 0 112.084 0 1.042 1.042 0 01-2.084 0zm4.167 0a1.042 1.042 0 112.083 0 1.042 1.042 0 01-2.083 0zm4.167 0a1.042 1.042 0 112.083 0 1.042 1.042 0 01-2.083 0zm-.834 4.375a2.292 2.292 0 014.584 0v.649c.168.02.358.06.548.158.314.16.569.415.728.728.116.226.152.451.168.642.014.173.014.378.014.588v.72c0 .21 0 .415-.014.589-.016.19-.052.415-.168.641-.16.314-.414.569-.728.729a1.685 1.685 0 01-.641.167c-.174.014-.379.014-.59.014h-3.219c-.21 0-.415 0-.589-.014a1.685 1.685 0 01-.641-.167 1.667 1.667 0 01-.728-.729 1.684 1.684 0 01-.168-.641c-.014-.174-.014-.379-.014-.589v-.72c0-.21 0-.415.014-.588.016-.19.052-.415.168-.642.16-.313.414-.568.728-.728.19-.097.38-.138.548-.158v-.649zM12.5 12a6.847 6.847 0 00-.49.01l-.002.01a6.783 6.783 0 00-.008.48v.667a6.783 6.783 0 00.01.49 6.847 6.847 0 00.49.01h3.166a6.845 6.845 0 00.49-.01l.002-.01c.008-.098.008-.233.008-.48V12.5c0-.247 0-.382-.008-.48l-.001-.01a6.845 6.845 0 00-.49-.01H12.5zm2.208-1.667h-1.25v-.625a.625.625 0 111.25 0v.625z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export function IconPlus() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 16 16"
        >
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M8 1v14M1 8h14"
            ></path>
        </svg>
    );
}

export function IconRefresh() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
        >
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M18.453 10.893A8.5 8.5 0 012.638 14.25l-.25-.433m-.842-4.71A8.5 8.5 0 0117.361 5.75l.25.433M1.493 16.066l.732-2.732 2.732.732m10.085-8.132l2.732.732.732-2.732"
            ></path>
        </svg>
    );
}

export function IconSearch() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
        >
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 19l-6-6m2-5A7 7 0 111 8a7 7 0 0114 0z"
            ></path>
        </svg>
    );
}

export function IconTimes() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M.293.293a1 1 0 011.414 0L7 5.586 12.293.293a1 1 0 111.414 1.414L8.414 7l5.293 5.293a1 1 0 01-1.414 1.414L7 8.414l-5.293 5.293a1 1 0 01-1.414-1.414L5.586 7 .293 1.707a1 1 0 010-1.414z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export function IconTrashCan() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 22"
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M9.161 0h1.678c.527 0 .981 0 1.356.03.395.033.789.104 1.167.297a3 3 0 011.311 1.311c.193.378.264.772.296 1.167.028.336.03.736.031 1.195h4a1 1 0 110 2h-1v10.241c0 .805 0 1.47-.044 2.01-.046.563-.145 1.08-.392 1.565a4 4 0 01-1.748 1.748c-.485.247-1.002.346-1.564.392-.541.044-1.206.044-2.01.044H7.758c-.805 0-1.47 0-2.01-.044-.563-.046-1.08-.145-1.565-.392a4 4 0 01-1.748-1.748c-.247-.485-.346-1.002-.392-1.564C2 17.71 2 17.046 2 16.242V6H1a1 1 0 010-2h4c0-.459.003-.86.03-1.195.033-.395.104-.789.297-1.167A3 3 0 016.638.327C7.016.134 7.41.063 7.805.031 8.18 0 8.635 0 9.161 0zM4 6v10.2c0 .857 0 1.439.038 1.889.035.438.1.663.18.819a2 2 0 00.874.874c.156.08.38.145.819.18C6.361 20 6.943 20 7.8 20h4.4c.857 0 1.439 0 1.889-.038.438-.035.663-.1.819-.18a2 2 0 00.874-.874c.08-.156.145-.38.18-.819.037-.45.038-1.032.038-1.889V6H4zm9-2H7c0-.465.004-.783.024-1.032.022-.272.06-.373.085-.422a1 1 0 01.437-.437c.05-.025.15-.063.422-.085C8.25 2 8.623 2 9.2 2h1.6c.577 0 .949 0 1.232.024.272.022.373.06.422.085a1 1 0 01.437.437c.025.05.063.15.085.422.02.249.023.567.024 1.032zM8 9.5a1 1 0 011 1v5a1 1 0 11-2 0v-5a1 1 0 011-1zm4 0a1 1 0 011 1v5a1 1 0 11-2 0v-5a1 1 0 011-1z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}


export function IconUser() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 12 14"
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M6 1.667a2.333 2.333 0 100 4.666 2.333 2.333 0 000-4.666zM2.333 4a3.667 3.667 0 117.334 0 3.667 3.667 0 01-7.334 0zm1.885 4.667H7.783c.818 0 1.376 0 1.851.143a3.333 3.333 0 012.223 2.222c.144.475.143 1.034.143 1.852V13a.667.667 0 01-1.333 0c0-.979-.008-1.32-.086-1.58a2 2 0 00-1.334-1.334c-.26-.079-.601-.086-1.58-.086H4.333c-.978 0-1.32.007-1.58.086A2 2 0 001.42 11.42c-.08.26-.087.602-.087 1.581A.667.667 0 010 13v-.116c0-.818 0-1.377.144-1.852A3.333 3.333 0 012.366 8.81c.475-.144 1.033-.144 1.852-.143z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export function IconCheck() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 16 11"
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M15.256.41a.833.833 0 010 1.18l-9.167 9.166a.833.833 0 01-1.178 0L.744 6.589a.833.833 0 111.179-1.178L5.5 8.988 14.077.411a.833.833 0 011.179 0z"
          clipRule="evenodd"
        ></path>
      </svg>
    );
  }