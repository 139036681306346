import React from 'react'
import * as C from './style'

const Loading = () => {
    return (
        <C.Loading>
        </C.Loading>
    )

}

export default Loading