import styled from 'styled-components'
import { color } from 'styles/theme'

export const EmptyContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex:1;
    height: calc(100vh - 88px);

    & img{
        width: 264px;
        height: 264px;
    }
`

export const EmptyContentText = styled.div`
    font-size: 20px;
    font-weight: 600;
    line-height: 125%;
    color: ${color.gray700};
    text-align: center;
`