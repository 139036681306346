import { Routes, Route, useLocation, Navigate, useNavigate } from "react-router-dom"

// pages
import Home from "pages/home"
import Domains from "pages/domain"
import Mail from "pages/mail"
import { autoLogin } from "services/api/auth"
import { useState, useEffect } from "react"
import { setUser, setCurrentAccount } from "services/redux/slices/user"
import { getLocalData, removeLocalData, setLocalData } from "utils/localData"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import ProtectedRoute from "components/router/protectedRoute"
import { setServerPublicKey } from "services/redux/slices/mail"
import Dashboard from "pages/dashboard"
import Loading from "components/parts/other/loading"

export default () => {

  const [loaded, setLoaded] = useState<null|boolean>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    if (getLocalData("token") && getLocalData("key")) {
      setLoaded(false)
      autoLogin().then((res) => {
        dispatch(setUser({
          accounts: res.data.accounts,
          names: res.data.names,
          cloudWallet: res.data.cloudWallet,
        }))
        let mailxAddress = getLocalData("selectedAccount") || res.data.accounts[0].address
        if (!res.data.accounts.includes(mailxAddress)) {
          mailxAddress = res.data.accounts[0].address
        }
        dispatch(setCurrentAccount(mailxAddress))
        dispatch(setServerPublicKey(res.data.c))
        setLoaded(true)
        setLocalData("token", res.data.token)
      }).catch((err) => {

        console.log(err)

        if (err.response.status === 401) {
          removeLocalData("token")
          removeLocalData("key")
        } else {
          console.log(err)
        }
        setLoaded(true)
      })
    } else {
      setLoaded(true)
    }
  }, [])

  if (loaded === null)
    return (<></>)

  if (loaded === false)
    return (
      <LoadingCont>
        <Loading />
      </LoadingCont>
    )

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/domains" element={<Domains />} />
      <Route path="/mail/" element={<ProtectedRoute children={<Mail />} />} />
      <Route path="/mail/:page" element={<ProtectedRoute children={<Mail />} />} />
      <Route path="/mail/:page/:subpage" element={<ProtectedRoute children={<Mail />} />} />
      <Route path="/dashboard/" element={<ProtectedRoute children={<Dashboard />} />} />
      <Route path="/logout/" element={<ProtectedRoute children={<Logout />} />} />
    </Routes>
  );
}

const LoadingCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

const Logout = () => {

  useEffect(() => {

    removeLocalData("token")
    removeLocalData("key")
    removeLocalData("selectedAccount")

    window.location.href = "/"

  }, [])

  return (<></>)

}