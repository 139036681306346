import { InputGroup } from 'components/ui/input/style'
import styled from 'styled-components'
import { color } from 'styles/theme'

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 88px;
    padding:0 24px;
    border-bottom: 2px solid ${color.gray100};
    background-color: ${color.white};
    width: 100%;
`



export const Search = styled.div`
    width: 420px;
    display: flex;
    & ${InputGroup} {
        flex:1
    }

    & input{
        border: none;
        background-color: ${color.gray100};
    }
`

export const Filter = styled.div`
    position: relative;
    margin-left:32px;
`

export const FiltersBubble = styled.div`
    position: absolute;
    top: 4px;
    right: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${color.blue500};
`

export const FilterIcon = styled.div`
    background-color: ${color.gray100};
    display: flex;
    align-items: center;
    justify-content: center;
    & svg{
        width:18px;
    }
    border-radius: 8px;
    width: 50px;
    height: 50px;
    cursor: pointer;
`

export const FilterDropdown = styled.div`
    position: absolute;
    top: 56px;
    right: 0;
    z-index: 18;
    background-color: ${color.white};
    width:235px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.16);
    font-size: 14px;
`

export const FilterDropdownItem = styled.div`
    padding: 16px;
    cursor: pointer;
    &:hover{
        background-color: ${color.gray50};
    }
    display: flex;
    align-items: center;
    user-select: none;
`

export const FilterDropdownItemCheckbox = styled.div<{active?:string}>`
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 1px solid ${color.black};
    background-color: ${props => props.active === 'true' ? color.black : color.white};
    cursor: pointer;
    color: ${color.white};

    & svg {
        height:11px;
        display: ${props => props.active === 'true' ? 'block' : 'none'};
    }
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const FilterDropdownItemText = styled.div`

`

export const FilterDropdownSort = styled.div`
    
`

export const FilterDropdownSortText = styled.div`
    padding: 16px;
    background-color: ${color.gray50};
    cursor: pointer;
    &:hover{
        background-color: ${color.gray100};
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    & svg {
        height:12px;
    }
    user-select: none;
`

export const FilterDropdownSortContent = styled.div`

`

export const FilterDropdownSortContentItem = styled.div`
    padding: 16px;
    cursor: pointer;
    &:hover{
        background-color: ${color.gray50};
    }
    display: flex;
    align-items: center;
    user-select: none;
`

export const Right = styled.div`
    display: flex;
    align-items: center;
`

export const Notifications = styled.div`
    margin-right: 16px;
    position: relative;
`

export const NotificationsIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 50px;
    height: 50px;
    border-radius: 8px;
    background-color: ${color.gray100};
    & svg {
        height:20px;
    }
    color: ${color.black};
    position: relative;
    user-select: none;
`

export const NotificationsCount = styled.div`
    position: absolute;
    top: -4px;
    right: -4px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${color.blue500};
    color: ${color.white};
    font-size: 10px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    
`

export const NotificationsOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index:19;
`


export const BankAccounts = styled.div`
    margin-right: 16px;
    position: relative;
`

export const BankAccountsIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 50px;
    height: 50px;
    border-radius: 8px;
    background-color: ${color.gray100};
    & svg {
        height:20px;
    }
    color: ${color.black};
    position: relative;
    user-select: none;
`

export const BankAccountsOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index:19;
`