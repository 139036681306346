import { useEffect, useState } from 'react'
import * as C from "./style"
import { useSelector } from 'react-redux'
import { getModalCallbacks, getModalProps } from 'utils/modal'
import SolanaWallet from './solanawallets'
import EvmWallet from './evmwallets'

const WalletConnect = () => {

    const modalCallbacks = getModalCallbacks("WalletConnectModal")
    const [data, setData] = useState<any>(null)
    const [walletType, changeWalletType] = useState<any>('')


    const onWalletConnect = (wallet:any) => {
        modalCallbacks.onConnect(walletType, wallet)
    }

    const back = () => {
        changeWalletType('')
    }
    

    if (walletType === 'solana'){
        return (
            <C.WalletPayment>
                <SolanaWallet back={back} onWalletConnect={onWalletConnect} />
            </C.WalletPayment>
        )
    }

    if (walletType === 'bsc' || walletType === 'avalanche'){
        return (
            <C.WalletPayment>
                <EvmWallet back={back} onWalletConnect={onWalletConnect} network={walletType}/>
            </C.WalletPayment>
        )
    }

    return (
        <C.WalletConnect>
            <C.Title>
                Select Network
            </C.Title>
            <C.Subtitle>
                Select the network you want to continue with
            </C.Subtitle>
            <C.WalletOptions>
                <C.WalletOption ptype='solana' onClick={() => changeWalletType('solana')}>
                    <C.WalletOptionImage> <img src="/images/chains/sol.png" /></C.WalletOptionImage>
                    <C.WalletOptionTitle>Use Solana</C.WalletOptionTitle>
                </C.WalletOption>
                <C.WalletOption ptype='eth' onClick={() => changeWalletType('avalanche')}>
                    <C.WalletOptionImage> <img src="/images/chains/eth.png" /></C.WalletOptionImage>
                    <C.WalletOptionTitle>Use Avalanche</C.WalletOptionTitle>
                </C.WalletOption>
                <C.WalletOption ptype='bnb' onClick={() => changeWalletType('bsc')}>
                    <C.WalletOptionImage> <img src="/images/chains/bnb.png" /></C.WalletOptionImage>
                    <C.WalletOptionTitle>Use BNB Chain</C.WalletOptionTitle>
                </C.WalletOption>
            </C.WalletOptions>
        </C.WalletConnect>
    )

}

export default WalletConnect