import React, { useState } from 'react'
import * as C from './style'
import { Button } from 'components/ui/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

const LoginMethod = (props:any) => {

    return (
        <C.LoginMethod>

            <C.BackButton onClick={props.back}>
                <FontAwesomeIcon icon={faArrowLeft} />
            </C.BackButton>

            <C.Title order="1">
                Choose a Login Method For
            </C.Title>
            <C.Title>
                Your Account
            </C.Title>
            <C.Description>
                Choose a login method for the <span>{props.mailxAddress}@mailx</span> account. 
            </C.Description>
            <C.Description>
                Either with your crypto wallet or directly connect your 
            </C.Description>
            <C.Description>
                account to the password.
            </C.Description>

            <C.LoginMethods>
                <Button onClick={() => props.onLoginMethodFinish("password")} variant="primary" size="large" block>Use Password</Button>
                <Button onClick={() => props.onLoginMethodFinish("wallet")} variant="secondary" size="large" block>Connect Crypto Wallet</Button>
            </C.LoginMethods>
        </C.LoginMethod>
    )

}

export default LoginMethod