export default {
    API:{
        URL: 'https://mailx2-be.vercel.app/api/',
        DRIVE: 'http://localhost:27014/',
        IPFS: 'https://crimson-territorial-anteater-987.mypinata.cloud/ipfs/'
    },
    SECRET:{
        LOCAL_STORAGE: 'pbuVY7Ds37T3sUz'
    },
    WEB3:{
        SOLANA:{
            RPC: 'https://api.devnet.solana.com',
            MX_PROGRAM:"M1L8X1wrht7BAXBuyFxMuuMgM4KYbGtwDd2ALdM6qz7",
            USDC_PK: "3x9H95aybMSgPyFAmaoQrNsCq5cCDxx1kiP2sihfNYqw",
            USDC_MINTS:[
                "ApAmUBDwBtryRt4g6ZmMd5TE1XC2QDSVBzbNYVjbAKgg"
            ]
        },
        EVM:{
            BSC:{
                CHAIN_ID: 97,
                RPC: 'https://data-seed-prebsc-2-s1.binance.org:8545/',
                SYMBOL: 'tBNB',
                DECIMALS: 18,
                NAME: 'Binance Smart Chain Testnet',
                BLOCK_EXPLORER_URL: 'https://testnet.bscscan.com'
            },
            ETH:{
                CHAIN_ID: 5,
                RPC: 'https://goerli.blockpi.network/v1/rpc/public',
                SYMBOL: 'GoerliETH',
                DECIMALS: 18,
                NAME: 'Goerli Testnet',
                BLOCK_EXPLORER_URL: 'https://goerli.etherscan.io'
            }
        },
        WORMHOLE:{
            VAA_RPC:"https://wormhole-v2-testnet-api.certus.one",
            BSC:{
                TOKEN_BRIDGE: "0x9dcF9D205C9De35334D646BeE44b2D2859712A09",
                BRIDGE:"0x68605AD7b15c732a30b1BbC62BE8F2A509D74b4D",
                NAME:"bsc",
                TOKENS:{
                    USDC: "0x64544969ed7EBf5f083679233325356EbE738930",
                }
            },
            SOLANA:{
                TOKEN_BRIDGE: "DZnkkTmCiFWfYTfT41X3Rd1kDgozqzxWaHqsw6W4x2oe",
                BRIDGE:"3u8hJUVTA4jH1wYAyUur7FFZVQ8H635K3tSHHF4ssjQ5",
                NAME:"solana",
                TOKENS:{
                    USDC: "3x9H95aybMSgPyFAmaoQrNsCq5cCDxx1kiP2sihfNYqw",
                }
            },
            ETH:{
                TOKEN_BRIDGE: "0xF890982f9310df57d00f659cf4fd87e65adEd8d7",
                BRIDGE:"0x706abc4E45D419950511e474C7B9Ed348A4a716c",
                NAME:"ethereum",
                TOKENS:{
                    USDC: "0x07865c6e87b9f70255377e024ace6630c1eaa37f",
                }
            }
        },
        RPC:{
            BSC:"https://data-seed-prebsc-2-s1.binance.org:8545/"
        }
    }   
}