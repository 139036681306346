import styled from "styled-components"
import { Dialog, DialogHeader } from "../../base/style"

export const Modal = styled.div`
    & ${Dialog} {
        max-width: 543px;
        width: 100%;
        padding:0px;
    }
`

