import React, { useState } from 'react'
import * as C from './style'
import { Button } from 'components/ui/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { SolanaWallets } from 'modules/wallet/solana'
import { CreateRegisterWalletVerification, VerifyRegisterWallet } from 'services/api/auth'
import { toast } from 'react-hot-toast'

const WalletPage = (props: any) => {

    const [loading, setLoading] = useState(false)

    const connect = (wallet: "phantom" | "solflare") => {

        const walletClass = SolanaWallets[wallet]

        if (walletClass) {
            const wallet = new walletClass()

            if (!walletClass.isInstalled()) {
                wallet.install()
                return;
            }


            wallet.connect().then(async () => {

                if (walletClass.isConnected()) {

                    let address = await wallet.address()
                    setLoading(true)
                    CreateRegisterWalletVerification(address[0], "solana").then((res: any) => {
                        wallet.signMessage(res.data.message, res.data.nonce).then((signature: any) => {
                            VerifyRegisterWallet(address[0], signature).then((res: any) => {
                                props.onWalletFinish(address[0], signature)
                            }).catch((err: any) => {
                                console.log(err)
                                toast.error("Error while verifying wallet")
                                setLoading(false)
                            })
                        }).catch((err: any) => {
                            console.log(err)
                            setLoading(false)
                        })
                    }).catch((err: any) => {
                        console.log(err)
                        toast.error("Error while creating wallet verification")
                        setLoading(false)
                    })
                }
            }).catch((err: any) => {
                console.log(err)
                setLoading(false)
            })
        }

    }

    return (
        <C.WalletPage>

            {loading && (
                <C.PageLoading>
                    <FontAwesomeIcon icon={faSpinner} spin />
                </C.PageLoading>
            )}

            <C.BackButton onClick={props.back}>
                <FontAwesomeIcon icon={faArrowLeft} />
            </C.BackButton>

            <C.Title>
                Connect Your Wallet
            </C.Title>
            <C.Description>
                Select the wallet to connect to your MailX account
            </C.Description>
            <C.Steps>
                <C.Step ></C.Step>
                <C.Step ></C.Step>
                <C.Step active="true"></C.Step>
            </C.Steps>

            <C.Wallets>
                <C.Wallet which='phantom' onClick={() => connect('phantom')}>
                    <C.WalletIcon>
                        <img src="/images/wallets/phantom.png" alt="phantom" />
                    </C.WalletIcon>
                    <C.WalletName>
                        Phantom Wallet
                    </C.WalletName>
                </C.Wallet>
                <C.Wallet which='backpack'>
                    <C.WalletIcon>
                        <img src="/images/wallets/backpack.png" alt="backpack" />
                    </C.WalletIcon>
                    <C.WalletName>
                        Backpack Wallet
                    </C.WalletName>
                </C.Wallet>
                <C.Wallet which='solflare' onClick={() => connect('solflare')} >
                    <C.WalletIcon>
                        <img src="/images/wallets/solflare.png" alt="solflare" />
                    </C.WalletIcon>
                    <C.WalletName>
                        Solflare Wallet
                    </C.WalletName>
                </C.Wallet>
            </C.Wallets>

            <C.WalletOr>
                <C.WalletOrLine></C.WalletOrLine>
                <C.WalletOrText>or</C.WalletOrText>
                <C.WalletOrLine></C.WalletOrLine>
            </C.WalletOr>

            <C.WalletsBottom>
                <Button variant="secondary" size="medium" block>Connect Ledger</Button>
            </C.WalletsBottom>

        </C.WalletPage>
    )

}

export default WalletPage