import { ethers } from "ethers"
import { WalletProvider } from "models/interfaces/walletProvider"
import { getRpcForNetwork } from "utils/helpers"
import ERC20Abi from "utils/static/erc20.abi.json"

export const increaseERC20Allowance = async (network:'bsc'|'avalanche', tokenAddress:string, spender:string, amount:string, wallet:WalletProvider) => {
    const provider = new ethers.providers.Web3Provider(wallet.getProvider())
    const tokenContract = new ethers.Contract(tokenAddress, ERC20Abi, provider)
    const signer = provider.getSigner()

    const increasedAmount = ethers.utils.parseUnits(amount, 18)
    const tx = await tokenContract.connect(signer).increaseAllowance(spender, increasedAmount)
    await tx.wait()

    return tx.hash
}