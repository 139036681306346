import styled from "styled-components"
import { Dialog, DialogHeader } from "../../base/style"

export const Modal = styled.div<{step:number}>`
    & ${Dialog} {
        max-width: 590px;
        width: 100%;
        padding:0px;

        ${props => (props.step === 1) && `
            max-width: 543px;
        `}

        ${props => (props.step === 2) && `
            max-width: 462px;
        `}
    }
`

