import { setModalStates } from "services/redux/slices/data"
import { store } from "services/redux/store"

const modalCallbacks: any = {}

export const isModalActive = (modalStates: any[], modalName: string) => {
    return modalStates.some((modal: any) => modal.n === modalName)
}

export const getModalCallbacks = (modalName: string) => {
    return modalCallbacks[modalName];
}

export const getModalProps = (modalStates: any[], modalName: string) => {
    let modal = modalStates.find((modal: any) => modal.n === modalName)
    if (modal) {
        return modal.props
    }
    return null
}

export const updateModalProps = (modalName: string, props: any) => {
    let modalStates = [...store.getState().data.modalStates]
    let index = modalStates.findIndex((modal: any) => modal.n === modalName)
    if (index !== -1) {
        modalStates[index] = { n: modalName, props: props }
    }
    store.dispatch(setModalStates(modalStates))
}

export const hideModal = (modalName: string, callback?: any) => {
    let modalStates = [...store.getState().data.modalStates]
    modalStates = modalStates.filter((modal: any) => modal.n !== modalName)
    store.dispatch(setModalStates(modalStates))

    if (callback) {
        setTimeout(() => {
            callback()
        }, 100);
    }

    delete modalCallbacks[modalName]
}

export const showModal = (modalName: string, props?: any, callbacks?: any) => {
    let modalStates = [...store.getState().data.modalStates]
    modalStates.push({ n: modalName, props: props })

    if (callbacks) {
        modalCallbacks[modalName] = callbacks
    }
    store.dispatch(setModalStates(modalStates))
}