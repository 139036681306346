import axios from 'axios'
import config from 'config'
import { getLocalData } from 'utils/localData'

export const register = (mailAddress: string, password: string, name:string, wallet?:string) => {

    return axios.post(config.API.URL + "user/auth/register", {
        mailAddress,
        password,
        name,
        wallet
    })
}

export const login = (mailAddress: string, password: string) => {
    return axios.post(config.API.URL + "user/auth/login", {
        mailAddress,
        password
    })
}

export const autoLogin = () => {
    return axios.get(config.API.URL + "user/auth/auto-login", {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}

export const CreateRegisterWalletVerification = (address: string, network:string) => {
    return axios.post(config.API.URL + "user/auth/register-wallet-verification", {
        address,
        network
    })
}

export const VerifyRegisterWallet = (address: string, message:string) => {
    return axios.post(config.API.URL + "user/auth/verify-register-wallet", {
        address,
        message
    })
}

export const CheckLoginMethod = (address: string) => {
    return axios.post(config.API.URL + "user/auth/check-login-method", {
        address
    })
}

export const VerifyLoginWallet = (mailAddress: string, message:string) => {
    return axios.post(config.API.URL + "user/auth/login-with-wallet", {
        mailAddress,
        message
    })
}