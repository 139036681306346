import React, { useMemo, useState } from 'react'
import * as C from './style'
import { Button } from 'components/ui/button'
import { useYupValidationResolver } from 'hooks/useYupValidationResolver'
import { useForm, Controller } from "react-hook-form"
import * as yup from "yup"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-regular-svg-icons'
import { InputGroup } from 'components/ui/input'
import { Input } from 'components/ui/input'

const Name = (props: any) => {

    const schema = useMemo(
        () =>
            yup.object({
                name: yup.string().default(props.name).required()
            }),
        []
    )

    const resolver = useYupValidationResolver(schema)
    const { handleSubmit, control, formState: { errors } } = useForm({ resolver })

    const onSubmit = (data: any) => {
        props.onNameFinish(data.name)
    }

    return (
        <C.Name>

            <C.BackButton onClick={props.back}>
                <FontAwesomeIcon icon={faArrowLeft} />
            </C.BackButton>

            <C.Title >
                So, What's Your Name?
            </C.Title>

            <C.Steps>
                <C.Step ></C.Step>
                <C.Step active="true"></C.Step>
                <C.Step></C.Step>
            </C.Steps>

            <C.PasswordForm onSubmit={handleSubmit((data) => onSubmit(data))}>

                <InputGroup settings={{
                    icon: <FontAwesomeIcon icon={faUser} />,
                    label: "Your Name",
                    hint: errors.name?.message as string,
                    state: errors.name ? "error" : "default"
                }}>
                    <Controller name="name" control={control} render={({ field }) =>
                        <Input onBlur={field.onBlur} onChange={field.onChange} checked={field.value} xref={field.ref} defaultValue={props.name} type='text' placeholder='enter your name' autoFocus />}
                    />
                </InputGroup>

                <Button type="submit" variant="primary" size="large" block>Continue</Button>

            </C.PasswordForm>
        </C.Name>
    )

}

export default Name