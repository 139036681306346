import React, { useMemo } from 'react'
import * as C from './style'
import Logo from 'components/ui/logo'
import * as yup from "yup"
import { Button } from 'components/ui/button'
import { useYupValidationResolver } from 'hooks/useYupValidationResolver'
import { useForm } from 'react-hook-form'

const Address = (props:any) => {

    const schema = useMemo(
        () =>
            yup.object({//disallow special characters
                mailxAddress: yup.string().default(props.mailxAddress).matches(/^[a-zA-Z0-9]+$/, "MailX address can only contain letters and numbers").required()
            }),
        []
    )

    const resolver = useYupValidationResolver(schema)
    const { register, handleSubmit, control, formState: { errors } } = useForm({ resolver })

    const onSubmit = (data: any) => {
        props.onAddressFinish(data.mailxAddress)
    }

    return (
        <C.Address>
            <C.TopLogo>
                <Logo />
            </C.TopLogo>
            <C.Title order="0">
                Welcome To <span>Secure Messaging</span>
            </C.Title>
            <C.Title>
                Part Of The Internet
            </C.Title>
            <C.Description>
                To register, first find a suitable MailX address.
            </C.Description>

            <C.Steps>
                <C.Step active="true"></C.Step>
                <C.Step></C.Step>
                <C.Step></C.Step>
            </C.Steps>

            <C.Form onSubmit={handleSubmit((data) => onSubmit(data))} autoComplete="off">
                <C.EMailInputGroup state={errors.mailxAddress ? "error" : "default"}>
                    <C.EmailInputLabel>
                        MailX Address
                    </C.EmailInputLabel>
                    <C.EMailInput>
                        <C.EMailInputObject {...register("mailxAddress")} defaultValue={props.mailxAddress} type="text" autoFocus autoCorrect='false' placeholder='your mailx address' />
                        <C.EMailInputDomain>@mailx</C.EMailInputDomain>
                    </C.EMailInput>
                    {errors.mailxAddress && <C.EMailInputHint>{errors.mailxAddress?.message as string}</C.EMailInputHint>}
                </C.EMailInputGroup>

                <Button type="submit" variant="primary" size="large" block>Continue</Button>
            </C.Form>
        </C.Address>
    )

}

export default Address