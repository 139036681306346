import bs58 from "bs58"
import { WalletProvider } from "models/interfaces/walletProvider"
import { WalletProviderDetails } from "models/interfaces/walletProviderDetails"

export class Phantom extends WalletProvider {

    constructor() {
        super()
        Phantom.isInstalled() && this.getProvider().on('accountChanged', () => {
            this.disconnect()
        })
    }

    static provider = WalletProviderDetails.phantom

    static isInstalled(): boolean {
        return (window as any).phantom && (window as any).phantom.solana && (window as any).phantom.solana.isPhantom
    }

    getProvider(): any {
        return (window as any).phantom.solana
    }

    static isConnected(): boolean {
        return Phantom.isInstalled() && (window as any).phantom.solana.isConnected
    }

    async connect(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            if (!Phantom.isInstalled())
                return reject("phantom is not installed")

            try {
                await this.getProvider().connect({ onlyIfTrusted: true })
            }
            catch (err) {
                try {
                    await this.getProvider().connect()
                }
                catch (err) {
                    return reject("failed to connect")
                }
            }

            resolve(true)
        })
    }

    async tryConnect(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            if (!Phantom.isInstalled())
                return reject(false)

            try {
                await this.getProvider().connect({ onlyIfTrusted: true })
            }
            catch (err) {
                return reject(false)
            }

            resolve(true)
        })
    }

    async disconnect(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            if (!Phantom.isInstalled())
                return reject(false)

            try {
                await this.getProvider().request({ method: "disconnect" })
            }
            catch (err) {
                return reject(false)
            }

            resolve(true)
        })
    }

    setOnDisconnect(callback: () => void): void {
        this.getProvider().on('disconnect', callback)
    }

    async address(): Promise<string[]> {
        return new Promise(async (resolve, reject) => {
            if (!Phantom.isInstalled() || !Phantom.isConnected())
                return reject(false)

            resolve([this.getProvider().publicKey.toString()])
        })
    }

    async signMessage(message: string, nonce: string): Promise<string> {
        return new Promise((resolve, reject) => {
            if (!Phantom.isInstalled() || !Phantom.isConnected()) {
                return reject(false)
            }

            let encoded = new TextEncoder().encode(message + nonce)

            this.getProvider().signMessage(encoded, "utf8").then((signature: any) => {
                resolve(bs58.encode(signature.signature))
            }).catch(() => {
                reject(false)
            })
        })
    }

    install(): void {
        window.open('https://phantom.app/', '_blank')
    }

    signTransaction(transaction: any): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!Phantom.isInstalled() || !Phantom.isConnected()) {
                return reject(false)
            }

            try {
                const tx= (window as any).phantom.solana.signTransaction(transaction)
                resolve(tx)
            } catch (e) {
                reject(e)
            }

        })
    }

    signAllTransactions(transactions: any[]): Promise<any[]> {
        return new Promise((resolve, reject) => {
            if (!Phantom.isInstalled() || !Phantom.isConnected()) {
                return reject(false)
            }

            try {
                const signatures = (window as any).phantom.solana.signAllTransactions(transactions)
                resolve(signatures)
            } catch (e) {
                reject(e)
            }

        })

    }
}