import { Keypair, PublicKey } from '@solana/web3.js'
import crypto from 'crypto'
import nacl from 'tweetnacl'
import { deserializeUint8Array } from 'utils/helpers'

export const getCryptoPair = (cloudWallet: Keypair) => {

    const seed = cloudWallet.secretKey.slice(0, 32)
    const cryptoHash = crypto.createHash("sha256").update(seed).digest()
    const constantSecretKey = cryptoHash.slice(0, nacl.box.secretKeyLength)

    return nacl.box.keyPair.fromSecretKey(constantSecretKey)

}

export const decryptMailData = (content: any, nonce:any, sender:Uint8Array, keypair: nacl.BoxKeyPair) => {
    return nacl.box.open(deserializeUint8Array(content), deserializeUint8Array(nonce), sender, keypair.secretKey)
}