import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface MailState {
    page: string,
    subpage: string,
    serverPublicKey: string,
    viewingMail?: string | null,
    refreshBox: boolean,
    boxPreviousPage?: string,
    mails: any[],
    filters: any,
    boxLoading: boolean,
    getMailsAfter?: string,
    selectedMails: string[]
}

const initialState: MailState = {
    page: "inbox",
    subpage: "home",
    serverPublicKey: "",
    viewingMail: null,
    refreshBox: false,
    mails: [],
    filters: {},
    boxLoading: false,
    selectedMails: []
}

export const mailSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setPage: (state, action: PayloadAction<string>) => {
            state.page = action.payload
        },
        setSubpage: (state, action: PayloadAction<string>) => {
            state.subpage = action.payload
        },
        setServerPublicKey: (state, action: PayloadAction<string>) => {
            state.serverPublicKey = action.payload
        },
        setViewingMail: (state, action: PayloadAction<string | null>) => {
            state.viewingMail = action.payload

            if (state.mails.length > 0) {
                state.mails.forEach(mail => {
                    mail.focus = false
                })
                const index = state.mails.findIndex(mail => mail.address === action.payload)
                if (index !== -1) {
                    state.mails[index].focus = true
                }
            }
        },
        setRefreshBox: (state, action: PayloadAction<boolean>) => {
            state.refreshBox = action.payload
        },
        setBoxPreviousPage: (state, action: PayloadAction<string|undefined>) => {
            state.boxPreviousPage = action.payload
        },
        setMails: (state, action: PayloadAction<any[]>) => {
            state.mails = action.payload

            if (state.viewingMail && typeof state.getMailsAfter === "undefined") {
                state.mails.forEach(mail => {
                    mail = { ...mail, focus: false}
                })

                const index = state.mails.findIndex(mail => mail.address === state.viewingMail)
                if (index !== -1) {
                    state.mails[index].focus = true
                }
            }
        },
        setSingleMail: (state, action: PayloadAction<{ index: number, mail: any }>) => {
            state.mails[action.payload.index] = action.payload.mail;
        },
        readMail: (state, action: PayloadAction<string>) => {
            const index = state.mails.findIndex(mail => mail.address === action.payload)
            if (index !== -1) {
                state.mails[index].read = true
            }
        },
        setFilters: (state, action: PayloadAction<any>) => {
            state.filters = action.payload
        },
        setBoxLoading: (state, action: PayloadAction<boolean>) => {
            state.boxLoading = action.payload
        },
        setGetMailsAfter: (state, action: PayloadAction<string | undefined>) => {
            state.getMailsAfter = action.payload
        },
        setSelectedMails: (state, action: PayloadAction<string[]>) => {
            state.selectedMails = action.payload
        }
    }
})

export const { setPage, setSubpage, setServerPublicKey, setViewingMail, setRefreshBox, setBoxPreviousPage, setMails, setSingleMail, readMail, setFilters, setBoxLoading, setGetMailsAfter, setSelectedMails } = mailSlice.actions

export default mailSlice.reducer