export function IconBold() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 15 18"
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M0 1a1 1 0 011-1h8a5 5 0 013.436 8.632A5 5 0 0110 18H1a1 1 0 01-1-1V1zm2 9v6h8a3 3 0 100-6H2zm0-2h7a3 3 0 100-6H2v6z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export function IconItalic() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 16 18"
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M10.977 0H6a1 1 0 000 2h3.557l-5.25 14H1a1 1 0 100 2h9a1 1 0 100-2H6.443l5.25-14H15a1 1 0 100-2h-4.023z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export function IconLink() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 22 22"
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M11 2.515A6 6 0 1119.486 11l-1.415 1.414A1 1 0 0116.657 11l1.415-1.414a4 4 0 00-5.657-5.657L11 5.343A1 1 0 019.585 3.93l1.415-1.414zm4.208 4.278a1 1 0 010 1.414l-7 7a1 1 0 11-1.415-1.414l7-7a1 1 0 011.415 0zM5.344 9.586a1 1 0 010 1.414l-1.415 1.414a4 4 0 105.657 5.657l1.415-1.414a1 1 0 011.414 1.414L11 19.485A6 6 0 112.514 11L3.93 9.586a1 1 0 011.415 0z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export function IconAlignCenter() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 14"
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M0 1a1 1 0 011-1h18a1 1 0 110 2H1a1 1 0 01-1-1zm3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM0 9a1 1 0 011-1h18a1 1 0 110 2H1a1 1 0 01-1-1zm3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export function IconAlignJustify() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 14"
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M0 1a1 1 0 011-1h18a1 1 0 110 2H1a1 1 0 01-1-1zm0 4a1 1 0 011-1h18a1 1 0 110 2H1a1 1 0 01-1-1zm0 4a1 1 0 011-1h18a1 1 0 110 2H1a1 1 0 01-1-1zm0 4a1 1 0 011-1h18a1 1 0 110 2H1a1 1 0 01-1-1z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export function IconAlignLeft() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 19 14"
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M0 1a1 1 0 011-1h17a1 1 0 110 2H1a1 1 0 01-1-1zm0 4a1 1 0 011-1h13a1 1 0 110 2H1a1 1 0 01-1-1zm0 4a1 1 0 011-1h17a1 1 0 110 2H1a1 1 0 01-1-1zm0 4a1 1 0 011-1h13a1 1 0 110 2H1a1 1 0 01-1-1z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export function IconAlignRight() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 19 14"
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M0 1a1 1 0 011-1h17a1 1 0 110 2H1a1 1 0 01-1-1zm4 4a1 1 0 011-1h13a1 1 0 110 2H5a1 1 0 01-1-1zM0 9a1 1 0 011-1h17a1 1 0 110 2H1a1 1 0 01-1-1zm4 4a1 1 0 011-1h13a1 1 0 110 2H5a1 1 0 01-1-1z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}
