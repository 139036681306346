import React, { useState } from 'react'
import * as C from './style'
import { Button } from 'components/ui/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { EvmWallets } from 'modules/wallet/evm'
import { CreateRegisterWalletVerification, VerifyRegisterWallet } from 'services/api/auth'
import { toast } from 'react-hot-toast'
import { getEvmNetworkDetails } from 'utils/helpers'

const EvmWalletsPage = (props: any) => {

    const [loading, setLoading] = useState(false)

    const connect = (wallet: "metamask") => {

        const walletClass = EvmWallets[wallet]

        if (walletClass) {
            const wallet = new walletClass()

            if (!walletClass.isInstalled()) {
                wallet.install()
                return;
            }


            wallet.connect(getEvmNetworkDetails(props.network)).then(async () => {

                if (walletClass.isConnected()) {

                    props.onWalletConnect(wallet)

                }
            }).catch((err: any) => {
                console.log(err)
                setLoading(false)
            })
        }

    }

    return (
        <C.WalletPage>

            <C.BackButton onClick={props.back}>
                <FontAwesomeIcon icon={faArrowLeft} />
            </C.BackButton>

            <C.TitleWallet>
                Connect Your Wallet
            </C.TitleWallet>
            <C.Description>
                Select the wallet you want to continue with
            </C.Description>

            <C.Wallets>
                <C.Wallet which='phantom' onClick={() => connect('metamask')}>
                    <C.WalletIcon>
                        <img src="/images/wallets/metamask.png" alt="metamask" />
                    </C.WalletIcon>
                    <C.WalletName>
                        Metamask Wallet
                    </C.WalletName>
                </C.Wallet>
            </C.Wallets>

            <C.WalletOr>
                <C.WalletOrLine></C.WalletOrLine>
                <C.WalletOrText>or</C.WalletOrText>
                <C.WalletOrLine></C.WalletOrLine>
            </C.WalletOr>

            <C.WalletsBottom>
                <Button variant="secondary" size="medium" block>Connect Ledger</Button>
            </C.WalletsBottom>

        </C.WalletPage>
    )

}

export default EvmWalletsPage