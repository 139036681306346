import LoginModal from "./auth/login"
import RegisterModal from "./auth/register"
import TppDeleteVoteModal from "./mail/tppDeleteVote"
import ConfirmationModal from "./ui/confirmationModal"
import DomainPaymentOptionsModal from "./payment/domainPaymentOptions"
import WalletConnectModal from "./payment/walletConnectModal"

const Modals = () => {

    return (
        <div>
            <LoginModal />
            <RegisterModal />
            <TppDeleteVoteModal />
            <ConfirmationModal />
            <DomainPaymentOptionsModal />
            <WalletConnectModal />
        </div>
    )

}

export default Modals