export enum Network {
    Solana = 'solana',
    Evm = 'evm',
}

export interface Chain{
    name: string
    network: Network,
    currency?: string,
}

export const Chains : any = {
    solana:{
        name: 'solana',
        network: Network.Solana,
        currency: 'SOL',
    } as Chain,
}