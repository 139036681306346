import { Editor, Transforms, Element as SlateElement } from "slate";
import { DefaultElement } from "slate-react";
import isHotkey from "is-hotkey";

const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']


export function renderLeaf(props: any) {
    return <StyledText {...props} />;
}

export function renderElement(props: any) {
    const { element, children, attributes } = props;
    const style = { textAlign: element.align }
    switch (element.type) {
        case "paragraph":
            return (
                <p style={style} {...attributes} >
                    {children}
                </p>
            );
        case "link":
            return <Link {...props} url={element.url} />;
        default:
            return <DefaultElement {...props} />;
    }
}

export const toggleBlockEditor = (editor: any, format: any) => {
    const isActive = isBlockActiveEditor(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    )

    Transforms.unwrapNodes(editor, {
        match: n =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            !TEXT_ALIGN_TYPES.includes(format),
        split: true,
    })
    let newProperties: any;
    if (TEXT_ALIGN_TYPES.includes(format)) {
        newProperties = {
            align: isActive ? undefined : format,
        }
    } else {
        newProperties = {
            type: isActive ? 'paragraph' : format,
        }
    }
    Transforms.setNodes<SlateElement>(editor, newProperties)

    if (!isActive) {
        const block = { type: format, children: [] }
        Transforms.wrapNodes(editor, block)
    }
}

export const isBlockActiveEditor = (editor:any, format:any, blockType = 'type') => {
    const { selection } = editor
    if (!selection) return false

    const [match] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: (n:any) =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                (n as any)[blockType] === format,
        })
    )

    return !!match
}


export function toggleStyleEditor(editor: any, style: any) {
    const activeStyles = getActiveStylesEditor(editor);

    if (activeStyles.has(style)) {
        Editor.removeMark(editor, style);
    } else {
        Editor.addMark(editor, style, true);
    }
}

function Link({ element, attributes, children }: any) {
    return (
        <a href={element.url} {...attributes} className={"link"}>
            {children}
        </a>
    );
}

export function getActiveStylesEditor(editor: any) {
    return new Set(Object.keys(Editor.marks(editor) ?? {}));
}
function StyledText({ attributes, children, leaf }: any) {
    if (leaf.bold) {
        children = <strong {...attributes}>{children}</strong>;
    }

    if (leaf.italic) {
        children = <em {...attributes}>{children}</em>;
    }

    return <span {...attributes}>{children}</span>;
}

export function isBoldNodeAtSelection(editor: any, selection: any) {
    if (selection == null) {
        return false;
    }

    return (
        Editor.above(editor, {
            at: selection,
            match: (n: any) => n.type === "bold",
        }) != null
    );
}

const serializeNodeToHtml = (node: any) => {
    if (node.type === 'paragraph') {
        const align = node.align ? ` style="text-align:${node.align}"` : '';
        return `<p${align}>${node.children.map(serializeNodeToHtml).join('')}</p>`;
    } else if (node.text !== undefined) {
        let text = node.text;
        if (node.bold) {
            text = `<strong>${text}</strong>`;
        }
        if (node.italic) {
            text = `<em>${text}</em>`;
        }
        return text;
    } else if (node.children) {
        return node.children.map(serializeNodeToHtml).join('');
    }
    return '';
};

export const editorSerializeToHtml = (value: any) => {
    return value.map(serializeNodeToHtml).join('');
};

export function getTextBlockStyle(editor: any) {
    const selection = editor.selection;
    if (selection == null) {
        return null;
    }

    const topLevelBlockNodesInSelection = Editor.nodes(editor, {
        at: editor.selection,
        mode: "highest",
        match: (n: any) => Editor.isBlock(editor, n),
    });

    let blockType = null;
    let nodeEntry = topLevelBlockNodesInSelection.next();
    while (!nodeEntry.done) {
        const [node]: any = nodeEntry.value;
        if (blockType == null) {
            blockType = node.type;
        } else if (blockType !== node.type) {
            return "multiple";
        }

        nodeEntry = topLevelBlockNodesInSelection.next();
    }

    return blockType !== "image" ? blockType : null;
}