import React, { useState } from 'react'
import * as C from './style'
import Loading from 'components/parts/other/loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'components/ui/button'

const TxStatusPage = (props: any) => {

    return (
        <C.TxStatusPage>
            {(props.status === 'pending' || props.status === 'pending-confirm') && (
                <C.PendingStatus>
                    <C.StatusLoading>
                        <Loading />
                    </C.StatusLoading>
                    <C.StatusTitle>
                        {props.status === 'pending' ? 'Confirming Transaction' : 'Please Confirm Your Transaction on Your Wallet'}
                    </C.StatusTitle>

                    <C.StatusData>
                        <C.StatusDataItem>
                            <C.StatusDataItemTitle>
                                PRICE
                            </C.StatusDataItemTitle>
                            <C.StatusDataItemValue>
                                <C.StatusDataItemValueText>
                                    1 USDC
                                </C.StatusDataItemValueText>
                                <C.StatusDataItemValueDesc>
                                    + transaction fee
                                </C.StatusDataItemValueDesc>
                            </C.StatusDataItemValue>
                        </C.StatusDataItem>
                        <C.StatusDataItem>
                            <C.StatusDataItemTitle>
                                DOMAIN
                            </C.StatusDataItemTitle>
                            <C.StatusDataItemValue>
                                <C.StatusDataItemValueText>
                                    {props.domain}
                                </C.StatusDataItemValueText>
                            </C.StatusDataItemValue>
                        </C.StatusDataItem>
                    </C.StatusData>

                </C.PendingStatus>
            )}

            {props.status === 'success' && (
                <C.SuccessStatus>
                    <C.StatusIcon variant="success">
                        <FontAwesomeIcon icon={faCheck} />
                    </C.StatusIcon>
                    <C.StatusTitle>
                        You Have Successfully Purchased The Domain
                    </C.StatusTitle>
                    <C.StatusDesc>
                        You have successfully purchased the <span>{props.domain}</span> domain. You can now manage your domain from your dashboard.
                    </C.StatusDesc>
                    <Button link="routerlink" variant="primary" size="large" to="/dashboard">Go to Dashboard</Button>
                </C.SuccessStatus>
            )}

            {props.status === 'error' && (
                <C.ErrorStatus>
                    <C.StatusIcon variant="error">
                        <FontAwesomeIcon icon={faTimes} />
                    </C.StatusIcon>
                    <C.StatusTitle>
                        Error
                    </C.StatusTitle>
                    <C.StatusDesc>
                        An error occurred while purchasing the domain. Please try again.
                    </C.StatusDesc>
                    <C.StatusButtons>
                        <Button variant="secondary" size="large" onClick={props.back}>Go Back</Button>
                        <Button variant="primary" size="large" onClick={props.walletTryAgain}>Try Again</Button>
                    </C.StatusButtons>
                </C.ErrorStatus>
            )}
        </C.TxStatusPage>
    )

}

export default TxStatusPage