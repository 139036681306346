import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface DataState {
    modalStates:any[]
}

const initialState: DataState = {
    modalStates: []
}

export const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setModalStates: (state, action: PayloadAction<any>) => {
            state.modalStates = [...action.payload]
        }
    }
})

export const { setModalStates } = dataSlice.actions

export default dataSlice.reducer