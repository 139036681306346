import { useEffect, useRef, useState } from "react"
import * as C from "./style"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { Button } from "components/ui/button"
import { Input } from "components/ui/input"
import { confirmWormholeBridge, listBankAccounts, transferUSDCToSol } from "services/api/mxbank"
import { useSelector } from "react-redux"
import { getNetworkWormholeDetails, shortenPublicKey, sleep } from "utils/helpers"
import { getUsdcBalance } from "modules/mxbank/view"
import { hideModal, showModal } from "utils/modal"
import { sendUsdcSol } from "modules/mxbank/actions"
import { toast } from "react-hot-toast"
import bs58 from "bs58"
import { getCryptoPair } from "modules/mail/asymetric"
import { unlockCloudWallet } from "modules/mail/helpers"
import nacl from "tweetnacl"
import { getLocalData } from "utils/localData"
import BigNumber from "bignumber.js"
import { depositUsdcCrossChain } from "modules/mxbank/wormhole"
import { getERC20Allowance } from "modules/evm/view"
import { WalletProvider } from "models/interfaces/walletProvider"
import { increaseERC20Allowance } from "modules/evm/actions"
import Loading from "components/parts/other/loading"
import config from "config"
import { getEmitterAddressEth, getSignedVAA } from "@certusone/wormhole-sdk"

const BankAccounts = () => {

    const [accounts, setAccounts] = useState<any[]>([])
    const currentAccount = useSelector((state: any) => state.user.currentAccount)
    const serverPublicKey = useSelector((state: any) => state.mail.serverPublicKey)
    const user = useSelector((state: any) => state.user.user)

    const [page, setPage] = useState<'home' | 'deposit' | 'send'>("home")

    useEffect(() => {

        listBankAccounts(currentAccount).then((res: any) => {
            setAccounts(res.data)
        }).catch((err: any) => {
            console.log(err)
        })

    }, [])

    const [selectedAccount, setSelectedAccount] = useState<any>(null)
    const [selectedAccountDetails, setSelectedAccountDetails] = useState<any>(null)

    useEffect(() => {

        if (selectedAccount !== null) {

            refreshAccountDetails()

        } else {
            setSelectedAccountDetails(null)
        }

    }, [selectedAccount])

    const refreshAccountDetails = () => {
        getUsdcBalance(selectedAccount.address).then((res: any) => {

            let usdcbalance = 0

            const usdcMatch = res.find((item: any) => item.mint === config.WEB3.SOLANA.USDC_PK);

            if (usdcMatch) {
                usdcbalance = usdcMatch.balance;
            }

            let wusdcbalance = 0
            const wusdcMatch = res.find((item: any) =>
                config.WEB3.SOLANA.USDC_MINTS.some((mint: any) => item.mint === mint)
            );

            if (wusdcMatch) {
                wusdcbalance = wusdcMatch.balance;
            }

            setSelectedAccountDetails({
                usdcbalance,
                wusdcbalance
            })
        }).catch((err: any) => {
            console.log(err)
        })
    }

    const [selectedSendNetwork, setSelectedSendNetwork] = useState<'mailx' | 'solana'>('mailx')

    const [sendAddress, setSendAddress] = useState<string>("")
    const [sendAmount, setSendAmount] = useState<string>("")

    const onSend = () => {

        //make sure amount + fee is less than balance
        if (new BigNumber(sendAmount).plus(new BigNumber(0.1)).isGreaterThan(new BigNumber(selectedAccountDetails.usdcbalance))) {
            toast.error("Insufficient balance")
            return
        }

        let key: any = new TextDecoder().decode(bs58.decode(getLocalData("key")))

        let cloudWallet = unlockCloudWallet(user.cloudWallet, key.toString())
        let cryptoPair = getCryptoPair(cloudWallet)

        key = nacl.box(new TextEncoder().encode(key), Buffer.from(user.cloudWallet.salt, 'hex'), bs58.decode(serverPublicKey), cryptoPair.secretKey)

        let loading = toast.loading("Sending USDC")
        transferUSDCToSol(currentAccount, selectedAccount.address, sendAddress, sendAmount, selectedSendNetwork, bs58.encode(key)).then((res: any) => {
            toast.success("USDC sent successfully")
            refreshAccountDetails()
            setPage('home')
        }).catch((err: any) => {
            console.log(err)
            toast.error("Error sending USDC")
        }).finally(() => {
            toast.dismiss(loading)
        })
    }

    const [depositAction, setDepositAction] = useState<'internal' | 'crosschain'>("internal")
    const [depositAmount, setDepositAmount] = useState<string>("")
    const [crossChainDepositState, setCrossChainDepositState] = useState<any>({})

    useEffect(() => {
        if (page === 'home') {
            setDepositAction('internal')
            setDepositAmount('')
        }
    }, [page])

    const onDeposit = () => {
        showModal("WalletConnectModal", null, {
            onConnect: async (network: string, wallet: WalletProvider) => {
                hideModal("WalletConnectModal")

                if (network === 'solana') {
                    let loading = toast.loading("Sending USDC")
                    sendUsdcSol(selectedAccount.address, BigInt(depositAmount) * BigInt(1000000000), wallet).then((res: any) => {
                        refreshAccountDetails()
                    }).catch((err: any) => {
                        console.log(err)
                        toast.error("Error sending USDC")
                    }).finally(() => {
                        toast.dismiss(loading)
                        setPage('home')
                    })
                } else if (network === 'bsc' || network === 'avalanche') {



                    let loading = toast.loading("Loading...")
                    //check allowence
                    let sourceChainDetails = getNetworkWormholeDetails(network)
                    let walletAddress = await wallet.address()
                    getERC20Allowance(network, sourceChainDetails.tokens.USDC, sourceChainDetails.tokenBridge, walletAddress[0]).then(async (res: any) => {
                        toast.dismiss(loading)
                        setCrossChainDepositState({
                            allowance: "loading",
                            transfer: "not-started",
                            completion: "not-started"
                        })
                        setDepositAction('crosschain')

                        if (new BigNumber(res).isLessThan(new BigNumber(depositAmount))) {
                            try {
                                await increaseERC20Allowance(network, sourceChainDetails.tokens.USDC, sourceChainDetails.tokenBridge, depositAmount, wallet)
                                setCrossChainDepositState({
                                    allowance: "done",
                                    transfer: "loading",
                                    completion: "not-started"
                                })
                            } catch (error) {
                                console.log(error)
                                toast.error("Error sending USDC")
                                setCrossChainDepositState({
                                    allowance: "error",
                                    transfer: "not-started",
                                    completion: "not-started"
                                })
                                return;
                            }
                        } else {
                            setCrossChainDepositState({
                                allowance: "done",
                                transfer: "loading",
                                completion: "not-started"
                            })
                        }

                        depositUsdcCrossChain(selectedAccount.address, depositAmount, network, wallet).then(async (res: any) => {
                            console.log(res)

                            setCrossChainDepositState({
                                allowance: "done",
                                transfer: "done",
                                completion: "loading"
                            })

                            var waitingForVAA = true
                            const emitterAddress = getEmitterAddressEth(sourceChainDetails.tokenBridge)
                            while (waitingForVAA) {
                                try {
                                    await getSignedVAA(config.WEB3.WORMHOLE.VAA_RPC, network, emitterAddress, res)
                                    waitingForVAA = false
                                } catch (error:any) {
                                    if (!error.message.includes('requested VAA not found in store')) {
                                        console.log(error)
                                    }
                                    await sleep(1500)
                                }
                            }

                            confirmWormholeBridge(currentAccount, selectedAccount.address, res, network).then((res: any) => {
                                console.log(res)

                                setCrossChainDepositState({
                                    allowance: "done",
                                    transfer: "done",
                                    completion: "done"
                                })

                                toast.success("USDC sent successfully")
                                refreshAccountDetails()
                                setTimeout(() => {
                                    setPage('home')
                                }, 2000)
                            }).catch((err: any) => {
                                console.log(err)
                                setCrossChainDepositState({
                                    allowance: "done",
                                    transfer: "done",
                                    completion: "error"
                                })
                            })
                        }).catch((err: any) => {
                            console.log(err)
                            toast.error("Error sending USDC")

                            setCrossChainDepositState({
                                allowance: "done",
                                transfer: "error",
                                completion: "not-started"
                            })
                        })

                    }).catch((err: any) => {
                        console.log(err)
                        toast.error("Error sending USDC")
                    })



                }
            }
        })
    }



    return (
        <C.BankAccounts>

            {selectedAccount === null && (
                <>
                    {accounts.map((account, index) => (
                        <C.AccountItem onClick={() => setSelectedAccount(account)} key={index}>
                            <C.AccountItemLeft>
                                <C.AccountItemName>
                                    {account.name}
                                </C.AccountItemName>
                                <C.AccountItemAddress>
                                    {shortenPublicKey(account.address)}
                                </C.AccountItemAddress>
                            </C.AccountItemLeft>
                            <C.AccountItemRight>
                                <FontAwesomeIcon icon={faChevronRight} />
                            </C.AccountItemRight>
                        </C.AccountItem>
                    ))}
                </>
            )}

            {selectedAccountDetails !== null && (
                <>
                    {page === 'home' && (
                        <C.Account>
                            <C.AccountHeader>
                                <C.AccountHeaderBack onClick={() => { setSelectedAccount(null); setSelectedAccountDetails(null) }}>
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </C.AccountHeaderBack>
                                <C.AccountHeaderTitle>
                                    {selectedAccount.name}
                                </C.AccountHeaderTitle>
                            </C.AccountHeader>
                            <C.AccountAddress>
                                <C.AccountAddressValue>
                                    {shortenPublicKey(selectedAccount.address)}
                                </C.AccountAddressValue>
                                <C.AccountAddressCopy onClick={() => { navigator.clipboard.writeText(selectedAccount.address) }}>
                                    Copy
                                </C.AccountAddressCopy>
                            </C.AccountAddress>
                            <C.AccountBalance>
                                {selectedAccountDetails.usdcbalance + selectedAccountDetails.wusdcbalance} USDC
                            </C.AccountBalance>
                            <C.AccountActions>
                                <Button variant="primary" size="small" onClick={() => setPage('deposit')}>
                                    Deposit
                                </Button>
                                <Button variant="secondary" size="small" onClick={() => setPage('send')}>
                                    Send
                                </Button>
                            </C.AccountActions>

                            <C.AccountAssets>
                                <C.AccountAsset>
                                    <C.AccountAssetName>
                                        USDC
                                    </C.AccountAssetName>
                                    <C.AccountAssetBalance>
                                        {selectedAccountDetails.usdcbalance}
                                    </C.AccountAssetBalance>
                                </C.AccountAsset>
                                {selectedAccountDetails.wusdcbalance > 0 && (
                                    <C.AccountAsset>
                                        <C.AccountAssetName>
                                            WUSDC
                                        </C.AccountAssetName>
                                        <C.AccountAssetBalance>
                                            {selectedAccountDetails.wusdcbalance}
                                        </C.AccountAssetBalance>
                                    </C.AccountAsset>
                                )}
                            </C.AccountAssets>

                        </C.Account>
                    )}

                    {page === 'send' && (
                        <C.Account>
                            <C.AccountHeader>
                                <C.AccountHeaderBack onClick={() => setPage('home')}>
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </C.AccountHeaderBack>
                                <C.AccountHeaderTitle>
                                    send from {selectedAccount.name}
                                </C.AccountHeaderTitle>
                            </C.AccountHeader>

                            <C.NetworkTitle>
                                Network
                            </C.NetworkTitle>
                            <C.NetworkSwitch>
                                <C.NetworkSwitchItem onClick={() => setSelectedSendNetwork('mailx')} isactive={selectedSendNetwork === 'mailx' ? "true" : "false"}>
                                    Mailx
                                </C.NetworkSwitchItem>
                                <C.NetworkSwitchItem onClick={() => setSelectedSendNetwork('solana')} isactive={selectedSendNetwork === 'solana' ? "true" : "false"}>
                                    Solana
                                </C.NetworkSwitchItem>
                            </C.NetworkSwitch>

                            <C.SendForm>
                                <Input type="text" placeholder="Address" onChange={(e: any) => setSendAddress(e.target.value)} />
                                <Input type="text" placeholder="Amount" onChange={(e: any) => setSendAmount(e.target.value)} />
                                <Button variant="blue" size="small" onClick={onSend}>
                                    Send
                                </Button>
                                <div>Fee: 0.1 USDC</div>
                            </C.SendForm>

                        </C.Account>
                    )}

                    {page === 'deposit' && (
                        <C.Account>
                            <C.AccountHeader>
                                <C.AccountHeaderBack onClick={() => setPage('home')}>
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </C.AccountHeaderBack>
                                <C.AccountHeaderTitle>
                                    deposit to {selectedAccount.name}
                                </C.AccountHeaderTitle>
                            </C.AccountHeader>

                            {depositAction !== 'crosschain' && (
                                <C.SendForm>
                                    <Input type="text" placeholder="Amount" onChange={(e: any) => setDepositAmount(e.target.value)} />
                                    <Button variant="blue" size="small" onClick={onDeposit}>
                                        Deposit
                                    </Button>
                                </C.SendForm>
                            )}

                            {depositAction === 'crosschain' && (
                                <C.CcSteps>
                                    <C.CcStep status={crossChainDepositState.allowance}>
                                        <C.CcStepTitle>
                                            Step 1: Allowance
                                        </C.CcStepTitle>
                                        <C.CcStepDescription>
                                            Allow USDC to be transferred to the Wormhole bridge
                                        </C.CcStepDescription>
                                        <C.CcStepStatus>
                                            {crossChainDepositState.allowance === 'loading' && (
                                                <>
                                                    Loading <Loading />
                                                </>
                                            )}

                                            {crossChainDepositState.allowance === 'done' && 'Done'}
                                            {crossChainDepositState.allowance === 'error' && 'Error'}
                                            {crossChainDepositState.allowance === 'not-started' && 'Waiting'}
                                        </C.CcStepStatus>
                                    </C.CcStep>

                                    <C.CcStep status={crossChainDepositState.transfer}>
                                        <C.CcStepTitle>
                                            Step 2: Transfer
                                        </C.CcStepTitle>
                                        <C.CcStepDescription>
                                            Transfer USDC to the Wormhole bridge
                                        </C.CcStepDescription>
                                        <C.CcStepStatus>
                                            {crossChainDepositState.transfer === 'loading' && (
                                                <>
                                                    Loading <Loading />
                                                </>
                                            )}

                                            {crossChainDepositState.transfer === 'done' && 'Done'}
                                            {crossChainDepositState.transfer === 'error' && 'Error'}
                                            {crossChainDepositState.transfer === 'not-started' && 'Waiting'}
                                        </C.CcStepStatus>
                                    </C.CcStep>

                                    <C.CcStep status={crossChainDepositState.completion}>
                                        <C.CcStepTitle>
                                            Step 3: Completion
                                        </C.CcStepTitle>
                                        <C.CcStepDescription>
                                            Wait for the MailX to confirm the transaction
                                        </C.CcStepDescription>
                                        <C.CcStepStatus>
                                            {crossChainDepositState.completion === 'loading' && (
                                                <>
                                                    Loading <Loading />
                                                </>
                                            )}

                                            {crossChainDepositState.completion === 'done' && 'Done'}
                                            {crossChainDepositState.completion === 'error' && 'Error'}
                                            {crossChainDepositState.completion === 'not-started' && 'Waiting'}
                                        </C.CcStepStatus>
                                    </C.CcStep>

                                    <C.DepositWarning>Do not close the window until the transaction is confirmed</C.DepositWarning>
                                </C.CcSteps>
                            )}
                        </C.Account>
                    )}
                </>
            )}

        </C.BankAccounts>
    )

}



export default BankAccounts