import { Logo } from 'components/ui/logo/style'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { color } from 'styles/theme'
import { Hex2Rgba } from 'utils/helpers'



export const Left = styled.div`
    display:flex;
    align-items:center;
`

export const HeaderLogo = styled(Link)`
    & ${Logo} {
        width: 110px;
    }
    margin-right:48px;
`

export const Nav = styled.div`
    display:flex;
`

export const NavItem = styled(Link)<{isactive?:string}>`
    color:${color.gray500};
    line-height:125%;
    font-weight:bold;

    &:hover {
        color:${color.black};
    }

    ${props => props.isactive === 'true' && `
        color:${color.blue500};
    `}

    padding: 24px 16px;
    text-decoration:none;
`

export const Right = styled.div`
    display:flex;
    align-items:center;
`

export const Account = styled.div`
    display:flex;
    align-items:center;
    margin-right:40px;
`

export const AccountPP = styled.div`
    width: 40px;
    height: 40px;

    & img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
`

export const AccountInfo = styled.div`
    margin-left: 8px;
`

export const AccountName = styled.div`
    font-weight: 600;
    line-height: 100%;
    color: ${color.black};
    margin-bottom: 4px;
`

export const AccountEmail = styled.div`
    font-size: 12px;
    font-weight: 500;
    line-height: 100%;
    color: ${color.gray500};
`

export const HeaderActions = styled.div`
    & button:not(:last-child) {
        margin-right: 8px;
       
    }

    & button {
        min-width: 140px;
    }
`

export const Header = styled.div<{theme?:string}>`
    height:100px;
    padding:32px 40px;
    display:flex;
    align-items:center;
    justify-content:space-between;

    ${props => props.theme === 'home' && `
        background: transparent;

        & ${NavItem} {
            color:${color.gray300};
        }

        & ${NavItem}:hover {
            color:${color.white};
        }

        & ${Logo} {
            color:${color.white};
        }

        & ${HeaderActions} button:nth-child(1) {
            color:${color.gray300};
        }

        & ${AccountName} {
            color:${color.white};
        }

        & ${AccountEmail} {
            color:${color.gray300};
        }
    `}
`