export const color = {

    black: "#101828",
    white: "#FFFFFF",

    // gray
    gray50: '#F9FAFB',
    gray100: '#F2F4F7',
    gray200: '#EAECF0',
    gray300: '#D0D5DD',
    gray400: '#98A2B3',
    gray500: '#667085',
    gray600: '#475467',
    gray700: '#344054',
    gray800: '#1D2939',

    // blue
    blue100: '#EFF8FF',
    blue300: '#ADDBFF',
    blue500: '#3166DF',
    blue700: '#1244B8',
    blue900: '#0E2E77',

    // red
    red100: '#FEF3F2',
    red300: '#FDA29B',
    red500: '#F04438',
    red700: '#B42318',
    red900: '#7A271A',

    // orange
    orange100: '#FFFAEB',
    orange300: '#FEC84B',
    orange500: '#F79009',
    orange700: '#B54708',
    orange900: '#7A2E0E',

    // green
    green100: '#ECFDF3',
    green300: '#6CE9A6',
    green500: '#12B76A',
    green700: '#027A48',
    green900: '#054F31',

    //wallets
    phantom: '#9786E4',
    backpack: '#F72435',
    solflare: '#232323',
    stripe:"#635BFF",

    //chains
    bnb:"#F3BA2F",
}