import { Logo } from 'components/ui/logo/style'
import styled from 'styled-components'
import { Container } from 'styles'
import { color } from 'styles/theme'
import { Hex2Rgba } from 'utils/helpers'

export const Dashboard = styled.div`

`

export const Header = styled.div`
    height:88px;
    padding:16px 40px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    background-color:${color.white};
    border-bottom:2px solid ${color.gray200};
`

export const HeaderLogo = styled.div`
    & ${Logo} {
        width: 110px;
    }
`

export const Top = styled.div`
    margin-top:40px;
    margin-bottom:64px;
    display:flex;
    justify-content:space-between;
`

export const TopLeft = styled.div`

`

export const TopTitle = styled.div`
    font-size:24px;
    font-weight:600;
    color:${color.black};
    line-height:125%;
    margin-bottom:16px;
`

export const TopDescription = styled.div`
    color:${color.gray700};
`

export const TopRight = styled.div`
    & button {
        padding:18px;
    }
`


export const Content = styled.div`

`

export const Tabs = styled.div`
    margin-bottom:24px;
    display:flex;
    align-items:center;
    background-color:${color.gray100};
    border-radius:8px;
    width:fit-content;
    padding:8px;
`

export const Tab = styled.div<{active?:string,isdisabled?:string}>`
    padding:8px 16px;
    border-radius:8px;
    font-size:14px;
    font-weight:bold;
    line-height:125%;
    color:${color.gray500};
    cursor:pointer;
    ${props => props.active === 'true' && `
        background-color:${color.white};
        color:${color.black};
    `}

    &:hover {
        color:${color.black};
    }

    ${props => props.isdisabled === 'true' && `
        opacity:0.5;
        cursor:not-allowed;
    `}
`

export const Accounts = styled.div`

`

export const Account = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    background-color:${color.white};
    padding:32px;
    border-radius:8px;

    &:not(:last-child) {
        margib-bottom:24px;
    }
`

export const AccountLeft = styled.div`
    display:flex;
    align-items:center;
`

export const AccountImage = styled.div`
    width:56px;
    height:56px;
    margin-right:24px;
    & img {
        width:100%;
        height:100%;
        border-radius:50%;
    }
`

export const AccountInfo = styled.div`
`

export const AccountTitle = styled.div`
    display:flex;
    align-items:center;
`

export const AccountName = styled.div`
    font-size:18px;
    font-weight:600;
    line-height:100%;
    color:${color.black};

    & span{
        color:${color.gray500};
        font-weight:500;
        margin-left:8px;
    }
`

export const AccountNotify = styled.div`
    padding:8px 16px;
    border-radius:28px;
    background-color:${color.blue100};
    color:${color.blue500};
    font-size:12px;
    font-weight:500;
    line-height:125%;
    margin-left:16px;
`

export const AccountLoginInfo = styled.div`
    font-size:12px;
    font-weight:500;
    line-height:125%;
    color:${color.gray500};

    & span {
        margin-right:4px;
        font-weight:600;
        color:${color.blue500};
    }
    margin-top:8px;
`

export const AccountRight = styled.div`
    display:flex;
    align-items:center;

    & > button{
        width:140px;
    }
`

export const AccountItemDropdown = styled.div`
    margin-right:24px;
`

export const Domains = styled.div`

`

export const Domain = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    background-color:${color.white};
    padding:32px;
    border-radius:8px;

    &:not(:last-child) {
        margib-bottom:24px;
    }
`

export const DomainLeft = styled.div`

`

export const DomainTitle = styled.div`
    display:flex;
    align-items:center;
`

export const DomainName = styled.div`
    font-size:18px;
    font-weight:600;
    line-height:100%;
    color:${color.black};
`

export const DomainExtra = styled.div`
    padding:8px 16px;
    border-radius:28px;
    background-color:${color.blue100};
    color:${color.blue500};
    font-size:12px;
    font-weight:500;
    line-height:125%;
    margin-left:16px;
`

export const DomainLoginInfo = styled.div`
    font-size:12px;
    font-weight:500;
    line-height:125%;
    color:${color.gray500};

    & span {
        margin-right:4px;
        font-weight:600;
        color:${color.gray600};
    }
    margin-top:8px;
`

export const DomainRight = styled.div`
    display:flex;
    align-items:center;

    & > button{
        width:140px;
    }
`

export const DomainDropdown = styled.div`
    margin-right:24px;
`

export const DomainToggle = styled.div`
    margin-left:42px;
`