import axios, { CancelToken } from 'axios'
import config from 'config'
import { getLocalData } from 'utils/localData'

export const listBankAccounts = (account: string) => {
    return axios.post(config.API.URL + "mxbank/list-accounts", { account }, {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}

export const transferUSDCToSol = (account: string, mxbank: string, to: string, amount: string, network: string, key: string) => {
    return axios.post(config.API.URL + "mxbank/transfer", { account, mxbank, to, amount, network, key }, {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}

export const confirmWormholeBridge = (account:string, mxbank:string, sequence:string, chain:string) => {
    return axios.post(config.API.URL + "mxbank/confirm-crosschain", { account, mxbank, sequence, chain }, {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}