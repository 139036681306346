import styled from "styled-components"
import { Dialog, DialogHeader } from "../../base/style"

export const Modal = styled.div<{step?:number}>`
    & ${Dialog} {
        max-width: 543px;
        width: 100%;
        padding:0px;

        ${props => (props.step === 0) && `
            max-width: 640px;
        `}

        ${props => (props.step === 4) && `
            max-width: 600px;
        `}
    }
`

