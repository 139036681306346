import { useEffect, useRef, useState } from "react"
import * as P from "./style"
import { Container } from "styles"
import Logo from "components/ui/logo"
import { useDispatch, useSelector } from "react-redux"
import { Button } from "components/ui/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faListDots, faPlus } from "@fortawesome/free-solid-svg-icons"
import AccountDropdown from "components/parts/other/accountDropdown"
import { getAccounts } from "services/api/account"
import { toast } from "react-hot-toast"
import { setLocalData } from "utils/localData"
import { useNavigate } from "react-router-dom"
import { setBoxPreviousPage, setMails } from "services/redux/slices/mail"
import { generateProfilePicture } from "utils/helpers"

const Dashboard = () => {

    const user = useSelector((state: any) => state.user.user)

    const [tab, setTab] = useState("accounts")
    const [accounts, setAccounts] = useState<any[]>([])
    const currentAccount = useSelector((state: any) => state.user.currentAccount)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        getAccounts().then((res) => {
            setAccounts(res.data)
        }).catch((err) => {
            toast.error("An unexpected error occurred")
            console.log(err)
        })
    }, [])

    const launch = (address:string) => {
        setLocalData("selectedAccount", address)
        dispatch(setMails([]))
        dispatch(setBoxPreviousPage(undefined))
        navigate("/mail")
    }

    return (
        <P.Dashboard>
            <P.Header>
                <P.HeaderLogo><Logo /></P.HeaderLogo>
                <AccountDropdown />
            </P.Header>

            <Container>
                <P.Top>
                    <P.TopLeft>
                        <P.TopTitle>
                            Hi,  {user.accounts.find((item: any) => item.address === currentAccount).name}
                        </P.TopTitle>
                        <P.TopDescription>
                            This is your account center where you can see your domains & accounts and switch between them!
                        </P.TopDescription>
                    </P.TopLeft>
                    <P.TopRight>
                        <Button disabled variant="blue" size="large"><FontAwesomeIcon icon={faPlus} /></Button>
                    </P.TopRight>
                </P.Top>

                <P.Content>
                    <P.Tabs>
                        <P.Tab active={tab === "accounts" ? "true" : "false"} onClick={() => setTab("accounts")}>
                            Accounts
                        </P.Tab>
                        <P.Tab isdisabled="true" active={tab === "domains" ? "true" : "false"} >
                            Domains
                        </P.Tab>
                    </P.Tabs>

                    {tab === "accounts" && (
                        <P.Accounts>
                            {accounts.map((account, i) => (
                                <P.Account key={i}>
                                    <P.AccountLeft>
                                        <P.AccountImage>
                                        <img src={generateProfilePicture(account.name)} alt="avatar" />
                                        </P.AccountImage>
                                        <P.AccountInfo>
                                            <P.AccountTitle>
                                                <P.AccountName>
                                                    {account.name} <span>{account.address}</span>
                                                </P.AccountName>
                                                {account.unreadCount > 0 && (
                                                    <P.AccountNotify>{account.unreadCount > 99 ? "99+" : account.unreadCount} unread mail</P.AccountNotify>
                                                )}
                                            </P.AccountTitle>
                                            <P.AccountLoginInfo>
                                               {/* <span>Last login:</span> 16 July 2023 12:34*/}
                                            </P.AccountLoginInfo>
                                        </P.AccountInfo>
                                    </P.AccountLeft>
                                    <P.AccountRight>
                                        {/*<P.AccountItemDropdown><FontAwesomeIcon icon={faListDots} /></P.AccountItemDropdown>*/}
                                        <Button variant="primary" size="small" onClick={() => launch(account.address)}>Launch</Button>
                                    </P.AccountRight>
                                </P.Account>
                            ))}
                        </P.Accounts>
                    )}

                    {tab === "domains" && (
                        <P.Domains>
                            <P.Domain>
                                <P.DomainLeft>
                                    <P.DomainTitle>
                                        <P.DomainName>
                                            google
                                        </P.DomainName>
                                        <P.DomainExtra>
                                            <span>1 account</span>
                                        </P.DomainExtra>
                                    </P.DomainTitle>
                                    <P.DomainLoginInfo>
                                        <span>Registered on:</span> 16 July 2023 12:34
                                    </P.DomainLoginInfo>
                                </P.DomainLeft>
                                <P.DomainRight>
                                    <P.DomainDropdown><FontAwesomeIcon icon={faListDots} /></P.DomainDropdown>
                                    <Button variant="secondary" size="small">Create Account</Button>
                                    <P.DomainToggle>
                                        <FontAwesomeIcon icon={faChevronDown} />
                                    </P.DomainToggle>
                                </P.DomainRight>
                            </P.Domain>

                        </P.Domains>
                    )}
                </P.Content>
            </Container>
        </P.Dashboard>
    )

}

export default Dashboard