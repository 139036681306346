import { ethers } from "ethers"
import { getRpcForNetwork } from "utils/helpers"
import ERC20Abi from "utils/static/erc20.abi.json"

export const getERC20Allowance = async (network:'bsc'|'avalanche', tokenAddress:string, spender:string, address:string) => {
    const provider = new ethers.providers.JsonRpcProvider(getRpcForNetwork(network))
    const tokenContract = new ethers.Contract(tokenAddress, ERC20Abi, provider)

    const allowance = await tokenContract.allowance(address, spender)
    return ethers.utils.formatUnits(allowance, 18)
}