import React from 'react'
import styled from 'styled-components'
import { color } from "styles/theme"

const custom = styled(
    ({ tag = 'div', children, settings, ...props }:any) =>
        tag === "div" ? 
        React.createElement(tag, {...props, tabIndex:"0"}, children)
        :
        React.createElement(tag, props, children)
)

export const Button = custom`
    display: inline-block;
    line-height: 100%;
    border-radius: 8px;
    border: 2px solid;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    ${({settings}) => settings.loading === "true" && `
        & svg{
            margin-left: 8px;
        }
    `}

    ${({settings}) => settings.block === "true" && `
        width: 100%;
    `}

    ${({settings}) => settings.size === "small" && `
        font-size: 14px;
        font-weight: 500;
        padding: 12px 16px;
    `}

    ${({settings}) => settings.size === "medium" && `
        font-size: 16px;
        font-weight: 500;
        padding: 16px 20px;
    `}

    ${({settings}) => settings.size === "large" && `
        font-size: 16px;
        font-weight: 600;
        padding: 17px 24px;
    `}

    ${({settings}) => settings.variant === "primary" && `
        background-color: ${color.black};
        color: ${color.white};
        border-color: ${color.black};
    `}

    ${({settings}) => settings.variant === "secondary" && `
        background-color: transparent;
        border-color: ${color.gray500};
        color: ${color.gray500};
    `}

    ${({settings}) => settings.variant === "white" && `
        background-color: ${color.gray50};;
        border-color: ${color.gray500};
        color: ${color.gray500};
    `}

    ${({settings}) => settings.variant === "blue" && `
        background-color: ${color.blue500};
        border-color: ${color.blue500};
        color: ${color.white};
    `}

    ${({settings}) => settings.variant === "danger" && `
        background-color: ${color.red500};
        color: ${color.red500};
    `}

    ${({settings}) => settings.variant === "warning" && `
        background-color: ${color.orange500};
        color: ${color.orange500};
    `}

    &:disabled{
        cursor: not-allowed;
        opacity: 0.5;
    }
`