import * as C from "./style"


const Input = (props: any) => {

    if (props.type === "textarea") {
        if (props.xref) {
            let { xref, children, ...rest } = props
            return (
                <C.Textarea ref={props.xref} {...rest}>
                    {props.children}
                </C.Textarea>
            )
        } else {
            let { children, ...rest } = props
            return (
                <C.Textarea {...rest}>
                    {props.children}
                </C.Textarea>
            )
        }
    } else if (props.type === "select") {
        if (props.xref) {
            let { xref, ...rest } = props
            return (
                <C.Select ref={props.xref} {...rest} styles={C.SelectStyles(props.state ? props.state : {})}/>
            )
        } else {
            return (
                <C.Select {...props} styles={C.SelectStyles(props.state ? props.state : {})}/>
            )
        }
    } else {
        if (props.xref) {
            let { xref, ...rest } = props
            return (
                <C.Input ref={props.xref} {...rest} />
            )
        } else {
            return (
                <C.Input {...props} />
            )
        }
    }
}

interface InputGroupSettings {
    label?: string | JSX.Element | JSX.Element[]
    icon?: any
    iconright?: any
    hint?: string | JSX.Element | JSX.Element[]
    state?: "error" | "success" | "default"
}

interface InputGroupProps extends React.HTMLAttributes<HTMLDivElement> {
    settings?: InputGroupSettings
}

const InputGroup = (props: InputGroupProps) => {

    let settings: InputGroupSettings = {
        label: "",
        icon: null,
        iconright: null,
        hint: "",
        state: "default"
    }

    if (props.settings) {
        settings = { ...settings, ...props.settings }
    }

    return (
        <C.InputGroup icon={settings.icon ? "true" : "false"} iconright={settings.iconright ? "true" : "false"} state={settings.state}>
            {settings.label && <C.InputLabel>{settings.label}</C.InputLabel>}
            <C.InputContainer>
                {settings.icon && <C.InputIcon>{settings.icon}</C.InputIcon>}
                {props.children}
                {settings.iconright && <C.InputIconRight>{settings.iconright}</C.InputIconRight>}
            </C.InputContainer>
            {settings.hint && <C.InputHint>{settings.hint}</C.InputHint>}
        </C.InputGroup>
    )
}


export { InputGroup, Input }