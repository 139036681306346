
import { Link } from "react-router-dom"
import * as C from "./style"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    link?: "routerlink" | "link" | "none",
    loading?: "true" | "false" | boolean,
    block?: "true" | "false" | boolean,
    size?: "small" | "medium" | "large",
    variant?: "primary" | "secondary" | "danger" | "warning" | "blue" | "white",
    to?: string,
    children: any,
}

export const Button = (props: ButtonProps) => {

    let { link, loading, block, size, variant, ...rest } = props
    let settings = { link, loading, block, size, variant }

    if (typeof block === "boolean")
        settings.block = block ? "true" : "false"

    if (typeof loading === "boolean")
        settings.loading = loading ? "true" : "false"

    if (link === "routerlink") {
        return (
            <C.Button tag={Link} settings={settings} {...rest}>
                {props.children}
            </C.Button>
        )
    } else if (props.link === "link") {
        return (
            <C.Button tag="a" settings={settings} {...rest}>
                {props.children}
            </C.Button>
        )
    } else {
        return (
            <C.Button tag="button" settings={settings} {...rest}>
                {props.children}
                {settings.loading === "true" && (
                    <FontAwesomeIcon icon={faSpinner} spin/>
                )}
            </C.Button>
        )
    }
}