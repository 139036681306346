// services
import { Provider } from "react-redux";
import { store } from "services/redux/store";
import { history } from "components/router/history";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { Tooltip } from "react-tooltip";

// components
import Routes from "routes";

// utils
import { color } from "styles/theme";
import { Hex2Rgba } from "utils/helpers";
import Modals from "components/modals";

// render the app
export default () => {
  return (
    <Provider store={store}>
      <HistoryRouter history={history}>
        <Routes />
        <Modals />
        <Tooltip id="tooltip" />
        <Toaster
          position="top-center"
          reverseOrder={false}
          toastOptions={{
            style: {
              border: "1px solid " + color.black,
              color: color.white,
              background: Hex2Rgba(color.black, 0.95),
            },
          }}
        />
      </HistoryRouter>
    </Provider>
  );
}
