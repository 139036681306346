import React, { useState } from 'react'
import * as C from './style'
import { Button } from 'components/ui/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import Loading from 'components/parts/other/loading'

const RegisterLoading = (props: any) => {

    return (
        <C.RegisterLoading>

            {(!props.loadingFinished && !props.loadingError) && (
                <C.LoadingWrapper>
                    <Loading />
                    <div>Creating account...</div>
                </C.LoadingWrapper>
            )}

            {props.loadingError && (
                <C.RegisterError>
                    <div>{props.loadingError}</div>
                    <Button onClick={props.back} variant="primary" size='small'>Try Again</Button>
                </C.RegisterError>
            )}

            {props.loadingFinished && (
                <C.RegisterSuccess>
                    <div>Your account created</div>
                    <Button onClick={props.launch} variant="primary" size='small'>Launch Mailx</Button>
                </C.RegisterSuccess>
            )}

        </C.RegisterLoading>
    )

}

export default RegisterLoading