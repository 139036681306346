import { PublicKey, sendAndConfirmTransaction, Keypair, ComputeBudgetProgram, GetProgramAccountsFilter } from '@solana/web3.js'
import { SOLANA_CONNECTION, WaitForTransaction } from '../solana/connection'
import config from 'config';
import { getAssociatedTokenAccount } from 'modules/solana/spltoken';
import { getAccount } from '@solana/spl-token';


const TOKEN_PROGRAM_ID = new PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA")

export const getUsdcBalance = async (address: string) => {
    const connection = SOLANA_CONNECTION

    const filters: GetProgramAccountsFilter[] = [
        {
            dataSize: 165,    //size of account (bytes)
        },
        {
            memcmp: {
                offset: 32,     //location of our query in the account (bytes)
                bytes: address,  //our search criteria, a base58 encoded string
            }
        }
    ];

    const accounts = await connection.getParsedProgramAccounts(
        TOKEN_PROGRAM_ID,   //SPL Token Program
        { filters: filters }
    )

    let mints: any[] = []

    accounts.forEach((account: any, i: any) => {
        try {
            const parsedAccountInfo: any = account.account.data;
            const mint: string = parsedAccountInfo["parsed"]["info"]["mint"];

            const balance: number = parsedAccountInfo["parsed"]["info"]["tokenAmount"]["uiAmount"];
            if (balance > 0)
                mints.push({
                    mint,
                    balance,
                })
        } catch (e) { }
    });

    let usdcAccounts : any= [];

    [config.WEB3.SOLANA.USDC_PK, ...config.WEB3.SOLANA.USDC_MINTS].forEach((mint: string) => {
        for (let i = 0; i < mints.length; i++) {
            if (mints[i].mint === mint) {
                usdcAccounts.push(mints[i])
                break
            }
        }
    })

    console.log(usdcAccounts)


    return usdcAccounts

    /*
        const usdcMint = new PublicKey(config.WEB3.SOLANA.USDC_PK)
        const accountAddress = new PublicKey(address)
    
        const ata = await getAssociatedTokenAccount(accountAddress, usdcMint)
        try {
            let accountData = await getAccount(connection, ata, "confirmed")
            return accountData.amount
        } catch (e) {
            return BigInt(0)
        }*/
}