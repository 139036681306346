import * as M from "./style"
import { useSelector } from "react-redux"
import { hideModal, isModalActive } from "utils/modal"
import BaseModal from "../../base"
import Register from "components/parts/auth/register"
import { useState } from "react"

const RegisterModal = () => {

    const show = isModalActive(useSelector((state: any) => state.data.modalStates), "register")
    const [step, setStep] = useState(0)

    return (
        <M.Modal step={step}>
            <BaseModal
                header={false}
                hide={() => hideModal("register", () => setStep(0))}
                show={show}
            >
                <Register step={step} setStep={setStep} hide={() => hideModal("register", () => setStep(0))} />
            </BaseModal>
        </M.Modal>
    )

}

export default RegisterModal