import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface UserState {
    user: any | null,
    userLoaded: boolean,
    currentAccount: any | null,
}

const initialState: UserState = {
    user: null,
    userLoaded: false,
    currentAccount: null,
}


export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<any | null>) => {
            state.user = action.payload
        },
        setUserLoaded: (state, action: PayloadAction<boolean>) => {
            state.userLoaded = action.payload
        },
        setCurrentAccount: (state, action: PayloadAction<any | null>) => {
            state.currentAccount = action.payload
        },
        setNotificationsCountForCurrentAccount: (state, action: PayloadAction<number|null>) => {
            if (state.currentAccount && typeof action.payload === "number") {
                state.user.accounts.find((account: any) => account.address === state.currentAccount).notificationsCount = action.payload
            }
        }
    }
})

export const { setUser, setUserLoaded, setCurrentAccount, setNotificationsCountForCurrentAccount} = userSlice.actions

export default userSlice.reducer