import { Loading } from 'components/parts/other/loading/style'
import styled from 'styled-components'
import { color } from 'styles/theme'
import { Hex2Rgba } from 'utils/helpers'

export const BankAccounts = styled.div`
    background-color: ${color.white};
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    border: 1px solid ${color.gray200};
    min-height: 680px;
    position: absolute;
    top:57px;
    right: 0;
    width: 450px;
    z-index: 20;
    padding: 24px 16px;
`

export const AccountItem = styled.div`
    display: flex;
    background-color: ${color.gray100};
    border-radius: 8px;
    padding: 16px;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
        margin-bottom: 16px;
    }
    cursor: pointer;

    &:hover {
        background-color: ${Hex2Rgba(color.gray100, 0.8)};
    }
`

export const AccountItemLeft = styled.div`
    
`

export const AccountItemName = styled.div`
    font-weight: bold;
`

export const AccountItemAddress = styled.div`
    font-size: 12px;
`

export const AccountItemRight = styled.div`

`


export const Account = styled.div`

`

export const AccountHeader = styled.div`
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`

export const AccountHeaderBack = styled.div`
    font-size: 12px;
    color: ${color.gray500};
    cursor: pointer;
    position: absolute;
    left:0;
`

export const AccountHeaderTitle = styled.div`
    font-weight: bold;
`

export const AccountAddress = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const AccountAddressValue = styled.div`
    margin-right: 8px;
    font-weight: bold;
`

export const AccountAddressCopy = styled.div`
    cursor: pointer;
    font-size: 12px;
`

export const AccountBalance = styled.div`
    margin: 24px 0;
    text-align: center;
    font-size: 24px;
`

export const AccountActions = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    & > *:not(:last-child) {
        margin-right: 16px;
    }
`

export const AccountAssets = styled.div`
    margin-top: 24px;
`

export const AccountAsset = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    background-color: ${color.gray100};
    border-radius: 8px;
    margin-bottom: 16px;
`

export const AccountAssetName = styled.div`

`

export const AccountAssetBalance = styled.div`

`

export const NetworkTitle = styled.div`
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 16px;
`

export const NetworkSwitch = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
`

export const NetworkSwitchItem = styled.div<{isactive?:string}>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    border-radius: 8px;
    cursor: pointer;
    background-color: ${color.gray100};
    &:not(:last-child) {
        margin-right: 8px;
    }

    ${({isactive}) => isactive === 'false' && `
        &:hover {
            background-color: ${Hex2Rgba(color.gray100, 0.8)};
        }
    `}

    ${({isactive}) => isactive === 'true' && `
        background-color: ${color.black};
        color: ${color.white};
    `}
`

export const SendForm = styled.div`

    & > *:not(:last-child) {
        margin-bottom: 16px;
    }

`

export const CcSteps = styled.div`

`

export const CcStep = styled.div<{status:'done'|'not-started'|'loading'|'error'}>`
    background-color: ${color.gray100};
    border-radius: 8px;
    padding: 16px;
    
    &:not(:last-child) {
        margin-bottom: 16px;
    }

    ${({status}) => status === 'done' && `
        background-color: ${color.green300};
    `}

    ${({status}) => status === 'not-started' && `
        opacity: 0.5;
    `}

    ${({status}) => status === 'loading' && `
        background-color: ${color.gray100};
    `}

    ${({status}) => status === 'error' && `
        background-color: ${color.red300};
    `}

    text-align: center;
`

export const CcStepTitle = styled.div`
    font-weight: bold;
`

export const CcStepDescription = styled.div`
    color: ${color.gray600};
    font-size: 14px;
    margin-top: 8px;
`

export const CcStepStatus = styled.div`
    margin-top: 8px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    & ${Loading}{
        margin-left: 8px;
        height:18px;
        width:18px;
        border-width: 3px;
    }
`

export const DepositWarning = styled.div`
    background-color: ${color.orange100};
    border-radius: 8px;
    padding: 16px;
    margin-top: 24px;
    font-size: 14px;
    text-align: center;
    color: ${color.orange700};
`