import { useEffect, useRef, useState } from "react"
import * as C from "./style"
const EmptyContent = () => {

    return (
        <C.EmptyContent>
            <img src="/images/empty-content.png" alt="empty content" />
            <C.EmptyContentText>
                Choose a mail to read
            </C.EmptyContentText>
        </C.EmptyContent>
    )
}

export default EmptyContent