import { useEffect, useRef, useState } from "react"
import * as C from "./style"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowDown, faChevronLeft, faFile, faReply, faReplyAll, faShare, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import { Button } from "components/ui/button"
import { useDispatch, useSelector } from "react-redux"
import { accessTpp, deleteNormalMail, getMail, ipfsGet, voteTpp } from "services/api/mail"
import toast from "react-hot-toast"
import { decryptMailData, getCryptoPair } from "modules/mail/asymetric"
import bs58 from "bs58"
import { unlockCloudWallet } from "modules/mail/helpers"
import { getLocalData } from "utils/localData"
import moment from "moment"
import { hideModal, showModal } from "utils/modal"
import { useNavigate } from "react-router-dom"
import nacl from 'tweetnacl'
import { setMails, readMail } from "services/redux/slices/mail"
import { setNotificationsCountForCurrentAccount } from "services/redux/slices/user"
import Reply from "../reply"
import { IconMailForward, IconMailReply, IconMailReplyAll } from "utils/icons/mail"
import { IconArrowLeft, IconTrashCan } from "utils/icons/common"
import { generateProfilePicture } from "utils/helpers"

const Mail = () => {

    const viewingMail = useSelector((state: any) => state.mail.viewingMail)
    const user = useSelector((state: any) => state.user.user)
    const currentAccount = useSelector((state: any) => state.user.currentAccount)

    const [mail, setMail] = useState<any>(null)
    const [accessStatus, setAccessStatus] = useState<any>(null)

    const navigate = useNavigate()
    const page = useSelector((state: any) => state.mail.page)
    const serverPublicKey = useSelector((state: any) => state.mail.serverPublicKey)

    const dispatch = useDispatch()

    const [replying, setReplying] = useState(false)
    const [replyProps, setReplyProps] = useState<any>(null)

    const [threadMails, setThreadMails] = useState<any[]>([])

    useEffect(() => {
        setMail(null)
        setAccessStatus(null)
        setReplying(false)
        setReplyProps(null)
        setThreadMails([])

        getMail(currentAccount, viewingMail).then((res: any) => {

            dispatch(setNotificationsCountForCurrentAccount(res.data.notificationsCount))

            let content = res.data.content
            //ipfsGet(res.data.content).then((content: any) => {
            //let jsonContent = JSON.parse(content.data)

            let encryptor = res.data.c
            let myKey = content.keys.find((item: any) => item.o === user.accounts.find((item: any) => item.address === currentAccount).ownedAddress)

            if (typeof myKey.k !== "undefined")
                encryptor = myKey.k

            if (typeof myKey === "undefined")
                return toast.error("An error occured while fetching mail.")

            let decryptedEncryptionKey = (decryptMailData(myKey.d, myKey.n, bs58.decode(encryptor), getCryptoPair(unlockCloudWallet(user.cloudWallet, new TextDecoder().decode(bs58.decode(getLocalData("key"))))))!)

            let decryptedContent = new TextDecoder().decode(decryptMailData(content.content, content.nonce, bs58.decode(encryptor), nacl.box.keyPair.fromSecretKey(decryptedEncryptionKey))!)

            let mailForThread: any = null
            if (res.data.thread !== null) {
                mailForThread = {
                    ...res.data,
                    content: decryptedContent,
                    visible: true
                }
                setThreadMails([mailForThread])
            }

            setMail({
                ...res.data,
                thread: null,
                content: decryptedContent
            })

            parseThread(res.data.thread, mailForThread)

            if (page === 'inbox') {
                dispatch(readMail(viewingMail))
            }

            /*}).catch((err: any) => {
                console.log(err)
                toast.error("An error occured while fetching mail.")
            })*/

        }).catch((err: any) => {
            console.log(err)
            toast.error("An error occured while fetching mail.")
        })

    }, [viewingMail])

    useEffect(() => {

        if (mail !== null) {

            if (mail.type === "mail") {
                setAccessStatus(true)
                return;
            }

            let myOwnedAddress = user.accounts.find((item: any) => item.address === currentAccount).ownedAddress
            let myStatusIndex = mail.from.ownedAddress === myOwnedAddress ? 0 : (mail.to.findIndex((item: any) => item.ownedAddress === myOwnedAddress) + 1)
            let myStatus = mail.tppStatus[myStatusIndex]

            if (myStatus.accepted === null)
                setAccessStatus("not-accepted")
            else if (myStatus.lostRights === true)
                setAccessStatus("lost-rights")
            else
                setAccessStatus(true)
        }

    }, [mail])

    const openTppDeleteVote = (m?: any) => {
        showModal("tppDeleteVote", {
            mail: typeof m !== "undefined" ? m : mail
        }, {
            updateTppStatus: (tppStatus: any) => {
                setMail({
                    ...mail,
                    tppStatus
                })
            }
        })
    }

    const onTppAccessAction = (accepted: boolean) => {
        let key: any = new TextDecoder().decode(bs58.decode(getLocalData("key")))
        let cloudWallet = unlockCloudWallet(user.cloudWallet, key.toString())
        let cryptoPair = getCryptoPair(cloudWallet)

        key = nacl.box(new TextEncoder().encode(key), Buffer.from(user.cloudWallet.salt, 'hex'), bs58.decode(serverPublicKey), cryptoPair.secretKey)

        accessTpp(currentAccount, mail.address, bs58.encode(key), accepted).then((res: any) => {

            if (!accepted) {
                toast.success("Successfully rejected XMail.")
                navigate("/mail/" + page)
                return;
            }

            setMail({
                ...mail,
                tppStatus: res.data.tppStatus
            })

            toast.success("Successfully accepted XMail.")
            setAccessStatus(true)
        }).catch((err: any) => {
            console.log(err)
            toast.error("An error occured while taking action.")
        })
    }

    const deleteMail = () => {

        if (mail.type === "mail") {
            showModal("confirmation", {
                title: "Delete the Mail",
                description: "Are you sure you want to delete this mail? it will be deleted from all sides.",
                icon: "delete",
                buttons: [
                    {
                        label: "Cancel",
                        variant: "secondary",
                        onClick: "hide"
                    },
                    {
                        label: "Delete",
                        variant: "primary",
                        onClick: "delete"
                    }
                ]
            }, {
                delete: () => {

                    let loading = toast.loading("Deleting mail...")
                    deleteNormalMail(currentAccount, mail.address).then((res: any) => {
                        toast.success("Mail deleted.")
                        navigate("/mail/" + page)
                    }).catch((err: any) => {
                        console.log(err)
                        toast.error("An error occurred while deleting mail.")
                    }).finally(() => {
                        toast.dismiss(loading)
                        hideModal("confirmation")
                    })

                },
                hide: () => {
                    hideModal("confirmation")
                }
            })
        } else if (mail.type === "tpp") {

            let voteStarted = mail.tppStatus.filter((tpp: any) => tpp.delete === true || tpp.delete === false).length > 0

            if (voteStarted) {
                openTppDeleteVote()
            } else {
                showModal("confirmation", {
                    title: "Delete the Xmail?",
                    description: "If you want to delete this mail, a vote will be started and everyone who has the mail is expected to vote to delete it or not.",
                    icon: "delete",
                    buttons: [
                        {
                            label: "Cancel",
                            variant: "secondary",
                            onClick: "hide"
                        },
                        {
                            label: "Start",
                            variant: "primary",
                            onClick: "delete"
                        }
                    ]
                }, {
                    delete: () => {
                        let loading = toast.loading("Starting vote...")

                        let key: any = new TextDecoder().decode(bs58.decode(getLocalData("key")))
                        let cloudWallet = unlockCloudWallet(user.cloudWallet, key.toString())
                        let cryptoPair = getCryptoPair(cloudWallet)

                        key = nacl.box(new TextEncoder().encode(key), Buffer.from(user.cloudWallet.salt, 'hex'), bs58.decode(serverPublicKey), cryptoPair.secretKey)

                        voteTpp(currentAccount, mail.address, bs58.encode(key), "delete").then((res: any) => {
                            toast.success("Vote started.")
                            setMail({
                                ...mail,
                                tppStatus: res.data.tppStatus
                            })
                            hideModal("confirmation", () => {
                                openTppDeleteVote({
                                    ...mail,
                                    tppStatus: res.data.tppStatus
                                })
                            })
                        }).catch((err: any) => {
                            console.log(err)
                            toast.error("An error occurred while starting vote.")
                        }).finally(() => {
                            toast.dismiss(loading)
                        })
                    },
                    hide: () => {
                        hideModal("confirmation")
                    }
                })
            }

        }

    }

    const mailAction = (type: 'reply' | 'replyall' | 'forward', address?: string) => {

        if (!address) {
            /*if (threadMails.length > 0)
                address = threadMails[0].address
            else*/
            address = mail.address
        }

        setReplyProps({
            type,
            address
        })
        setReplying(true)

    }

    const parseThread = async (thread: any, mailForThread: any) => {

        if (!thread)
            return;

        console.log(thread)

        let threadMailDatas = []

        if (mailForThread !== null)
            threadMailDatas.push(mailForThread)

        for (let i = 0; i < thread.length; i++) {

            if (thread[i].from.address === currentAccount || thread[i].to.findIndex((item: any) => item.address === currentAccount) !== -1) {
                try {
                    let content = thread[i].content

                    let myKey = content.keys.find((item: any) => item.o === user.accounts.find((item: any) => item.address === currentAccount).ownedAddress)
                    let encryptor = thread[i].c

                    if (typeof myKey.k !== "undefined")
                        encryptor = myKey.k

                    if (typeof myKey !== "undefined") {

                        let decryptedEncryptionKey = (decryptMailData(myKey.d, myKey.n, bs58.decode(encryptor), getCryptoPair(unlockCloudWallet(user.cloudWallet, new TextDecoder().decode(bs58.decode(getLocalData("key"))))))!)

                        let decryptedContent = new TextDecoder().decode(decryptMailData(content.content, content.nonce, bs58.decode(encryptor), nacl.box.keyPair.fromSecretKey(decryptedEncryptionKey))!)

                        threadMailDatas.push({
                            ...thread[i],
                            content: decryptedContent,
                            visible: false
                        })
                    }
                } catch (err) {
                    console.log(err)
                }
            }
        }

        //order by createdAt newest to oldest
        threadMailDatas.sort((a: any, b: any) => {
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        })

        setThreadMails(threadMailDatas)
    }

    const expandThreadMail = (index: number) => {
        let newThreadMails = [...threadMails]

        if (newThreadMails[index].visible)
            return;

        newThreadMails[index].visible = true
        setThreadMails(newThreadMails)
    }

    if (mail === null)
        return (
            <></>
        )

    return (
        <C.Mail>

            {!replying && (
                <>
                    {accessStatus !== null && (
                        <>
                            {(accessStatus === true && mail.tppStatus.filter((tpp: any) => tpp.delete === true).length > 0) && (
                                <>
                                    <C.Notification>
                                        <C.NotificationText>
                                            <span>{mail.tppStatus.filter((tpp: any) => tpp.delete === true).length} party</span> demands that mail to be permanently deleted from all sides.
                                        </C.NotificationText>
                                        <Button variant="secondary" size="small" onClick={() => openTppDeleteVote()}>View Vote</Button>
                                    </C.Notification>

                                </>
                            )}

                            <C.Header>
                                <C.HeaderLeft>
                                    <C.HeaderBack onClick={() => navigate("/mail/" + page)}>
                                        <IconArrowLeft />
                                    </C.HeaderBack>
                                    <C.Subject>
                                        {mail.subject}
                                    </C.Subject>
                                </C.HeaderLeft>

                                <C.HeaderRight>

                                    <C.HeaderRightTop>
                                        {mail.type === 'mail' && (
                                            <>
                                                <C.MailAction actiontype="reply" onClick={() => mailAction('reply')}>
                                                    <IconMailReply />
                                                </C.MailAction>
                                                <C.MailAction actiontype="replyall" onClick={() => mailAction('replyall')}>
                                                    <IconMailReplyAll />
                                                </C.MailAction>
                                                {/*<C.MailAction actiontype="forward" onClick={() => mailAction('forward')}>
                                                    <IconMailForward />
                                        </C.MailAction>*/}
                                            </>
                                        )}
                                        {accessStatus === true && (
                                        <C.MailAction actiontype="delete" onClick={deleteMail}>
                                            <IconTrashCan />
                                        </C.MailAction>
                                        )}
                                    </C.HeaderRightTop>
                                    <C.HeaderRightBottom>
                                        {mail.type === 'mail' && (
                                            <C.HeaderRightBottomItem itemType="mailtype-default">
                                                Default Mail
                                            </C.HeaderRightBottomItem>
                                        )}
                                        {mail.type === 'tpp' && (
                                            <C.HeaderRightBottomItem itemType="mailtype-tpp">
                                                XMail
                                            </C.HeaderRightBottomItem>
                                        )}
                                        {mail.type === 'tpp' && (
                                            <C.HeaderRightTopItemA href={"https://solscan.io/account/" + mail.address + "?cluster=devnet"} target="_blank">
                                                View on Solscan
                                            </C.HeaderRightTopItemA>
                                        )}
                                        <C.HeaderRightBottomItem itemType="date" title={moment(mail.createdAt).format("DD/MM/YYYY HH:mm:ss")}>
                                            {moment(mail.createdAt).fromNow()}
                                        </C.HeaderRightBottomItem>
                                    </C.HeaderRightBottom>
                                </C.HeaderRight>
                            </C.Header>


                            {threadMails.length === 0 && (
                                <C.Content>
                                    <C.FromTo>
                                        <C.FromPP>
                                            <img src={generateProfilePicture(mail.from.name)} alt="avatar" />
                                        </C.FromPP>
                                        <C.FromToContent>
                                            <C.FromDetails>
                                                <C.FromName>
                                                    {mail.from.name}
                                                </C.FromName>
                                                <C.FromAddress>
                                                    {'<' + mail.from.address + '>'}
                                                </C.FromAddress>
                                            </C.FromDetails>
                                            <C.ToName>
                                                To:&nbsp;
                                                {mail.to.map((item: any, index: number) => {
                                                    let itemTppAcceptStatus: any;
                                                    if (mail.type === 'tpp') {
                                                        itemTppAcceptStatus = mail.tppStatus[index + 1].accepted
                                                        if (itemTppAcceptStatus === null)
                                                            itemTppAcceptStatus = "waiting"
                                                        else if (itemTppAcceptStatus === true)
                                                            itemTppAcceptStatus = "accepted"
                                                        else
                                                            itemTppAcceptStatus = "rejected"
                                                    }
                                                    return (
                                                        <span key={index} title={item.address + (mail.type === "tpp" ? (" - " + itemTppAcceptStatus.charAt(0).toUpperCase() + itemTppAcceptStatus.slice(1)) : "")}>
                                                            {item.name}
                                                            {mail.type === 'tpp' && (<C.ToTppStatus status={itemTppAcceptStatus}></C.ToTppStatus>)}
                                                            {index !== mail.to.length - 1 && ", "}
                                                        </span>
                                                    )
                                                })}
                                            </C.ToName>
                                        </C.FromToContent>
                                    </C.FromTo>

                                    {accessStatus === true && (
                                        <>
                                            <C.Body>
                                                <div dangerouslySetInnerHTML={{ __html: mail.content }} /> {/*TODO:DOMPurify for XSS*/}
                                            </C.Body>
                                            <C.FooterActions>
                                                {/*mail.type === 'mail' && (
                                                <Button variant="primary" size="small" onClick={() => setReplying(true)}>Reply</Button>
                                            )*/}
                                            </C.FooterActions>

                                            {/*<C.Attachments>
                                                <C.Attachment>
                                                    <C.AttachmentLeft>
                                                        <C.AttachmentIcon>
                                                            <FontAwesomeIcon icon={faFile} />
                                                        </C.AttachmentIcon>
                                                        <C.AttachmentDetails>
                                                            <C.AttachmentName>
                                                                test.zip
                                                            </C.AttachmentName>
                                                            <C.AttachmentSize>
                                                                1.2 MB
                                                            </C.AttachmentSize>
                                                        </C.AttachmentDetails>
                                                    </C.AttachmentLeft>
                                                    <C.AttachmentDownload>
                                                        <FontAwesomeIcon icon={faArrowDown} />
                                                    </C.AttachmentDownload>
                                                </C.Attachment>
                                        </C.Attachments>*/}
                                        </>
                                    )}
                                </C.Content>
                            )}

                            {threadMails.length > 0 && (
                                <C.ThreadContainer>
                                    <C.Thread>
                                        {threadMails.map((thread: any, index: number) => {

                                            return (
                                                <C.ThreadMail key={index} iscollapsed={thread.visible ? "false" : "true"} onClick={() => expandThreadMail(index)}>
                                                    <C.TMHeader>
                                                        <C.TMHeaderLeft>
                                                            <C.TMHeaderLeftAvatar>
                                                                <img src={generateProfilePicture(thread.from.name)} alt="avatar" />
                                                            </C.TMHeaderLeftAvatar>
                                                            <C.TMHeaderFromToContent>
                                                                <C.TMHeaderFromDetails>
                                                                    <C.TMHeaderFromName>{thread.from.name}</C.TMHeaderFromName>
                                                                    <C.TMHeaderFromAddress> {'<' + thread.from.address + '>'}</C.TMHeaderFromAddress>
                                                                </C.TMHeaderFromDetails>
                                                                <C.TMHeaderToName>
                                                                    To:&nbsp;
                                                                    {thread.to.map((item: any, index: number) => {
                                                                        return (
                                                                            <span key={index} title={item.address}>
                                                                                {item.name}
                                                                                {index !== thread.to.length - 1 && ", "}
                                                                            </span>
                                                                        )
                                                                    })}
                                                                </C.TMHeaderToName>
                                                            </C.TMHeaderFromToContent>
                                                        </C.TMHeaderLeft>
                                                        <C.TMHeaderRight>
                                                            <C.TMHeaderRightActions>
                                                                <C.ThreadMailAction actiontype="reply" onClick={() => mailAction('reply', thread.address)}>
                                                                    <IconMailReply />
                                                                </C.ThreadMailAction>
                                                                <C.ThreadMailAction actiontype="replyall" onClick={() => mailAction('replyall', thread.address)}>
                                                                    <IconMailReplyAll />
                                                                </C.ThreadMailAction>
                                                                {/*<C.ThreadMailAction actiontype="forward" onClick={() => mailAction('forward', thread.address)}>
                                                                    <IconMailForward />
                                                                </C.ThreadMailAction>*/}
                                                                <C.ThreadMailAction actiontype="delete" onClick={deleteMail}>
                                                                    <IconTrashCan />
                                                                </C.ThreadMailAction>
                                                            </C.TMHeaderRightActions>
                                                            <C.TMHeaderSeperator />
                                                            <C.TMHeaderRightDate> {moment(thread.createdAt).fromNow()}</C.TMHeaderRightDate>
                                                        </C.TMHeaderRight>
                                                    </C.TMHeader>
                                                    <C.TMContent>
                                                        <C.TMContentBody>
                                                            <div dangerouslySetInnerHTML={{ __html: thread.content }} /> {/*TODO:DOMPurify for XSS*/}
                                                        </C.TMContentBody>

                                                        {/*<C.Attachments>
                                                            <C.Attachment>
                                                                <C.AttachmentLeft>
                                                                    <C.AttachmentIcon>
                                                                        <FontAwesomeIcon icon={faFile} />
                                                                    </C.AttachmentIcon>
                                                                    <C.AttachmentDetails>
                                                                        <C.AttachmentName>
                                                                            test.zip
                                                                        </C.AttachmentName>
                                                                        <C.AttachmentSize>
                                                                            1.2 MB
                                                                        </C.AttachmentSize>
                                                                    </C.AttachmentDetails>
                                                                </C.AttachmentLeft>
                                                                <C.AttachmentDownload>
                                                                    <FontAwesomeIcon icon={faArrowDown} />
                                                                </C.AttachmentDownload>
                                                            </C.Attachment>
                                                                </C.Attachments>*/}
                                                    </C.TMContent>
                                                </C.ThreadMail>
                                            )
                                        })}
                                    </C.Thread>
                                </C.ThreadContainer>
                            )}


                            {accessStatus === "not-accepted" && (
                                <>
                                    <C.Content>
                                        <C.Access>
                                            <img src="/images/tpp-access.png" alt="access" />
                                            <C.AccessTitle>
                                                You Need to Accept To See This Mail
                                            </C.AccessTitle>
                                            <C.AccessText>
                                                <span>{mail.from.name}</span> wants to send you a XMail but you need to accept it first.
                                            </C.AccessText>
                                            <C.AccessText>
                                                This will take 1 <span>XMail</span> credit from your account.
                                            </C.AccessText>
                                            <C.AccessActions>
                                                <Button variant="secondary" size="large" onClick={() => onTppAccessAction(false)}>Reject</Button>
                                                <Button variant="primary" size="large" onClick={() => onTppAccessAction(true)}>Accept</Button>
                                            </C.AccessActions>
                                        </C.Access>
                                    </C.Content>
                                </>
                            )}
                        </>
                    )}
                </>
            )}

            {replying && (
                <Reply mail={threadMails.length > 0 ? threadMails : [mail]} cancel={() => setReplying(false)} {...replyProps} />
            )}



        </C.Mail>
    )
}

export default Mail