import { useEffect, useState } from 'react'
import * as C from "./style"
import { useSelector } from 'react-redux'
import { getModalCallbacks, getModalProps } from 'utils/modal'
import WalletPage from './wallet'
import bs58 from 'bs58'
import { unlockCloudWallet } from 'modules/mail/helpers'
import { getLocalData } from 'utils/localData'
import { BuyDomain } from 'modules/domain/solana'
import TxStatusPage from './tx'
import toast from 'react-hot-toast'

let walletRef : any;

const DomainPaymentOptions = (props:any) => {

    const modalProps = getModalProps(useSelector((state: any) => state.data.modalStates), "DomainPaymentOptions")
    const modalCallbacks = getModalCallbacks("DomainPaymentOptions")
    const [data, setData] = useState<any>(null)
    const [paymentType, setPaymentType] = useState<any>('')
    const user = useSelector((state: any) => state.user.user)

    const [txStatus, setTxStatus] = useState<any>(null)

    useEffect(() => {
        if (modalProps !== null)
            setData(modalProps)

    }, [modalProps])

    const onWalletFinish = (wallet:any) => {

        props.setStep(2)
        setTxStatus('pending-confirm')
        walletRef = wallet

        let key: any = new TextDecoder().decode(bs58.decode(getLocalData("key")))
        let cloudWallet = unlockCloudWallet(user.cloudWallet, key.toString())

        BuyDomain(data.domain, wallet, cloudWallet, setTxStatus).then((res) => {
            setTxStatus('success')
            modalCallbacks.refresh()
        }).catch((err) => {
            //if throw new Error("Domain already exists")
            if (err.message === "Domain already exists") {
                toast.error("Domain registered by someone else")
            }
            console.log(err)
            setTxStatus('error')
        })

    }

    const back = () => {

        if (txStatus === 'pending' ||txStatus === 'pending-confirm' || txStatus === 'success')
            return;

        if (txStatus === 'error') {
            setTxStatus(null)
            props.setStep(1)
            return;
        }

        props.setStep(0)

        setTxStatus(null)
        setPaymentType('')
    }

    const walletTryAgain = () => {
        onWalletFinish(walletRef)
    }

    useEffect(() => {
        if (paymentType !== '')
            props.setStep(1)
        else
            props.setStep(0)
    }, [paymentType])

    const changePaymentType = (type: string) => {
        props.setStep(1)
        setPaymentType(type)
    }
    

    if (!data) return null;

    if (txStatus !== null)
        return (
            <TxStatusPage walletTryAgain={walletTryAgain} domain={data.domain} status={txStatus} back={back} />
        )

    if (paymentType === 'solana'){
        return (
            <C.WalletPayment>
                <WalletPage back={back} onWalletFinish={onWalletFinish} />
            </C.WalletPayment>
        )
    }

    return (
        <C.DomainPaymentOptions>
            <C.Domain>
                <C.DomainName>{data.domain}</C.DomainName>
            </C.Domain>
            <C.Title>
                Great Domain Choice!
            </C.Title>
            <C.Subtitle>
                Choose your payment method
            </C.Subtitle>
            <C.PaymentOptions>
                <C.PaymentOption ptype='credit-card' onClick={() => changePaymentType('credit-card')}>
                    <C.PaymentOptionImage> <img src="/images/chains/stripe.png" /></C.PaymentOptionImage>
                    <C.PaymentOptionTitle>Use Credit Card</C.PaymentOptionTitle>
                </C.PaymentOption>
                <C.PaymentOptionOr>
                    <C.PaymentOptionOrLine></C.PaymentOptionOrLine>
                    <C.PaymentOptionOrText>or</C.PaymentOptionOrText>
                    <C.PaymentOptionOrLine></C.PaymentOptionOrLine>
                </C.PaymentOptionOr>
                <C.PaymentOption ptype='solana' onClick={() => changePaymentType('solana')}>
                    <C.PaymentOptionImage> <img src="/images/chains/sol.png" /></C.PaymentOptionImage>
                    <C.PaymentOptionTitle>Use Solana <span>$USDC</span></C.PaymentOptionTitle>
                </C.PaymentOption>
                <C.PaymentOption ptype='eth' onClick={() => changePaymentType('eth')}>
                    <C.PaymentOptionImage> <img src="/images/chains/eth.png" /></C.PaymentOptionImage>
                    <C.PaymentOptionTitle>Use Avalanche <span>$USDC</span></C.PaymentOptionTitle>
                </C.PaymentOption>
                <C.PaymentOption ptype='bnb' onClick={() => changePaymentType('bnb')}>
                    <C.PaymentOptionImage> <img src="/images/chains/bnb.png" /></C.PaymentOptionImage>
                    <C.PaymentOptionTitle>Use BNB Chain <span>$USDC</span></C.PaymentOptionTitle>
                </C.PaymentOption>
            </C.PaymentOptions>
        </C.DomainPaymentOptions>
    )

}

export default DomainPaymentOptions