import { InputGroup } from "components/ui/input/style"
import { Logo } from "components/ui/logo/style"
import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"

export const Register = styled.div`
    padding:48px;
`

export const Address = styled.div`

`

export const TopLogo = styled.div`
    ${Logo} {
        width: 78px;
    }
    margin-bottom:16px;
    display: flex;
    justify-content: center;
`

export const Title = styled.div<{order?:string}>`
    font-size: 24px;
    font-weight: 700;
    color: ${color.black};
    text-align: center;
    margin-bottom: 8px;
    line-height: 125%;

    ${props => props.order === "0" && `
        margin-top:24px;
        line-height: 0.6;
    `}

    ${props => props.order === "1" && `
        line-height: 0.6;
    `}


    & span{
        color: ${color.blue500};
    }
`

export const Description = styled.div`
    font-size: 14px;
    color: ${color.gray600};
    font-weight: 500;
    text-align: center;
    line-height: 125%;

    & span{
        color: ${color.blue500};
        font-weight: 600;
    }
`

export const Form = styled.form`
    margin-top:32px;

    background-image: url("/images/register_Bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 48px;
    border-radius: 8px;

    & button{
        margin-top: 16px;
    }
`



export const EmailInputLabel = styled.label`
    font-size: 14px;
    font-weight: 500;
    line-height: 125%;
`

export const EMailInput = styled.div`
    display: flex;
    align-items: center;
    border-radius:8px;
    background: ${color.white};
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border:1px solid ${color.gray300};
    margin-top:6px;
`

export const EMailInputObject = styled.input`
    color: ${color.black};
    flex:1;
    border: none;
    background: transparent;
    font-size: 14px;
    font-weight: 500;
    line-height: 125%;
    padding: 16px 14px;
    outline: none;
`

export const EMailInputDomain = styled.div`
    color: ${color.gray600};
    padding:4px 12px;
    background: ${color.gray100};
    border-radius: 4px;
    margin-right: 14px;
    font-size: 13px;
    font-weight: 500;
    line-height: 125%;
    user-select: none;
    cursor: default;
`

export const EMailInputHint = styled.div`
    margin-top: 6px;
    font-size: 14px;
    font-weight: 500;
    line-height: 125%;
`

export const EMailInputGroup = styled.div<{state:'error'|'default'}>`
    color: ${color.white};

    ${state => state.state === 'error' && `
        & ${EMailInput} {
            border-color: ${color.red500};
            background: ${color.red100};
            &:hover{
                border-color: ${color.red500};
            }
            &:focus{
                border-color: ${color.red500};
            }
        }

        & ${EMailInputDomain} {
            color: ${color.red900};
            background: ${color.red300};
        }

        & ${EMailInputHint} {
            color: ${color.white};
        }
    `}
`

export const LoginMethod = styled.div``
export const LoginMethods = styled.div`
    margin-top: 24px;
    & > button:not(:last-child){
        margin-bottom: 16px;
    }
`

export const BackButton = styled.div`
    position: absolute;
    cursor: pointer;
    font-size: 16px;
    left: 24px;
    top: 24px;
    padding-left:0px;
    color: ${color.black};
`

export const PasswordForm = styled.form`
    margin-top: 24px;

    & ${InputGroup} {
        margin-bottom: 16px;
    }
`

export const Name = styled.div`

`

export const Steps = styled.div`
    display: flex;
    margin-top: 24px;
    margin-bottom: 24px;
`

export const Step = styled.div<{active?:string}>`
    flex:1;
    height: 3px;
    background: ${color.blue300};

    border-radius: 16px;

    &:not(:last-child){
        margin-right: 8px;
    }

    ${props => props.active === "true" && `
        background: ${color.blue500};
    `}
`

export const RegisterLoading = styled.div`

`

export const WalletPage = styled.div`

`

export const PageLoading = styled.div`
    background: ${Hex2Rgba(color.white, 0.64)};
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    & svg{
        font-size: 18px;
        color: ${color.blue500};
    }
`

export const Wallets = styled.div`

`

export const Wallet = styled.div<{which:string}>`
    width: 100%;
    border-radius: 8px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    color: ${color.white};
    text-align: center;
    transition: all .1s ease-in-out;
    &:hover{
        opacity: 0.8;
    }

    ${props => props.which === "phantom" && `
        background: ${color.phantom};
    `}
    ${props => props.which === "backpack" && `
        background: ${color.backpack};
    `}
    ${props => props.which === "solflare" && `
        background: ${color.solflare};
    `}

    &:not(:last-child){
        margin-bottom: 16px;
    }
`

export const WalletIcon = styled.div`
    position: absolute;
    left: 16px;
    width: 32px;
    height: 32px;

    & img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
`

export const WalletName = styled.div`
    font-weight: 600;
    line-height: 100%;
    letter-spacing: 0.01em;
`

export const WalletOr = styled.div`
    display: flex;
    margin:32px 0;
    padding:0 40px;
    align-items: center;
`

export const WalletOrLine = styled.div`
    flex:1;
    height: 2px;
    background: ${color.gray300};
`

export const WalletOrText = styled.div`
    margin:0 16px;
    font-weight: 600;
    line-height: 100%;
    color: ${color.gray300};
`

export const WalletsBottom = styled.div`

`

export const RegisterError = styled.div`
    text-align: center;
    & div{
        margin-bottom: 16px;
        font-size: 16px;
        font-weight: 500;
        line-height: 125%;
        color: ${color.red500};
        text-align: center;
    }

`

export const RegisterSuccess = styled.div`
    text-align: center;
    & div{
        margin-bottom: 16px;
        font-size: 16px;
        font-weight: 500;
        line-height: 125%;
        color: ${color.blue500};
        text-align: center;
    }
`

export const LoadingWrapper = styled.div`
    text-align: center;
    & div{
        margin-top: 16px;
        font-size: 14px;
        font-weight: 500;
        line-height: 125%;
        color: ${color.gray600};
    }
`