import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"

export const Loading = styled.div`
    width:48px;
    height:48px;
    border-radius:50%;
    border:6px solid ${Hex2Rgba(color.blue500, 0.32)};
    border-bottom-color:${color.blue500};
    animation:rotation .75s linear infinite;
    display:inline-block;

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`