import axios, { CancelToken } from 'axios'
import config from 'config'
import { getLocalData } from 'utils/localData'

export const getNotifications = (account: string) => {
    return axios.post(config.API.URL + "notification/get", {
        account,
    }, {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}

export const DeleteNotification = (account: string, id: string) => {
    return axios.post(config.API.URL + "notification/delete", {
        account,
        id
    }, {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}

export const markAllNotificationsAsRead = (account: string) => {
    return axios.post(config.API.URL + "notification/mark-all-as-read", {
        account,
    }, {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}