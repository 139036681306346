import axios, { CancelToken } from 'axios'
import config from 'config'
import { getLocalData } from 'utils/localData'

export const uploadFile = (data:any, cancelToken?:any) => {
    return axios.post(config.API.DRIVE + "/upload", data, {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        },
        cancelToken
    })
}

export const downloadFile = (id:string, key:string) => {
    return axios.get(config.API.DRIVE + "/download/" + id, {
        headers: {
            Authorization: "Bearer " + getLocalData("token"),
            "x-api-key": key
        },
        responseType: 'blob'
    })
}

export const deleteFile = (id:string) => {
    return axios.delete(config.API.DRIVE + "/delete/" + id, {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}

export const viewFile = (id:string) => {
    return axios.get(config.API.DRIVE + "/view/" + id, {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}