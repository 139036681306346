import ReactDOM from 'react-dom/client'

// styles
import 'styles/base.scss'
import 'styles/fontStyles.css'

import App from "./app"

// render the app
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <App/>
)