import { PublicKey, sendAndConfirmTransaction, Keypair, ComputeBudgetProgram, Transaction } from '@solana/web3.js'
import { SOLANA_CONNECTION, WaitForTransaction } from '../solana/connection'
import config from 'config';
import { getAssociatedTokenAccount } from 'modules/solana/spltoken';
import * as spltoken from "@solana/spl-token";
import { getAccount } from '@solana/spl-token';

export const sendUsdcSol = async (toAddress: string, amount: bigint, authority:any) => {

    const authorityPubkey = new PublicKey((await authority.address())[0])
    console.log(authorityPubkey.toString())
    const connection = SOLANA_CONNECTION
    const usdcMint = new PublicKey(config.WEB3.SOLANA.USDC_PK)
    const to = new PublicKey(toAddress)

    const atafrom = await getAssociatedTokenAccount(authorityPubkey, usdcMint)
    const atato = await getAssociatedTokenAccount(to, usdcMint)

    const modifyComputeUnits = ComputeBudgetProgram.setComputeUnitLimit({
        units: 1000000
    });

    const addPriorityFee = ComputeBudgetProgram.setComputeUnitPrice({
        microLamports: 1
    });
    
    const transaction = new Transaction().add(modifyComputeUnits).add(addPriorityFee)

    try {
        await getAccount(connection, atato, "confirmed")
    } catch (e) {
        if (e instanceof spltoken.TokenAccountNotFoundError || e instanceof spltoken.TokenInvalidAccountOwnerError) {
            const createATAIx = spltoken.createAssociatedTokenAccountInstruction(
                authorityPubkey,
                atato,
                to,
                usdcMint
            )

            transaction.add(createATAIx)
        }else{
            throw e
        }
    }

    const transferIx = spltoken.createTransferInstruction(
        atafrom,
        atato,
        authorityPubkey,
        amount
    )

    transaction.add(transferIx)

    console.log(transaction)

    transaction.recentBlockhash = (await SOLANA_CONNECTION.getLatestBlockhash("finalized")).blockhash
    transaction.feePayer = authorityPubkey

    const signed = await authority.signTransaction(transaction)
    const sig = await SOLANA_CONNECTION.sendRawTransaction(signed.serialize(), { skipPreflight: false, preflightCommitment: "finalized" });

    let confirm = await WaitForTransaction(sig)

    return true
}