import { Connection } from "@solana/web3.js"
import config from "config"
import { sleep } from "utils/helpers"


export const SOLANA_CONNECTION = new Connection(config.WEB3.SOLANA.RPC, { commitment: 'confirmed' })

export const anchorWallet = (wallet?: any) => {
    return {
        signTransaction: () => {},
        signAllTransactions: () => {},
        publicKey: wallet
    } as any
}

export const WaitForTransaction = (signature: string) => new Promise(async (resolve, reject) => {
    let done = false
    //90 second timeout
    let timeout = 90 * 1000

    //if timeout is reached, reject
    setTimeout(() => {
        if (!done) {
            reject("timeout")
            done = true
        }
    }, timeout)

    while (!done) {
        try {
            const latestBlockHash = await SOLANA_CONNECTION.getLatestBlockhash();
            let confirm = await SOLANA_CONNECTION.confirmTransaction({
                signature,
                blockhash: latestBlockHash.blockhash,
                lastValidBlockHeight: latestBlockHash.lastValidBlockHeight
            })

            if (confirm.value.err) {
                console.error(confirm.value)
                done = true
                reject(confirm.value.err);
            }

            done = true
        } catch (error) {
            console.log(error)
            await sleep(1000)
        }
    }

    resolve(true)
})