import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Container } from 'styles'
import { color } from 'styles/theme'
import { Hex2Rgba } from 'utils/helpers'

export const Account = styled.div`
    position: relative;
`

export const VisibleSide = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    width:290px;
    background-color: ${color.gray100};
    padding: 0 8px;
    height: 56px;
    border-radius: 8px;
`

export const AccountLeft = styled.div`
    display: flex;
    align-items: center;
`

export const AccountAvatar = styled.div`
    width: 40px;
    height: 40px;

    & img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
`

export const AccountTexts = styled.div`
    margin-left:18px;
`

export const AccountName = styled.div`
    font-weight: 600;
    line-height: 100%;
`

export const AccountEmail = styled.div`
    font-size: 12px;
    font-weight: 500;
    line-height: 100%;
    color: ${color.gray500};
    margin-top: 4px;
`

export const AccountRight = styled.div`
    & svg {
        width: 14px;
    }
    margin-right: 8px;
`

export const Dropdown = styled.div`
    position: absolute;
    top: 64px;
    background-color: ${color.gray100};
    width: 290px;
    border-radius: 8px;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.16);
`

export const DropdownItem = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
    padding:14px 24px;
    & svg {
        margin-right: 18px;
        height: 14px;
        color: ${color.black};
    }
    cursor: pointer;
    color: ${color.gray500};
    font-size: 14px;
    font-weight: 600;
    line-height: 100%;

    &:hover {
        background-color: ${Hex2Rgba(color.black, 0.04)};
    }
`