import { Header, NavItem } from 'components/parts/other/headerHome/style'
import { Logo } from 'components/ui/logo/style'
import styled from 'styled-components'
import { Container } from 'styles'
import { color } from 'styles/theme'
import { Hex2Rgba } from 'utils/helpers'

export const Home = styled.div`
    & ${Header} {
        background-color: transparent;
        position: absolute;
        top:0;
        left:0;
        z-index: 1;
        width: 100%;
    }
`

export const Content = styled.div`
    width: 100%;

    
`

export const Hero = styled.div`
    padding-top:176px;
    background-image: url("/images/landing-bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    /*height: calc(100vh - 100px);*/
`

export const HeroTitle = styled.div<{first?:string}>`
    font-size: 56px;
    line-height: 125%;
    font-weight: bold;
    color: ${color.white};
    text-align: center;
`

export const HeroDesc = styled.div<{first?:string}>`
    font-size: 20px;
    font-weight: bold;
    line-height: 125%;
    color: ${color.white};
    text-align: center;
    ${props => props.first === "true" && `
        margin-top:24px;
    `}
    ${props => props.first !== "true" && `
        margin-bottom:80px;
    `}
`

export const HeroImage = styled.div`
    width: 100%;
    position:relative;
    bottom:-5px;
    & img {
        width: 100%;
    }
`