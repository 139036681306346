import { useEffect, useRef, useState } from "react"
import * as C from "./style"
import { useSelector } from "react-redux"
import { faPaperclip, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "components/ui/button"
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { toast } from "react-hot-toast"
import bs58 from "bs58"
import { getCryptoPair } from "modules/mail/asymetric"
import { unlockCloudWallet } from "modules/mail/helpers"
import { sendMail } from "services/api/mail"
import nacl from "tweetnacl"
import { getLocalData } from "utils/localData"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import { generateProfilePicture } from "utils/helpers"

const Reply = (props: any) => {

    const [body, setBody] = useState("")
    const [to, setTo] = useState("")
    const [recipients, setRecipients] = useState<any[]>([])
    const user = useSelector((state: any) => state.user.user)
    const serverPublicKey = useSelector((state: any) => state.mail.serverPublicKey)
    const currentAccount = useSelector((state: any) => state.user.currentAccount)
    const emailAddressRef = useRef<any>( props.mail.find((x: any) => x.address === props.address))

    const page = useSelector((state: any) => state.mail.page)
    const navigate = useNavigate()

    const [mail, setMail] = useState<any>(null)

    const onSend = () => {

        if (recipients.length === 0)
            return toast.error("Please enter a recipient")

        if (recipients.filter((r) => r.valid === false).length > 0)
            return toast.error("Please enter a valid recipient")

        if (body === "")
            return toast.error("Please enter a body")

        let key: any = new TextDecoder().decode(bs58.decode(getLocalData("key")))

        let cloudWallet = unlockCloudWallet(user.cloudWallet, key.toString())
        let cryptoPair = getCryptoPair(cloudWallet)

        key = nacl.box(new TextEncoder().encode(key), Buffer.from(user.cloudWallet.salt, 'hex'), bs58.decode(serverPublicKey), cryptoPair.secretKey)

        sendMail(currentAccount, recipients.map((r) => r.address), "", body, bs58.encode(key), false, mail.address).then((res) => {
            toast.success("Reply sent successfully")
            navigate("/mail/" + page)
        }).catch((err) => {
            console.log(err)
            toast.error("Something went wrong, please try again")
        })

    }

    useEffect(() => {

        let mail = props.mail.find((x: any) => x.address === props.address)
        setMail(mail)

        if (props.type === 'reply') {

            setRecipients([{ address: mail.from.address, valid: true }])

        } else if (props.type === 'replyall') {

            let all = [mail.from.address, ...mail.to.map((to: any) => to.address)]

            //strip current account
            all = all.filter((a: any) => a !== currentAccount)

            all = all.map((a: any) => ({ address: a, valid: true }))

            setRecipients(all)
        }

    }, [props])


    const onEmailFocusOut = () => {
        if (to.trim() === "")
            return;

        let text = to.trim()

        let expression = /.+@.+/g
        let regex = new RegExp(expression)

        let isValid = text.match(regex)

        let newRecipients = [...recipients]
        newRecipients.push({ address: text, valid: isValid })

        setRecipients(newRecipients)
        setTo("")
        emailAddressRef.current.value = ""
    }


    const removeRecipient = (index: number) => {
        let newRecipients = [...recipients]
        newRecipients.splice(index, 1)
        setRecipients(newRecipients)
    }

    if (mail === null)
        return <></>

    return (
        <C.Reply>
            <C.Header>
                <C.HeaderLeft>
                    <Button variant="blue" size="small" onClick={onSend}>Send Reply</Button>
                </C.HeaderLeft>
                <C.HeaderRight>
                    <C.HeaderAction actiontype="attach">
                        <FontAwesomeIcon icon={faPaperclip} />
                    </C.HeaderAction>
                    <C.HeaderAction actiontype="close" onClick={props.cancel}>
                        <FontAwesomeIcon icon={faTimes} />
                    </C.HeaderAction>
                </C.HeaderRight>
            </C.Header>
            <C.Body>
                <C.EmailInputArea empty={recipients.length === 0 ? "true" : "false"}>
                    {recipients.map((recipient, index) => (
                        <C.EmailInputLabel isvalid={recipient.valid ? "true" : "false"} key={index}>{recipient.address} <span onClick={() => removeRecipient(index)}>&times;</span></C.EmailInputLabel>
                    ))}
                    <C.EmailInput placeholder={recipients.length === 0 ? "To" : ""} onBlur={onEmailFocusOut} ref={emailAddressRef} type="text" onChange={(e) => setTo(e.target.value)} />
                </C.EmailInputArea>
                <C.Input style={{ border: 'none' }} disabled type="text" value={"Re: " + mail.subject} />
                <C.Text>
                    <CKEditor

                        editor={ClassicEditor}
                        data=""
                        onReady={(editor: any) => {
                            editor.editing.view.focus();
                        }}
                        onChange={(event: any, editor: any) => {
                            const data = editor.getData();
                            setBody(data)
                        }}
                        onBlur={(event: any, editor: any) => {
                        }}
                        onFocus={(event: any, editor: any) => {
                        }}
                    />
                </C.Text>
                <C.ThreadContainer>
                    <C.Thread>
                        <C.ThreadMail>
                            <C.TMHeader>
                                <C.TMHeaderLeft>
                                    <C.TMHeaderLeftAvatar>
                                        <img src={generateProfilePicture(mail.from.name)} alt="avatar" />
                                    </C.TMHeaderLeftAvatar>
                                    <C.TMHeaderFromToContent>
                                        <C.TMHeaderFromDetails>
                                            <C.TMHeaderFromName>{mail.from.name}</C.TMHeaderFromName>
                                            <C.TMHeaderFromAddress> {'<' + mail.from.address + '>'}</C.TMHeaderFromAddress>
                                        </C.TMHeaderFromDetails>
                                        <C.TMHeaderToName>
                                            To:&nbsp;
                                            {mail.to.map((item: any, index: number) => {
                                                return (
                                                    <span key={index} title={item.address}>
                                                        {item.name}
                                                        {index !== mail.to.length - 1 && ", "}
                                                    </span>
                                                )
                                            })}
                                        </C.TMHeaderToName>
                                    </C.TMHeaderFromToContent>
                                </C.TMHeaderLeft>
                                <C.TMHeaderRight>
                                    <C.TMHeaderRightDate>{moment(mail.createdAt).fromNow()}</C.TMHeaderRightDate>
                                </C.TMHeaderRight>
                            </C.TMHeader>
                            <C.TMContent>
                                <C.TMContentBody>
                                <div dangerouslySetInnerHTML={{ __html: mail.content }} /> {/*TODO:DOMPurify for XSS*/}
                                </C.TMContentBody>
                            </C.TMContent>
                        </C.ThreadMail>
                    </C.Thread>
                </C.ThreadContainer>
            </C.Body>
        </C.Reply>
    )

}

export default Reply