import { useEffect, useRef, useState } from "react"
import * as P from "./style"
import Sidebar from "components/parts/mail/sidebar"
import Header from "components/parts/mail/header"
import Box from "components/parts/mail/box"
import EmptyContent from "components/parts/mail/emptyContent"
import MailView from "components/parts/mail/mail"
import Compose from "components/parts/mail/compose"
import { useNavigate, useParams } from "react-router-dom"
import { setPage, setSubpage, setViewingMail } from "services/redux/slices/mail"
import { useDispatch, useSelector } from "react-redux"
import { isBase58EncodedMailAddress } from "utils/helpers"
import { Tooltip } from 'react-tooltip'

const Mail = (props: any) => {

    const params = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const subpage = useSelector((state: any) => state.mail.subpage)

    useEffect(() => {
        let page = params.page || "inbox"
        let subpage = params.subpage || "home"

        if (page !== "inbox" && page !== "sent") {
            navigate("/mail/")
            return
        }

        dispatch(setPage(page))

        if (subpage !== "home" && subpage !== "compose") {
            /*if (!isBase58EncodedMailAddress(subpage))
                navigate("/mail/")
            else {*/
                dispatch(setViewingMail(subpage))
                dispatch(setSubpage("mail"))
            //}
        } else {
            dispatch(setSubpage(subpage))
            dispatch(setViewingMail(null))
        }

    }, [params])

    return (
        <>
        <P.Mail>
            <Sidebar />
            <P.Main>
                <Header />
                <P.Content>
                    <Box />
                    {subpage === 'home' && <EmptyContent />}
                    {subpage === 'mail' && <MailView />}
                    {subpage === 'compose' && <Compose />}
                </P.Content>
            </P.Main>
        </P.Mail>

        <Tooltip id="tpp-tooltip" html="XMail enables both the sender<br/>and the receiver to collaboratively delete the mail,<br/>given that they both consent to the deletion"  />
        </>
    )
}

export default Mail