import styled from "styled-components"
import { Dialog, DialogHeader } from "../../base/style"
import { color } from "styles/theme"

export const Modal = styled.div`
    & ${Dialog} {
        max-width: 543px;
        width: 100%;
        padding:48px;
    }
`

export const Confirmation = styled.div`
    text-align:center;
    display:flex;
    flex-direction:column;
    align-items:center;
`

export const Icon = styled.div<{color:string}>`
    width: 67px;
    height: 67px;

    display:flex;
    align-items:center;
    justify-content:center;
    border-radius:8px;
    & svg{
        height: 30px;
    }
    margin-bottom: 16px;

    ${props => props.color === "red" && `
        background-color: ${color.red100};
        color: ${color.red500};
    `}
`

export const Title = styled.div`
    color:${color.black};
    font-weight: bold;
    font-size:24px;
    line-height: 125%;
`

export const Description = styled.div`
    font-size:14px;
    font-weight: 500;
    color:${color.gray600};
    line-height: 125%;

    & span{
        font-weight: 600;
        color:${color.black};
    }
    margin-top: 8px;
`

export const Buttons = styled.div`
    display:flex;
    width:100%;
    margin-top:32px;
    & button{
        flex:1;
    }
    & button:not(:last-child){
        margin-right: 16px;
    }
`