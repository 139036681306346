import { useEffect, useRef, useState } from "react"
import * as C from "./style"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faEyeSlash, faRefresh, faTimes, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"
import { bulkDeleteMails, bulkMarkMailsAsRead, getBox } from "services/api/mail"
import { toast } from "react-hot-toast"
import { setBoxLoading, setBoxPreviousPage, setFilters, setGetMailsAfter, setMails, setRefreshBox, setSelectedMails, setViewingMail } from "services/redux/slices/mail"
import moment from "moment"
import { useNavigate } from "react-router-dom"
import { setNotificationsCountForCurrentAccount } from "services/redux/slices/user"
import { Scrollbars } from 'react-custom-scrollbars-2'
import { IconEyeOff, IconXmail } from "utils/icons"
import { showModal, hideModal } from "utils/modal"
import { IconCheck, IconRefresh, IconTimes, IconTrashCan } from "utils/icons/common"
import { generateProfilePicture } from "utils/helpers"

const Box = () => {

    const page = useSelector((state: any) => state.mail.page)
    const refreshBox = useSelector((state: any) => state.mail.refreshBox)
    const filters = useSelector((state: any) => state.mail.filters)
    const boxLoading = useSelector((state: any) => state.mail.boxLoading)
    const getMailsAfter = useSelector((state: any) => state.mail.getMailsAfter)
    const [cancelToken, setCancelToken] = useState<any>(null)
    const previousPage = useSelector((state: any) => state.mail.boxPreviousPage)
    const currentAccount = useSelector((state: any) => state.user.currentAccount)
    const selectedMails = useSelector((state: any) => state.mail.selectedMails)
    const viewingMail = useSelector((state: any) => state.mail.viewingMail)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [isSelectedMailsDeletable, setIsSelectedMailsDeletable] = useState<boolean>(false)
    const [isSelectedMailsMarkableAsRead, setIsSelectedMailsMarkableAsRead] = useState<boolean>(false)

    useEffect(() => {

        if (previousPage === page && refreshBox === false)
            return;

        if (previousPage !== page && refreshBox === true) {
            dispatch(setGetMailsAfter(undefined))
            cancelToken.cancel()
        }

        if (previousPage !== page)
            dispatch(setMails([]))

        dispatch(setBoxPreviousPage(page))

        if (cancelToken !== null)
            cancelToken.cancel()

        const token = axios.CancelToken.source()
        setCancelToken(token)

        getBox(currentAccount, page, token.token, filters, getMailsAfter).then((res: any) => {
            setCancelToken(null)

            if (!getMailsAfter)
                dispatch(setMails(res.data.mails))
            else
                dispatch(setMails([...mails, ...res.data.mails]))
            dispatch(setNotificationsCountForCurrentAccount(res.data.notificationsCount))
            dispatch(setBoxLoading(false))
        }).catch((err: any) => {
            if (!axios.isCancel(err)) {
                toast.error("An error occured while fetching mails")
                console.log(err)
            }
            dispatch(setBoxLoading(false))
        }).finally(() => {
            dispatch(setRefreshBox(false))

            dispatch(setGetMailsAfter(undefined))
        })
    }, [page, refreshBox])

    const mails = useSelector((state: any) => state.mail.mails)

    const onMailClick = (e: any, to: string) => {

        if (e.button === 0)
            navigate(to)
        if (e.button === 1)
            window.open(to, "_blank")
    }

    const getMailTarget = (mail: any) => {

        if (page === 'inbox' && typeof mail.users[0].accounts.find((account: any) => account.ownedAddress === mail.target) === "undefined")
            return ""

        if (page === 'inbox')
            return mail.users[0].accounts.find((account: any) => account.ownedAddress === mail.target).name

        let users: any = mail.users
        let emails: any = []

        for (let i = 0; i < users.length; i++) {
            for (let j = 0; j < mail.target.length; j++) {
                let t = users[i].accounts.find((account: any) => account.ownedAddress === mail.target[j])
                if (typeof t !== "undefined")
                    emails.push(t.name)
            }
        }

        return emails.join(", ")
    }



    const onMailBoxTypeChange = (type: string) => {
        let _filters = { ...filters }

        if (type === "all")
            delete _filters.mailBoxType
        else
            _filters.mailBoxType = type

        dispatch(setFilters(_filters))
        dispatch(setMails([]))
        dispatch(setRefreshBox(true))
    }

    const scrollBarRef = useRef<any>(null)

    const onScroll = () => {

        if (boxLoading || mails.length === 0)
            return;

        const scrollTop = scrollBarRef.current.getScrollTop()
        const scrollHeight = scrollBarRef.current.getScrollHeight()
        const clientHeight = scrollBarRef.current.getClientHeight()

        //if close to bottom
        if (scrollTop + clientHeight >= scrollHeight - 100) {
            //console.log("bottom")
            dispatch(setBoxLoading(true))
            dispatch(setGetMailsAfter(mails[mails.length - 1]._id))
            //console.log(mails[mails.length - 1].subject)
            dispatch(setRefreshBox(true))
        }
    }

    const selectMail = (e: any, i: string) => {
        e.preventDefault()
        e.stopPropagation()
        let _selectedMails = [...selectedMails]

        if (_selectedMails.includes(i))
            _selectedMails.splice(_selectedMails.indexOf(i), 1)
        else
            _selectedMails.push(i)

        let _isDeletable = true
        for (let i = 0; i < _selectedMails.length; i++) {
            if (mails.find((mail: any) => mail.address === _selectedMails[i]).type === "tpp") {
                _isDeletable = false
                break;
            }
        }

        let _isMarkableAsRead = false
        for (let i = 0; i < _selectedMails.length; i++) {
            if (!mails.find((mail: any) => mail.address === _selectedMails[i]).read) {
                _isMarkableAsRead = true
                break;
            }
        }

        setIsSelectedMailsDeletable(_isDeletable)
        setIsSelectedMailsMarkableAsRead(_isMarkableAsRead)
        dispatch(setSelectedMails(_selectedMails))
    }

    const [bulkDeleting, setBulkDeleting] = useState<boolean>(false)
    const [bulkMarkingAsRead, setBulkMarkingAsRead] = useState<boolean>(false)

    const onBulkDelete = () => {
        if (!isSelectedMailsDeletable || bulkDeleting)
            return;

        showModal("confirmation", {
            title: "Delete selected mails?",
            description: "Are you sure you want to delete selected mails? it will be deleted from all sides.",
            icon: "delete",
            buttons: [
                {
                    label: "Cancel",
                    variant: "secondary",
                    onClick: "hide"
                },
                {
                    label: "Delete",
                    variant: "primary",
                    onClick: "delete"
                }
            ]
        }, {
            delete: () => {

                setBulkDeleting(true)
                hideModal("confirmation")

                bulkDeleteMails(currentAccount, selectedMails).then((res: any) => {
                    //remove deleted mails from mails
                    let _mails = [...mails]
                    for (let i = 0; i < selectedMails.length; i++) {
                        _mails = _mails.filter((mail: any) => mail.address !== selectedMails[i])
                    }

                    if (selectedMails.find((address: string) => address === viewingMail)) {
                        navigate("/mail/" + page)
                    }
                    if (_mails.length < 10)
                        dispatch(setRefreshBox(true))
                    else
                        dispatch(setMails(_mails))

                    dispatch(setSelectedMails([]))
                }).catch((err: any) => {
                    toast.error("An error occured while deleting mails")
                    console.log(err)
                }).finally(() => {
                    setBulkDeleting(false)
                })

            },
            hide: () => {
                hideModal("confirmation")
            }
        })
    }

    const onBulkMarkRead = () => {

        if (!isSelectedMailsMarkableAsRead || bulkMarkingAsRead)
            return;

        bulkMarkMailsAsRead(currentAccount, selectedMails).then((res: any) => {
            let _mails = [...mails]
            for (let i = 0; i < selectedMails.length; i++) {
                let index = _mails.findIndex((mail: any) => mail.address === selectedMails[i])
                _mails[index] = { ..._mails[index], read: true }
            }

            dispatch(setMails(_mails))
            dispatch(setSelectedMails([]))
        }).catch((err: any) => {
            toast.error("An error occured while marking mails as read")
            console.log(err)
        }).finally(() => {
            setBulkMarkingAsRead(false)
        })
    }

    const clearSelection = () => {
        dispatch(setSelectedMails([]))
    }

    const selectAll = () => {
        let _selectedMails : any= []

        if (selectedMails.length !== mails.length) {
            for (let i = 0; i < mails.length; i++) {
                _selectedMails.push(mails[i].address)
            }
        }else{
            _selectedMails = []
        }

        let _isDeletable = true
        for (let i = 0; i < _selectedMails.length; i++) {
            if (mails.find((mail: any) => mail.address === _selectedMails[i]).type === "tpp") {
                _isDeletable = false
                break;
            }
        }

        let _isMarkableAsRead = false
        for (let i = 0; i < _selectedMails.length; i++) {
            if (!mails.find((mail: any) => mail.address === _selectedMails[i]).read) {
                _isMarkableAsRead = true
                break;
            }
        }

        setIsSelectedMailsDeletable(_isDeletable)
        setIsSelectedMailsMarkableAsRead(_isMarkableAsRead)
        dispatch(setSelectedMails(_selectedMails))
    }

    return (
        <C.Box>
            {selectedMails.length === 0 && (
                <C.BoxHeader>
                    <C.BoxHeaderLeft>
                        <C.SelectAll>
                            <C.SelectAllCheckbox state="false" onClick={selectAll}>
                            </C.SelectAllCheckbox>
                        </C.SelectAll>
                        <C.MailboxTypes>
                            <C.MailboxType onClick={() => onMailBoxTypeChange("all")} active={typeof filters.mailBoxType === 'undefined' ? "true" : "false"}>
                                Focused
                            </C.MailboxType>
                            <C.MailboxType onClick={() => onMailBoxTypeChange("tpp")} active={filters.mailBoxType === "tpp" ? "true" : "false"}>
                                XMail's
                            </C.MailboxType>
                        </C.MailboxTypes>
                    </C.BoxHeaderLeft>

                    <C.Refresh onClick={() => dispatch(setRefreshBox(true))}>
                        <IconRefresh />
                    </C.Refresh>
                </C.BoxHeader>
            )}

            {selectedMails.length > 0 && (
                <C.SelectedMails>
                    <C.SelectedMailsLeft>
                        <C.SelectAll>
                            <C.SelectAllCheckbox state={selectedMails.length === mails.length ? "true" : "false"} onClick={selectAll}>
                                {selectedMails.length === mails.length && <IconCheck />}
                            </C.SelectAllCheckbox>
                        </C.SelectAll>
                        <C.SelectedMailsCount>
                            <span>{selectedMails.length} mail</span> selected
                        </C.SelectedMailsCount>
                    </C.SelectedMailsLeft>
                    <C.SelectedMailsActions>
                        <C.SelectedMailsAction atype="mark-read" data-tooltip="mark selected read" isdisabled={bulkMarkingAsRead ? "true" : (!isSelectedMailsMarkableAsRead ? "true" : "false")} onClick={onBulkMarkRead}>
                            <IconEyeOff />
                        </C.SelectedMailsAction>
                        <C.SelectedMailsAction atype="delete" data-tooltip="delete seleted" isdisabled={bulkDeleting ? "true" : (!isSelectedMailsDeletable ? "true" : "false")} onClick={onBulkDelete}>
                            <IconTrashCan />
                        </C.SelectedMailsAction>
                        <C.SelectedMailsAction atype="cancel" onClick={clearSelection}>
                            <IconTimes />
                        </C.SelectedMailsAction>
                    </C.SelectedMailsActions>
                </C.SelectedMails>
            )}


            <C.Mails>
                <Scrollbars style={{ width: "100%", height: "100%" }} onScroll={onScroll} ref={scrollBarRef}>
                    {mails.map((mail: any, key: number) => {
                        let target = getMailTarget(mail)
                        return(
                        <C.Mail isselected={selectedMails.includes(mail.address) ? "true" : "false"} focus={mail.focus ? "true" : "false"} read={mail.read === null ? "true" : mail.read ? "true" : "false"} key={key} >
                            <C.MailSenderPP>
                                <img src={generateProfilePicture(target)} alt="profile picture" />
                            </C.MailSenderPP>
                            <C.MailCheckbox onClick={(e) => selectMail(e, mail.address)} state={selectedMails.includes(mail.address) ? "checked" : "unchecked"}>
                                <div>
                                    <div><IconCheck /></div>
                                    <div><IconTimes/></div>
                                </div>
                            </C.MailCheckbox>
                            <C.MailContent>
                                <C.MailSender>
                                    {target}
                                </C.MailSender>
                                <C.MailSubject>
                                    {mail.type === "tpp" && <IconXmail />} {(mail.thread && page === 'inbox') ? "Re:" : ""} {mail.subject}
                                </C.MailSubject>
                            </C.MailContent>
                            <C.MailDate>
                                {moment(mail.createdAt).fromNow()}
                            </C.MailDate>
                            <C.MailOverlay onMouseDown={(e) => onMailClick(e, "/mail/" + (page === "inbox" ? "inbox/" : "sent/") + mail.address)}></C.MailOverlay>
                        </C.Mail>
                    )})}
                </Scrollbars>
            </C.Mails>

        </C.Box>
    )
}

export default Box