import styled from 'styled-components'
import { color } from 'styles/theme'

export const DomainPaymentOptions = styled.div`
    padding:48px;
`

export const Domain = styled.div`
    display:flex;
    justify-content:center;
    margin-bottom:24px;
`

export const DomainName = styled.div`
    background-color:${color.gray50};
    border-radius:8px;
    border:1px solid ${color.gray200};
    color:${color.blue500};
    font-size:18px;
    font-weight:bold;
    line-height:125%;
    padding:16px 32px;
`

export const Title = styled.div`
    font-size:24px;
    line-height:125%;
    font-weight:bold;
    color:${color.black};
    margin-bottom:8px;
    text-align:center;
`

export const Subtitle = styled.div`
    color:${color.gray600};
    font-size:14px;
    line-height:125%;
    font-weight:500;
    text-align:center;
`

export const PaymentOptions = styled.div`
    margin-top:24px;
`

export const PaymentOptionImage = styled.div`
    position:absolute;
    left:18px;

    & img{
        width:100%;
        height:100%;
    }
`

export const PaymentOptionTitle = styled.div`
    font-weight:600;
    display:flex;
    align-items:center;
    & span{
        display:flex;
        align-items:center;
        &::before{
            content:'';
            display:inline-block;
            width:4px;
            height:4px;
            border-radius:50%;
            background-color:${color.gray700};
            margin:0 8px;
        }
        font-weight:400;

        color:${color.gray400};
        font-size:16px;
    }
`

export const PaymentOption = styled.div<{ptype:string}>`
    display:flex;
    align-items:center;
    justify-content:center;
    position:relative;
    padding:20px 18px;
    border-radius:8px;
    cursor:pointer;
    color:${color.white};
    ${props => props.ptype === 'credit-card' && `
        background-color:${color.stripe};

        & ${PaymentOptionImage}{
            width:46px;
        }
    `}
    ${props => props.ptype === 'solana' && `
        background-color:${color.black};

        & ${PaymentOptionImage}{
            width:24px;
        }
    `}
    ${props => props.ptype === 'eth' && `
        background-color:${color.gray200};

        & ${PaymentOptionImage}{
            width:17px;
        }

        & ${PaymentOptionTitle}{
            color:${color.black};
            & span{
                color:${color.gray500};
            }
        }
    `}
    ${props => props.ptype === 'bnb' && `
        background-color:${color.bnb};

        & ${PaymentOptionImage}{
            width:26px;
        }
        & ${PaymentOptionTitle}{
            color:${color.black};
            & span{
                color:${color.gray500};
            }
        }
    `}

    &:not(:last-child){
        margin-bottom:16px;
    }
`

export const PaymentOptionOr = styled.div`
    margin:16px 0;
    display:flex;
    align-items:center;
    color:${color.gray300};
    user-select:none;
`

export const PaymentOptionOrLine = styled.div`
    flex:1;
    background-color:${color.gray300};
    height:2px;
`

export const PaymentOptionOrText = styled.div`
    margin:0 16px;
`


export const WalletPayment = styled.div`
    padding:48px;
`


export const Wallets = styled.div`

`

export const Wallet = styled.div<{which:string}>`
    width: 100%;
    border-radius: 8px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    color: ${color.white};
    text-align: center;
    transition: all .1s ease-in-out;
    &:hover{
        opacity: 0.8;
    }

    ${props => props.which === "phantom" && `
        background: ${color.phantom};
    `}
    ${props => props.which === "backpack" && `
        background: ${color.backpack};
    `}
    ${props => props.which === "solflare" && `
        background: ${color.solflare};
    `}

    &:not(:last-child){
        margin-bottom: 16px;
    }
`

export const WalletIcon = styled.div`
    position: absolute;
    left: 16px;
    width: 32px;
    height: 32px;

    & img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
`

export const WalletName = styled.div`
    font-weight: 600;
    line-height: 100%;
    letter-spacing: 0.01em;
`

export const WalletOr = styled.div`
    display: flex;
    margin:32px 0;
    padding:0 40px;
    align-items: center;
`

export const WalletOrLine = styled.div`
    flex:1;
    height: 2px;
    background: ${color.gray300};
`

export const WalletOrText = styled.div`
    margin:0 16px;
    font-weight: 600;
    line-height: 100%;
    color: ${color.gray300};
`

export const WalletsBottom = styled.div`
    display: flex;
    justify-content: center;

    & button{
        flex:1
    }

    & button:first-child{
        margin-right: 8px;
    }
`

export const WalletPage = styled.div``

export const BackButton = styled.div`
    position: absolute;
    cursor: pointer;
    font-size: 16px;
    left: 24px;
    top: 24px;
    padding-left:0px;
    color: ${color.black};
`


export const TitleWallet = styled.div`
    font-size: 24px;
    font-weight: 700;
    color: ${color.black};
    text-align: center;
    margin-bottom: 8px;
    line-height: 125%;
`

export const Description = styled.div`
    font-size: 14px;
    color: ${color.gray600};
    font-weight: 500;
    text-align: center;
    line-height: 125%;

    & span{
        color: ${color.blue500};
    }

    margin-bottom: 16px;
`


export const TxStatusPage = styled.div`
    padding:48px;
`

export const PendingStatus = styled.div`

`

export const StatusLoading = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    margin-bottom:32px;
`

export const StatusTitle = styled.div`
    font-size:24px;
    font-weight:bold;
    color:${color.black};
    line-height:125%;
    text-align:center;
`

export const StatusData = styled.div`
    background-color:${color.gray50};
    padding:16px;
    border-radius:8px;
    margin-top:24px;
`

export const StatusDataItem = styled.div`
    background-color:${color.gray100};
    padding:16px;
    border-radius:8px;

    &:not(:last-child){
        margin-bottom:16px;
    }
`

export const StatusDataItemTitle = styled.div`
    font-size:12px;
    font-weight:bold;
    color:${color.black};
    line-height:125%;
`

export const StatusDataItemValue = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin-top:8px;
`

export const StatusDataItemValueText = styled.div`
    color:${color.gray700};
    font-size:14px;
    font-weight:600;
    line-height:100%;
`

export const StatusDataItemValueDesc = styled.div`
    font-size:12px;
    color:${color.gray500};
    line-height:100%;
`

export const SuccessStatus = styled.div`
    & a{
        width:100%;
        text-align:center;
        height:56px;
    }
`

export const StatusIcon = styled.div<{variant:string}>`
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 24px;
    ${props => props.variant === 'success' && `
        background-color:${color.blue500};
    `}
    ${props => props.variant === 'error' && `
        background-color:${color.red500};
    `}

    & svg{
        height: 18px;
        color: ${color.white};
    }
`

export const StatusDesc = styled.div`
    font-size:14px;
    font-weight:500;
    color:${color.gray600};
    line-height:125%;
    margin-bottom:24px;
    margin-top:8px;
    text-align:center;

    & span{
        color:${color.blue500};
        font-weight:600;
    }
`

export const ErrorStatus = styled.div`

`

export const StatusButtons = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;

    & > *{
        flex:1;
        height:56px;
    }

    & > *:not(:last-child){
        margin-right:8px;
    }
`