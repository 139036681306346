import { useDispatch } from "react-redux"
import * as C from "./style"
import { Transition } from 'react-transition-group'

const BaseModal = ({ children, ...props }: any) => {

    const dispatch = useDispatch()

    const close = () => {
        if (props.hide) {
            if (props.isredux === true)
                dispatch(props.hide())
            else
                props.hide()
        }
    }

    const duration = 100

    const defaultStyle = {
        transition: `opacity ${duration}ms ease-in-out`,
        opacity: 0,
    }

    const transitionStyles: any = {
        entering: { opacity: 1 },
        entered: { opacity: 1 },
        exiting: { opacity: 0 },
        exited: { opacity: 0 },
    }

    return (
        <Transition in={props.show} timeout={duration} unmountOnExit>
            {state => (
                <C.Modal style={{
                    ...defaultStyle,
                    ...transitionStyles[state]
                }}>
                    <C.Overlay onClick={close}></C.Overlay>
                    <C.Dialog>
                        <C.DialogClose onClick={close}>&times;</C.DialogClose>

                        {props.header !== false && (
                            <C.DialogHeader>
                                <C.DialogTitle>
                                    {props.title ? props.title : ""}
                                </C.DialogTitle>
                            </C.DialogHeader>
                        )}

                        <C.DialogBody>
                            {children}
                        </C.DialogBody>
                    </C.Dialog>
                </C.Modal>
            )}
        </Transition>
    )


}

export default BaseModal