import crypto from 'crypto'
import nacl from 'tweetnacl'
import { Keypair } from '@solana/web3.js'
import bs58 from 'bs58'

export const unlockCloudWallet = (cloudWallet: any, key: string) => {

    const hash = crypto.createHash("sha256").update(key + cloudWallet.salt).digest()
    const seed = hash.slice(0, 32)
    const keypair = nacl.box.keyPair.fromSecretKey(seed)

    const decryptedCloudWalletPrivateKey = nacl.box.open(bs58.decode(cloudWallet.privateKey), Buffer.from(cloudWallet.salt, 'hex'), keypair.publicKey, keypair.secretKey)

    return Keypair.fromSecretKey(decryptedCloudWalletPrivateKey!)
}