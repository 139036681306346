import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { color } from 'styles/theme'
import { Hex2Rgba } from 'utils/helpers'

export const Notifications = styled.div`
    background-color: ${color.white};
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    border: 1px solid ${color.gray200};
    min-height: 680px;
    position: absolute;
    top:57px;
    right: 0;
    width: 450px;
    z-index: 20;
`

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    padding-left:16px;
    padding-right:16px;
    padding-top: 24px;
`

export const Title = styled.div`
    font-weight: bold;
    color: ${color.black};
    line-height: 100%;
`

export const MarkAllAsRead = styled.div`
    color: ${color.blue500};
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    line-height: 100%;
`

export const TabsContainer = styled.div`
    padding-left:16px;
`

export const Tabs = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 50%;
    height: 48px;
    background-color: ${color.gray100};
    border-radius: 8px;
    padding: 0 8px;
`

export const Tab = styled.div<{active?:string}>`
    height: 32px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    line-height: 125%;
    border-radius: 8px;
    padding-left: 16px;
    padding-right: 16px;
    color: ${props => props.active === "true" ? color.black : color.gray500};
    background-color: ${props => props.active === "true" ? color.white : "transparent"};
    cursor: pointer;

    &:hover, &:active{
        color: ${color.black};
    }
    user-select: none;
`

export const EmptyContent = styled.div`
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & img{
        height:220px;
        pointer-events: none;
    }
    user-select: none;
    cursor: default;
`

export const EmptyContentText = styled.div`
    margin-top: 32px;
    color: ${color.gray500};
    font-size: 20px;
    font-weight: 500;
    line-height: 125%;
`

export const Content = styled.div`
    margin-top:24px;
    paddintg-bottom: 24px;
    
    border-top: 1px solid ${color.gray200};
`

export const NotificationText = styled.div`
    color: ${color.gray600};
    font-size: 14px;
    font-weight: 500;
    line-height: 125%;
    margin-bottom: 16px;
`

export const NotificationLink = styled(Link)<{read?:string}>`
    display: flex;
    align-items: center;
    padding: 24px 16px;

    border-bottom: 1px solid ${color.gray200};

    ${props => props.read === "true" && `
        background-color: ${color.gray100};
        & ${NotificationText} >* {
            color: ${color.gray600} !important;
        }
    `}

    text-decoration: none;
    
`

export const Notification = styled.div<{read?:string}>`
    display: flex;
    align-items: center;
    padding: 24px 16px;

    border-bottom: 1px solid ${color.gray200};

    ${props => props.read === "true" && `
        background-color: ${color.gray100};
        & ${NotificationText} >* {
            color: ${color.gray600} !important;
        }
    `}
    
`

export const NotificationAvatar = styled.div`
    width: 48px;
    height: 48px;
    margin-right: 16px;

    & img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
`

export const NotificationContent = styled.div`
    flex:1;
`



export const NThighlight = styled.span`
    color: ${color.black};
`

export const NTblue = styled.span`
    color: ${color.blue500};
`

export const NTred = styled.span`
    color: ${color.red500};
`

export const NTgreen = styled.span`
    color: ${color.green500};
`

export const NTblack = styled.span`
    color: ${color.black};
    font-weight: bold;
`

export const NotificationActions = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const NotificationActionsLeft = styled.div`
    display: flex;
    align-items: center;
`

export const NotificationActionsRight = styled.div`
    display: flex;
    align-items: center;
`

export const NotificationAction = styled.div<{actiontype:string}>`
    font-size: 14px;
    font-weight: bold;
    line-height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    ${props => props.actiontype === "reject" && `
        color: ${color.gray500};

        &:hover{
            color: ${color.black};
            &::after{
                background-color:  ${Hex2Rgba(color.gray300, .4)};
            }
        }
    `}

    ${props => props.actiontype === "accept" && `
        color: ${color.blue500};

        &:hover{
            color: ${color.blue700};
            &::after{
                background-color: ${Hex2Rgba(color.blue300, .4)};
            }
        }
        
    `}

    cursor: pointer;

    &:not(:last-child){
        margin-right: 16px;
    }

    & span{
        position: relative;
        z-index: 1;
    }

    &:hover{
        &::after{
            content: "";
            position: absolute;
            width: 130%;
            height: 200%;
            border-radius: 4px;
            z-index: 0;
        }
    }
`

export const NotificationTime = styled.div`
    font-size: 12px;
    font-weight: 600;
    line-height: 100%;
    color: ${color.gray600};
`

export const NotificationActionsDelete = styled.div`
    margin-left: 16px;
    cursor: pointer;
    & svg{
        height: 14px;
        color: ${color.gray500};
    }

    &:hover{
        & svg{
            color: ${color.red500};
        }
    }
`
