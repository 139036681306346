import { useEffect, useRef, useState } from "react"
import * as C from "./style"
import { useSelector } from "react-redux"
import Logo from "components/ui/logo"
import { Button } from "components/ui/button"
import { showModal } from "utils/modal"
import { generateProfilePicture } from "utils/helpers"

const HeaderHome = (props:any) => {

    const currentAccount = useSelector((state: any) => state.user.currentAccount)
    const user = useSelector((state: any) => state.user.user)

    return (
        <C.Header theme={props.theme}>
            <C.Left>
                <C.HeaderLogo to="/">
                    <Logo />
                </C.HeaderLogo>
                <C.Nav>
                   {/* <C.NavItem to="/domains">Domains</C.NavItem>
                    <C.NavItem to="/product">Product</C.NavItem>
                    <C.NavItem to="/solutions">Solutions</C.NavItem>
                    <C.NavItem to="/pricing">Pricing</C.NavItem>
                    <C.NavItem to="/about">About</C.NavItem>*/  }
                </C.Nav>
            </C.Left>
            {user && (
                <C.Right>
                    <C.Account>
                        <C.AccountPP>
                            <img src={generateProfilePicture(user.accounts.find((item: any) => item.address === currentAccount).name)} alt="avatar" />
                        </C.AccountPP>
                        <C.AccountInfo>
                            <C.AccountName>{user.accounts.find((item: any) => item.address === currentAccount).name}</C.AccountName>
                            <C.AccountEmail> {currentAccount}</C.AccountEmail>
                        </C.AccountInfo>
                    </C.Account>
                    <Button link="routerlink" variant="primary" size="small" to="/mail">Launch App</Button>
                </C.Right>
            )}

            {!user && (
                <C.HeaderActions>
                    <Button onClick={() => showModal("register")} variant='secondary' size="small">Get Early Access</Button>
                    <Button onClick={() => showModal("login")} variant="primary" size="small">Login</Button>
                </C.HeaderActions>
            )}
        </C.Header>
    )

}

export default HeaderHome