import styled from "styled-components"
import { color } from "styles/theme"
import ReactSelect from 'react-select'

export const Input = styled.input<any>`
    width:100%;
    padding: 16px 14px;
    border-radius:8px;
    border: 1px solid ${color.gray300};
    background-color: ${color.white};
    color: ${color.gray600};
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    transition: all .1s ease-in-out;
    font-weight: 500;
    &:hover{
        border-color: ${color.gray600};
    }

    &:focus{
        outline:none;
        border-color: ${color.blue500};
        color: ${color.black};
    }

    &::placeholder{
        color: ${color.gray400};
    }

    ${props => props.state === 'error' && `
        border-color: ${color.red500};
        &:hover{
            border-color: ${color.red500};
        }
        &:focus{
            border-color: ${color.red500};
        }
    `}

    ${props => props.state === 'success' && `
        border-color: ${color.green500};
        &:hover{
            border-color: ${color.green500};
        }
        &:focus{
            border-color: ${color.green500};
        }
    `}
`

export const Select = styled(ReactSelect)<any>`
    width:100%;
`

export const Textarea = styled.textarea<any>`
    width:100%;
    padding: 16px 14px;
    border-radius:8px;
    border: 1px solid ${color.gray300};
    background-color: ${color.white};
    color: ${color.gray600};
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    transition: all .1s ease-in-out;
    font-weight: 500;
    &:hover{
        border-color: ${color.gray600};
    }

    &:focus{
        outline:none;
        border-color: ${color.blue500};
        color: ${color.black};
    }

    &::placeholder{
        color: ${color.gray300};
    }

    ${props => props.state === 'error' && `
        border-color: ${color.red500};
        &:hover{
            border-color: ${color.red500};
        }
        &:focus{
            border-color: ${color.red500};
        }
    `}

    ${props => props.state === 'success' && `
        border-color: ${color.green500};
        &:hover{
            border-color: ${color.green500};
        }
        &:focus{
            border-color: ${color.green500};
        }
    `}
`

export const InputHint = styled.div`
    font-size: 14px;
    color: ${color.gray600};
    margin-top: 6px;
    cursor: default;
`

export const InputGroup = styled.div<{icon?:string,iconright?:string,state?:string}>`
    ${props => props.icon === 'true' && `
        ${Input}{
            padding-left: 37px;
        }
    `}

    ${props => props.iconright === 'true' && `
        ${Input}{
            padding-right: 37px;
        }
    `}

    ${props => props.state === 'error' && `
        ${Input}{
            border-color: ${color.red500};
            &:hover{
                border-color: ${color.red500};
            }
            &:focus{
                border-color: ${color.red500};
            }
        }

        ${InputHint}{
            color: ${color.red500};
        }
    `}

    ${props => props.state === 'success' && `
        ${Input}{
            border-color: ${color.green500};
            &:hover{
                border-color: ${color.green500};
            }
            &:focus{
                border-color: ${color.green500};
            }
        }

        ${InputHint}{
            color: ${color.green500};
        }
    `}
`

export const InputContainer = styled.div`
    position: relative;
    width:100%;
    display: flex;
    align-items: center;
`

export const InputIcon = styled.div`
    position: absolute;
    width: 15px;
    left: 14px;
    & svg{
        width: 100%;
        color: ${color.black};
    }
    z-index: 1;
    pointer-events: none;
`

export const InputIconRight = styled.div`
    position: absolute;
    width: 15px;
    right: 14px;
    & svg{
        width: 100%;
        color: ${color.black};
    }
    pointer-events: none;
`

export const InputLabel = styled.label`
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 6px;
    display: block;
    line-height: 125%;
`

export const SelectStyles = (groupState:any) => {  return {
    control: (base:any, state:any) => ({
        ...base,
        border: `1px solid` + (groupState.state === 'error' ? color.red500 : state.isFocused ? color.blue500 : color.gray300),
        borderRadius: '8px',
        backgroundColor: color.gray50,
        color: color.gray600,
        boxShadow: 'none',
        padding: '6px 0px 6px 14px',
        fontSize: '14px',
        '&:hover': {
            borderColor:  groupState.state === 'error' ? color.red500 : color.gray600,
        },
        '&:active': {
            borderColor:  groupState.state === 'error' ? color.red500 : color.blue500,
            color: color.black,
            padding: '6px 0px 6px 14px',
        },
        cursor: 'pointer',
    }),
    valueContainer: (base:any, state:any) => ({
        ...base,
        padding: groupState.icon ? '0px 0px 0px 23px' : '0px',
    }),
    input: (base:any, state:any) => ({
        ...base,
        margin: '0px',
        padding: '0px',
    }),
    singleValue: (base:any, state:any) => ({
        ...base,
        margin: '0px',
        padding: '0px',
        color: color.gray600,
    }),
    placeholder: (base:any, state:any) => ({
        ...base,
        margin: '0px',
        padding: '0px',
        color: color.gray600,
    }),
    menu: (base:any, state:any) => ({
        ...base,
        borderRadius: '8px',
        marginTop: '8px',
    }),
    menuList: (base:any, state:any) => ({
        ...base,
        margin: '0px',
        padding: '8px',
        paddingTop: '4px'
    }),
    menuPortal: (base: any) => ({ 
        ...base, 
        zIndex: 9999,
    }),
    option: (base:any, state:any) => ({
        ...base,
        borderRadius: '8px',
        marginTop: '4px',
        backgroundColor: state.isFocused ? color.gray300 : state.isActive ? color.gray300 : color.white,
        color: color.black,
        position: 'relative',
        '&:after': {
            content: state.isSelected ? '""' : 'none',
            position: 'absolute',
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            backgroundColor: color.blue500,
            top: 'calc(50% - 4px)',
            right: '14px',
        },
        cursor: state.isDisabled ? 'not-allowed' : 'pointer',
        '&:active': {
            backgroundColor: color.blue500,
            color: color.white,
        },
    }),
    indicatorSeparator: (base:any, state:any) => ({
        ...base,
        display: 'none',
    }),
    multiValue: (base:any, state:any) => ({
        ...base,
    }),
    multiValueLabel: (base:any, state:any) => ({
        ...base,
    }),
    multiValueRemove: (base:any, state:any) => ({
        ...base,
    }),
}}