export function IconXmail() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#3166DF"
        d="M2.107 8.214a.111.111 0 010-.198l1.001-.513A7.05 7.05 0 006.17 4.442l.514-1.002a.111.111 0 01.197 0l.513 1.002a7.05 7.05 0 003.061 3.06l1.002.514c.08.041.08.156 0 .198l-1.002.513a7.05 7.05 0 00-3.06 3.06L6.88 12.79a.111.111 0 01-.197 0l-.514-1.001a7.05 7.05 0 00-3.06-3.061l-1.002-.513z"
      ></path>
      <path
        fill="#0E2E77"
        d="M4.587 8.214a.111.111 0 010-.198l1.002-.513A7.05 7.05 0 008.65 4.442l.513-1.002a.111.111 0 01.198 0l.513 1.002a7.05 7.05 0 003.06 3.06l1.003.514c.08.041.08.156 0 .198l-1.002.513a7.05 7.05 0 00-3.061 3.06L9.36 12.79a.111.111 0 01-.198 0l-.513-1.001a7.05 7.05 0 00-3.061-3.061l-1.002-.513z"
      ></path>
      <path
        fill="#1244B8"
        fillRule="evenodd"
        d="M8 5.45a7.05 7.05 0 002.433 2.053l1.002.513c.08.041.08.156 0 .198l-1.002.513A7.05 7.05 0 008 10.78a7.05 7.05 0 00-2.433-2.053l-1.001-.513a.111.111 0 010-.198l1.001-.513A7.05 7.05 0 008 5.449z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export function IconBell() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 21"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 20H8m8-13A6 6 0 004 7c0 3.09-.78 5.206-1.65 6.605-.735 1.18-1.102 1.771-1.089 1.936.015.182.054.252.2.36.133.099.731.099 1.928.099H16.61c1.196 0 1.794 0 1.927-.098.147-.11.186-.179.2-.361.014-.165-.353-.755-1.088-1.936C16.78 12.206 16 10.09 16 7z"
      ></path>
    </svg>
  );
}

export function IconContacts() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 22 20"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.5 2a3 3 0 100 6 3 3 0 000-6zm-5 3a5 5 0 1110 0 5 5 0 01-10 0zM13.573.916a1 1 0 011.302-.552 5.001 5.001 0 010 9.272 1 1 0 11-.75-1.854 3.001 3.001 0 000-5.564 1 1 0 01-.552-1.302zM6.964 12h3.072c.901 0 1.629 0 2.22.04.61.042 1.148.13 1.657.34a5 5 0 012.706 2.707c.212.51.3 1.048.34 1.656.041.592.041 1.32.041 2.222V19a1 1 0 11-2 0c0-.946 0-1.605-.036-2.12-.034-.508-.099-.803-.192-1.028a3 3 0 00-1.624-1.624c-.225-.093-.52-.158-1.027-.192C11.605 14 10.946 14 10 14H7c-.946 0-1.605 0-2.12.036-.507.034-.803.099-1.028.192a3 3 0 00-1.624 1.624c-.093.225-.158.52-.192 1.027C2 17.395 2 18.054 2 19a1 1 0 11-2 0v-.035c0-.902 0-1.63.04-2.222.042-.608.13-1.147.34-1.656a5 5 0 012.707-2.706c.51-.212 1.048-.3 1.656-.34C5.335 12 6.063 12 6.964 12zm10.068.877a1 1 0 011.217-.72A5.002 5.002 0 0122 17v2a1 1 0 11-2 0v-2a3.002 3.002 0 00-2.25-2.905 1 1 0 01-.718-1.218z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export function IconFilter() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 14"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4 7h12M1 1h18M7 13h6"
      ></path>
    </svg>
  );
}

export function IconLightning() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 14 16"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.931.722c.272.118.433.4.396.694L7.754 6h4.13c.161 0 .324 0 .457.012.126.011.366.042.575.21a1 1 0 01.371.793 1.045 1.045 0 01-.206.577 5.43 5.43 0 01-.283.358l-5.953 7.144a.667.667 0 01-1.174-.51L6.244 10h-4.13c-.16 0-.323 0-.456-.012a1.045 1.045 0 01-.576-.21 1 1 0 01-.37-.793c.003-.268.134-.473.206-.577.076-.11.18-.234.283-.358l.015-.018L7.154.907A.667.667 0 017.93.722zM2.423 8.667h4.576a.667.667 0 01.662.75l-.385 3.076 4.3-5.16H6.999a.667.667 0 01-.661-.749l.384-3.077-4.3 5.16z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export function IconVerified() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.91 1.436a3.221 3.221 0 014.179 0l-.428.503.428-.503c.302.258.678.414 1.074.445a3.221 3.221 0 012.955 2.955c.032.396.187.772.445 1.075a3.221 3.221 0 010 4.178 1.888 1.888 0 00-.445 1.075 3.221 3.221 0 01-2.955 2.955 1.888 1.888 0 00-1.074.445 3.222 3.222 0 01-4.18 0 1.888 1.888 0 00-1.074-.445 3.221 3.221 0 01-2.954-2.955 1.888 1.888 0 00-.445-1.075l.502-.427-.502.427a3.221 3.221 0 010-4.178c.257-.303.413-.679.445-1.075a3.221 3.221 0 012.954-2.955 1.888 1.888 0 001.075-.445zm3.314 1.015a1.888 1.888 0 00-2.45 0 3.221 3.221 0 01-1.832.76A1.888 1.888 0 003.21 4.941a3.221 3.221 0 01-.76 1.833 1.888 1.888 0 000 2.45l-.507.432.507-.432c.44.516.706 1.157.76 1.833a1.888 1.888 0 001.732 1.732c.675.054 1.317.32 1.833.76a1.888 1.888 0 002.449 0 3.222 3.222 0 011.833-.76 1.888 1.888 0 001.732-1.732c.054-.676.32-1.317.76-1.833a1.888 1.888 0 000-2.45 3.221 3.221 0 01-.76-1.833 1.888 1.888 0 00-1.732-1.732 3.221 3.221 0 01-1.833-.759zm1.58 3.411c.26.26.26.682 0 .943l-3 3a.667.667 0 01-.943 0L5.528 8.47a.667.667 0 11.943-.942l.862.862L9.86 5.862c.26-.26.683-.26.943 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export function IconEyeOff() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 22 20"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M1.293.293a1 1 0 011.415 0l3.638 3.639c.059.046.112.1.16.16l10.391 10.39c.068.053.13.114.184.185l3.627 3.626a1 1 0 01-1.415 1.414l-3.153-3.154C14.695 17.4 12.972 18 11 18c-2.861 0-5.194-1.265-6.923-2.707-1.726-1.441-2.91-3.107-3.502-4.046a12.87 12.87 0 00-.024-.038c-.118-.185-.27-.425-.348-.762a2.159 2.159 0 010-.894c.077-.337.23-.578.348-.764l.025-.038c.602-.953 1.825-2.676 3.62-4.141L1.293 1.707a1 1 0 010-1.414zm4.325 5.74C3.98 7.325 2.834 8.92 2.267 9.818a9.406 9.406 0 00-.11.18V10a9.608 9.608 0 00.11.178c.531.844 1.585 2.32 3.092 3.578C6.863 15.012 8.757 16 11 16c1.353 0 2.578-.359 3.667-.919l-1.635-1.635a4 4 0 01-5.478-5.478L5.618 6.032zm3.45 3.449a2 2 0 002.45 2.45l-2.45-2.45zM11 4c-.381 0-.752.028-1.11.082a1 1 0 11-.293-1.979A9.587 9.587 0 0111 2c2.862 0 5.195 1.265 6.924 2.707 1.726 1.441 2.91 3.107 3.502 4.046l.024.038c.118.185.27.425.348.762.062.272.062.622 0 .894-.077.337-.23.578-.349.764l-.024.039a18.487 18.487 0 01-1.442 1.972 1 1 0 11-1.533-1.284 16.52 16.52 0 001.395-1.937V10v-.001a1.379 1.379 0 00-.03-.049l-.08-.13c-.532-.843-1.587-2.32-3.093-3.577C15.138 4.988 13.244 4 11 4z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export function IconLogout() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4.173.333H5a.667.667 0 110 1.334h-.8c-.57 0-.96 0-1.26.025-.291.024-.44.067-.545.12-.251.128-.455.332-.583.583-.053.104-.096.253-.12.546-.025.3-.025.688-.025 1.259v5.6c0 .571 0 .96.025 1.26.024.291.067.441.12.545.128.251.332.455.583.583.104.053.254.096.546.12.3.025.688.025 1.26.025H5a.667.667 0 110 1.334h-.828c-.537 0-.98 0-1.34-.03-.375-.03-.72-.096-1.043-.261A2.667 2.667 0 01.624 12.21c-.165-.324-.23-.668-.261-1.043-.03-.361-.03-.804-.03-1.34V4.171c0-.536 0-.98.03-1.34.03-.375.096-.72.261-1.043C.88 1.288 1.288.88 1.79.624c.323-.165.668-.23 1.042-.261.361-.03.804-.03 1.34-.03zm5.022 2.862c.26-.26.683-.26.943 0l3.334 3.334c.26.26.26.682 0 .942l-3.334 3.334a.667.667 0 01-.943-.943l2.196-2.195H5a.667.667 0 010-1.334h6.39L9.195 4.138a.667.667 0 010-.943z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}