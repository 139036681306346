import React, { useState } from 'react'
import * as C from './style'
import Address from './address'
import Password from './password'
import Wallet from './wallet'
import { CheckLoginMethod, login } from 'services/api/auth'
import { toast } from 'react-hot-toast'
import { setLocalData } from 'utils/localData'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setUser, setCurrentAccount } from 'services/redux/slices/user'
import { setServerPublicKey } from 'services/redux/slices/mail'
import bs58 from 'bs58'

const Login = (props: any) => {

    const [step, setStep] = useState(0)
    const [mailxAddress, setMailxAddress] = useState("")
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [walletDetails, setWalletDetails] = useState<any>(null)

    const onAddressFinish = (address: string) => {

        if (loading)
            return;

        setLoading(true)

        CheckLoginMethod(address).then((res) => {
            setMailxAddress(address)
            if (res.data.type === "both") {
                setStep(3)
                setWalletDetails(res.data.wallet)
            } else if (res.data.type === "password") {
                setStep(1)
                setWalletDetails(null)
            } else if (res.data.type === "wallet") {
                setStep(2)
                setWalletDetails(res.data.wallet)
            }
            setLoading(false)

        }).catch((err) => {
            if (err.response.status === 404) {
                toast.error("Email is not registered")
            } else {
                toast.error("Something went wrong, please try again")
            }
            setLoading(false)
        })
    }

    const switchMethod = (to: string) => {
        setStep(to === "password" ? 1 : 2)
    }

    const onPasswordFinish = (password: string) => {
        if (loading)
            return;
        setLoading(true)
        login(mailxAddress, password).then((res) => {

            setLocalData("token", res.data.token)
            setLocalData("key", bs58.encode(new TextEncoder().encode(password)))
            setLocalData("selectedAccount", mailxAddress)
            navigate("/mail")

            dispatch(setUser({
                accounts: res.data.accounts,
                names: res.data.names,
                cloudWallet: res.data.cloudWallet,
            }))

            dispatch(setCurrentAccount(mailxAddress))
            dispatch(setServerPublicKey(res.data.c))

            props.hide()

        }).catch((err) => {
            //if 401
            if (err.response.status === 401 || err.response.status === 404) {
                toast.error("Email or password is incorrect")
            } else {
                toast.error("Something went wrong, please try again")
            }
            setLoading(false)
        })
    }

    const onWalletFinish = (res: any, signature: any) => {
        setLocalData("token", res.data.token)
        setLocalData("key", bs58.encode(new TextEncoder().encode(signature)))
        setLocalData("selectedAccount", mailxAddress)
        navigate("/mail")
        props.hide()

        dispatch(setUser({
            accounts: res.data.accounts,
            names: res.data.names,
            cloudWallet: res.data.cloudWallet,
        }))

        dispatch(setCurrentAccount(mailxAddress))
        dispatch(setServerPublicKey(res.data.c))
    }

    const back = () => {
        if (step === 1) {
            setStep(0)
        }
    }

    return (
        <C.Login>

            {step === 0 && <Address mailxAddress={mailxAddress} onAddressFinish={onAddressFinish} />}
            {step === 1 && <Password switchMethod={switchMethod} loading={loading} mailxAddress={mailxAddress} back={back} onPasswordFinish={onPasswordFinish} />}
            {step === 2 && <Wallet walletDetails={walletDetails} password={false} loading={loading} mailxAddress={mailxAddress} back={back} onWalletFinish={onWalletFinish} />}
            {step === 3 && <Wallet walletDetails={walletDetails} switchMethod={switchMethod} password={true} loading={loading} mailxAddress={mailxAddress} back={back} onWalletFinish={onWalletFinish} />}

        </C.Login>
    )

}

export default Login
