import { useEffect, useRef, useState } from "react"
import * as C from "./style"
import { Button } from "components/ui/button"
import { getModalCallbacks, getModalProps, updateModalProps } from "utils/modal"
import { useSelector } from "react-redux"
import { voteTpp } from "services/api/mail"
import bs58 from "bs58"
import { getCryptoPair } from "modules/mail/asymetric"
import { unlockCloudWallet } from "modules/mail/helpers"
import nacl from "tweetnacl"
import { getLocalData } from "utils/localData"
import toast from "react-hot-toast"
import { generateProfilePicture } from "utils/helpers"
const TppDeleteVote = () => {

    const props = getModalProps(useSelector((state: any) => state.data.modalStates), "tppDeleteVote")
    const user = useSelector((state: any) => state.user.user)
    const currentAccount = useSelector((state: any) => state.user.currentAccount)
    const serverPublicKey = useSelector((state: any) => state.mail.serverPublicKey)

    const [users, setUsers] = useState<any[]>([])
    const [myTppStatus, setMyTppStatus] = useState<any>(null)

    useEffect(() => {
        setUsers([])
        setMyTppStatus(null)

        if (props !== null) {
            let _users: any = [props.mail.from, ...props.mail.to]

            for (let i = 0; i < _users.length; i++) {
                let tppStatus = props.mail.tppStatus[i]
                _users[i] = { ..._users[i], tppStatus: tppStatus }
            }
            //find me and put me on first
            let me = _users.filter((u: any) => u.address === currentAccount)[0]
            _users = _users.filter((u: any) => u.address !== currentAccount)
            _users.unshift(me)

            setMyTppStatus(parseStatus(_users[0].tppStatus))
            setUsers(_users)
        }

    }, [props])

    const parseStatus = (tppStatus: any) => {
        if (tppStatus.accepted === false)
            return {
                votetype: "not-accepted",
                text: "not accepted mail"
            }

        if (tppStatus.accepted === null)
            return {
                votetype: "not-accepted-yet",
                text: "not accepted yet"
            }

        if (tppStatus.lostRights === true)
            return {
                votetype: "lost-rights",
                text: "lost rights"
            }

        if (tppStatus.delete === false)
            return {
                votetype: "voted-no-delete",
                text: "voted for store"
            }

        if (tppStatus.delete === true)
            return {
                votetype: "voted-delete",
                text: "voted for delete"
            }

        if (tppStatus.delete === null)
            return {
                votetype: "not-voted",
                text: "not voted"
            }

    }

    const vote = (vote: "store" | "delete") => {
        let key: any = new TextDecoder().decode(bs58.decode(getLocalData("key")))
        let cloudWallet = unlockCloudWallet(user.cloudWallet, key.toString())
        let cryptoPair = getCryptoPair(cloudWallet)

        key = nacl.box(new TextEncoder().encode(key), Buffer.from(user.cloudWallet.salt, 'hex'), bs58.decode(serverPublicKey), cryptoPair.secretKey)

        voteTpp(currentAccount, props.mail.address, bs58.encode(key), vote).then((res: any) => {
            toast.success("Your vote has been sent successfully.")
            getModalCallbacks("tppDeleteVote").updateTppStatus(res.data.tppStatus)
            updateModalProps("tppDeleteVote", { mail: { ...props.mail, tppStatus: res.data.tppStatus } })
        }).catch((err: any) => {
            console.log(err)
            toast.error("An error occurred while sending your vote.")
        })

    }

    if (myTppStatus === null)
        return <></>

    return (
        <C.TppDeleteVote>
            <C.Title>
                Vote for Deletion of This Mail
            </C.Title>
            <C.Description>
                If you think this mail should be deleted, please vote for deletion. or if you think this mail should be stored, please vote for store.
            </C.Description>
            <C.List>
                <C.ListTitle>
                    People on This Mail
                </C.ListTitle>
                <C.ListContainer>
                    {users.map((u: any, i: number) => {
                        let status = parseStatus(u.tppStatus)
                        return (
                            <C.ListItem>
                                <C.ListItemLeft>
                                    <C.ListItemLeftAvatar>
                                        <img src={generateProfilePicture(u.name)} />
                                    </C.ListItemLeftAvatar>
                                    <C.ListItemLeftDetails>
                                        <C.ListItemLeftDetailsName>
                                            {u.name} {i === 0 && <span>You</span>}
                                        </C.ListItemLeftDetailsName>
                                        <C.ListItemLeftDetailsEmail>
                                            {u.address}
                                        </C.ListItemLeftDetailsEmail>
                                    </C.ListItemLeftDetails>
                                </C.ListItemLeft>
                                <C.ListItemRight>
                                    <C.ListItemRightVoteStatus votetype={status!.votetype}>
                                        {status!.text}
                                    </C.ListItemRightVoteStatus>
                                </C.ListItemRight>
                            </C.ListItem>
                        )
                    })}
                </C.ListContainer>
            </C.List>

            {myTppStatus.votetype === "not-voted" && (
                <C.Actions>
                    <Button variant="secondary" size="large" onClick={() => vote("store")}>Vote for Store</Button>
                    <Button variant="primary" size="large" onClick={() => vote("delete")}>Vote for Delete</Button>
                </C.Actions>
            )}
            {myTppStatus.votetype === "lost-rights" && (
                <C.Actions>
                    <Button variant="secondary" size="large" disabled>Vote for Store</Button>
                    <Button variant="primary" size="large" disabled>Vote for Delete</Button>
                </C.Actions>
            )}
            {myTppStatus.votetype === "voted-no-delete" && (
                <C.Actions>
                    <Button variant="secondary" size="large" disabled>Vote for Store</Button>
                    <Button variant="primary" size="large" onClick={() => vote("delete")}>Vote for Delete</Button>
                </C.Actions>
            )}
            {myTppStatus.votetype === "voted-delete" && (
                <C.Actions>
                    <Button variant="secondary" size="large" onClick={() => vote("store")}>Vote for Store</Button>
                    <Button variant="primary" size="large" disabled>Vote for Delete</Button>
                </C.Actions>
            )}
        </C.TppDeleteVote>
    )
}

export default TppDeleteVote