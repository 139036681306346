import { EncryptStorage } from 'encrypt-storage'
import config from 'config'
import crypto from 'crypto'

export const encryptStorage = new EncryptStorage(config.SECRET.LOCAL_STORAGE)

export const getLocalData = (key: string) => {
    try {
        key = crypto.createHash('sha256').update(key).digest('hex')
        return encryptStorage.getItem(crypto.createHash('sha256').update("x"+key).digest('hex'))
    } catch (e) {
        return null
    }
}

export const setLocalData = (key: string, value: any) => {
    key = crypto.createHash('sha256').update(key).digest('hex')
    return encryptStorage.setItem(crypto.createHash('sha256').update("x"+key).digest('hex'), value)
}

export const removeLocalData = (key: string) => {
    key = crypto.createHash('sha256').update(key).digest('hex')
    return encryptStorage.removeItem(crypto.createHash('sha256').update("x"+key).digest('hex'))
}