import { InputGroup, InputIcon } from 'components/ui/input/style'
import { Logo } from 'components/ui/logo/style'
import styled from 'styled-components'
import { Container } from 'styles'
import { color } from 'styles/theme'
import { Hex2Rgba } from 'utils/helpers'

export const DomainPage = styled.div`

`

export const DomainHeroContainer = styled.div`
    padding:0 40px;
`

export const DomainHero = styled.div`
    min-height:calc(100vh - 100px - 40px);
    background-image:url('/images/landing-bg.png');
    background-size:cover;
    background-position:center;
    background-repeat:no-repeat;
    border-radius:8px;
    padding:0 88px;
    display:flex;
    flex-direction:column;
    justify-content:center;
`

export const Promo = styled.div`
    padding:16px 32px;
    background-color:${Hex2Rgba(color.blue500, 0.32)};
    color:${color.blue100};
    width:fit-content;
    margin-bottom:16px;
    border-radius:8px;
    font-size:24px;
    font-weight:bold;
    line-height:125%;
`

export const Title = styled.div`
    font-size:48px;
    color:${color.white};
    line-height:125%;
    font-weight:bold;
    letter-spacing:-0.02em;
`

export const HeroTexts = styled.div`
    margin-top:32px;
`

export const HeroText = styled.div`
    display:flex;
    align-items:center;
    margin-bottom:12px;
`

export const HeroTextIcon = styled.div`
    width:24px;
    height:24px;
    border-radius:50%;
    background-color:${color.blue300};
    margin-right:12px;
    color:${color.black};
`

export const HeroTextContent = styled.div`
    color:${color.blue300};
    line-height:125%;
`

export const HeroDomainSearch = styled.form`
    margin-top:52px;
    max-width:45%;
    & input{
        
        padding:24px 14px;
        padding-left:56px !important;
        font-size:16px;
        border:none !important;
    }

    & ${InputIcon} {
        left:19px;
        & svg{
            width:24px;
        }
    }

    & ${InputGroup} {
        position:relative;
    }

    & button{
        position:absolute;
        right:16px;
        width:124px;
    }
`

//domain results

export const DomainSearhPage = styled.div`

`

export const DomainSearch = styled.div`
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
    padding:32px;
    border-radius:8px;
    background-color:${color.white};
    margin-top:48px;
`

export const DomainSearchInput = styled.form`
    & ${InputGroup} {
        position:relative;
    }

    & button{
        position:absolute;
        right:16px;
        width:124px;
        height:40px;
        font-size:14px;
        padding:0;
    }

    & input{
        padding:24px 14px;
        padding-left: 53px !important;
        font-size:16px;
    }

    & ${InputIcon} {
        left:19px;
        & svg{
            width:18px;
        }
    }
`

export const DomainSearchResult = styled.div`
    margin-top:64px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin-bottom:32px;
`

export const DomainResultLeft = styled.div`
    display:flex;
    align-items:center;
`

export const DomainResultIcon = styled.div<{isavailable:string}>`
    width:56px;
    height:56px;
    border-radius:50%;
    background-color:${props => props.isavailable === 'true' ? color.blue500 : color.red500};
    color:${color.white};
    display:flex;
    align-items:center;
    justify-content:center;
    & svg{
        width:24px;
    }
    margin-right:16px;
`

export const DomainResultContent = styled.div`
    display:flex;
    align-items:center;
`

export const DomainResultDomain = styled.div`
    font-size:32px;
    font-weight:600;
    line-height:100%;
    color:${color.black};
`

export const DomainResultText = styled.div`
    color:${color.gray400};
    font-size:18px;
    line-height:125%;
    margin-left:16px;
`

export const DomainResultRight = styled.div`
    display:flex;
    & button{
        width:266px;
    }
`

export const DomainResultPricePart = styled.div`
    margin-right:32px;
    display:flex;
    flex-direction:column;
    align-items:flex-end;
`

export const DomainResultPrice = styled.div`
    color:${color.black};
    font-size:24px;
    font-weight:bold;
    line-height:100%;

`

export const DomainResultPriceText = styled.div`
    color:${color.gray500};
    line-height:125%;
    margin-top:8px;
`

export const Loading = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    margin-top:64px;

    & > div{
        width:48px;
        height:48px;
    }
`