import styled from 'styled-components'
import { color } from 'styles/theme'

export const Reply = styled.div`
    flex:1;
    overflow-y: auto;
    max-height: calc(100vh - 88px);
`


export const Header = styled.div`
    display:flex;
    padding:24px 32px;
    justify-content:space-between;
    align-items:center;
`

export const HeaderLeft = styled.div`
    & > button{
        width:170px;
    }
    display:flex;
    align-items:center;
`


export const HeaderRight = styled.div`
    display:flex;
    align-items:center;
`

export const HeaderAction = styled.div<{actiontype:string}>`
    &:not(:last-child){
        margin-right:24px;
    }
    cursor:pointer;
    & svg{
        height:21px;
    }
    ${props => props.actiontype === 'attach' && `
        color:${color.gray700};
    `}
    ${props => props.actiontype === 'close' && `
        color:${color.blue900};
    `}
`


export const Body = styled.div`
    display:flex;
    flex-direction:column;
    flex:1;
`


export const EmailInputArea = styled.div<{empty:string}>`
    background-color:${color.gray50};
    border-bottom:2px solid ${color.gray100};
    width:100%;
    padding:21px 32px;
    font-size:16px;
    font-weight:600;
    color:${color.gray600};
    line-height:125%;
    display:flex;
    align-items:center;
    flex-wrap:wrap;

    ${props => props.empty === 'false' && `
        padding-bottom:13px;
    `}
`

export const EmailInputLabel = styled.div<{isvalid:string}>`
    background-color:${color.gray200};
    padding:4px 8px;
    font-weight:500;
    line-height:125%;
    border-radius:8px;
    display:flex;
    align-items:center;
    position:relative;
    cursor:default;
    margin-right:8px;
    margin-bottom:8px;
    &:hover{
        & span{
            display:block;
        }
    }
    & span{
        right:8px;
        position:absolute;
        color:${color.blue500};
        display:none;
        font-size:24px;
        background-color:${color.gray200};
        padding:4px;
        cursor:pointer;
    }

    ${props => props.isvalid === 'false' && `
        background-color:${color.red300};
        & span{
            color:${color.red500};
            background-color:${color.red300};
        }
    `}
`

export const EmailInput = styled.input`
    background:transparent;
    border:none;
    outline:none;
    font-size:16px;
    font-weight:600;
    color:${color.gray600};
`



export const Input = styled.input`
    background-color:${color.gray50};
    border:none;
    border-bottom:2px solid ${color.gray100};
    width:100%;
    padding:21px 32px;
    outline:none;
    font-size:16px;
    font-weight:600;
    color:${color.gray600};
    line-height:125%;
`

export const Text = styled.div`
    flex:1;
    display:flex;
    flex-direction:column;
    & .ck-editor{
        flex:1;
        display:flex;
        flex-direction:column;
    }
    
    & .ck-editor__main{
        flex:1;
    }

    & .ck-toolbar_grouping{
        border:none;
        background-color:transparent;
        min-height:56px;
    }
    
    & .ck-editor__editable_inline {
        min-height: 72px;
        height: 100%;
        border:0px !important;
        box-shadow:none !important;
        background-color:${color.gray50} !important;
        padding-left:32px;
        padding-right:32px;
    }
`

export const ThreadContainer = styled.div`
    padding:32px;
`

export const Thread = styled.div`
    background-color:${color.gray100};
    border-radius:16px;
`

export const ThreadMail = styled.div`
    padding:32px;
`

export const TMHeader = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin-bottom:24px;
`

export const TMHeaderLeft = styled.div`
    display:flex;
    align-items:center;
`

export const TMHeaderLeftAvatar = styled.div`
    width:40px;
    height:40px;

    & img{
        width:100%;
        height:100%;
        border-radius:50%;
    }

    margin-right:16px;
`

export const TMHeaderFromToContent = styled.div`
    
`

export const TMHeaderFromDetails = styled.div`
    display:flex;
    align-items:center;
    margin-bottom: 8px;
`

export const TMHeaderFromName = styled.div`
    color:${color.black};
    font-weight:600;
    line-height:100%;
`

export const TMHeaderFromAddress = styled.div`
    color: ${color.gray600};
    font-size: 14px;
    font-weight: 500;
    margin-left: 8px;
`

export const TMHeaderToName = styled.div`
    font-size: 12px;
    color: ${color.gray500};
    font-weight: 500;
    line-height: 100%;
`

export const TMHeaderRight = styled.div`
    
`

export const TMHeaderRightDate = styled.div`
    color:${color.blue500};
    font-size:12px;
    font-weight:500;
`

export const TMContent = styled.div`

`

export const TMContentBody = styled.div`
    position: relative;
`