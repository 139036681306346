import styled from 'styled-components'
import { color } from 'styles/theme'

export const Compose = styled.div`
    flex:1;
    display:flex;
    flex-direction:column;
    background-color:${color.gray50};
`

export const Header = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding:32px;
    border-bottom:2px solid ${color.gray100};
`

export const Title = styled.div`
    font-size:20px;
    line-height:100%;
    font-weight:600;
    color:${color.black};
`

export const Close = styled.div`
    font-size:30px;
    cursor:pointer;
`

export const Body = styled.div`
    padding:32px;
    display:flex;
    flex-direction:column;
    flex:1;
`

export const Recipients = styled.div`
    display:flex;
    align-items:center;
`

export const InputLabel = styled.div`
    font-size:16px;
    line-height:125%;
    font-weight:600;
    color:${color.black};
    white-space:nowrap;
    margin-right:16px;
`

export const RecipientsInput = styled.input`
    background:transparent;
    border:none;
    outline:none;
    font-size:16px;
    font-weight:600;
    color:${color.gray600};
    flex:1;
    padding:13px;

    &::placeholder{
        color:${color.gray400};
        font-weight:400;
    }
`

export const RecipientsArea = styled.div<{empty:string}>`
    background-color:${color.white};
    width:100%;
    
    font-size:16px;
    font-weight:600;
    color:${color.gray600};
    line-height:125%;
    display:flex;
    align-items:center;
    flex-wrap:wrap;
    border-radius:8px;

    ${props => props.empty === 'false' && `
        padding-bottom:6px;
        padding-top:6px;
        padding:6px 12px; 
        & > input{
            padding:7px;
        }
    `}
`

export const RecipientLabel = styled.div<{isvalid:string}>`
    background-color:${color.gray200};
    padding:4px 8px;
    font-weight:500;
    line-height:125%;
    border-radius:8px;
    display:flex;
    align-items:center;
    position:relative;
    cursor:default;
    margin-right:8px;
    margin-bottom:3px;
    margin-top:3px;
    color:${color.black};
    border:1px solid ${color.gray200};
    & span{
        right:8px;
        position:absolute;
        color:${color.blue500};
        display:none;
        font-size:24px;
        background-color:${color.gray200};
        padding:4px;
        cursor:pointer;
    }

    &:hover{
        & span{
            display:block;
        }
    }

    ${props => props.isvalid === 'false' && `
        background-color:${color.red100};
        color:${color.red900};
        border:1px solid ${color.red300};
        & span{
            color:${color.red500};
            background-color:${color.red100};
        }
    `}
`



export const Subject = styled.div`
    display:flex;
    align-items:center;
    margin-top:24px;
`

export const SubjectInput = styled.input`
    background:${color.white};
    border:none;
    outline:none;
    font-size:16px;
    font-weight:600;
    color:${color.gray600};
    flex:1;
    padding:13px;
    border-radius:8px;

    &::placeholder{
        color:${color.gray400};
        font-weight:400;
    }
`

export const Text = styled.div`
    margin-top:24px;
    margin-bottom:8px;
    flex:1;
    overflow-y:auto;
    background-color:${color.white};
    border-radius:8px;
    padding:8px 24px;

    & > div{
        min-height:92%;
        outline:none !important;
        
    }
`

export const Footer = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin-top:36px;
`

export const WysiwygActions = styled.div`
    display:flex;
    align-items:center;
`

export const WysiwygActionSeperator = styled.div`
    width:1px;
    height:24px;
    background-color:${color.gray300};
    margin-right:10px;
    margin-left:10px;
`

export const WysiwygAction = styled.div<{wtype:string,isactive?:string}>`
    cursor:pointer;
    margin-right:10px;
    margin-left:10px;
    width:24px;
    height:24px;
    display:flex;
    align-items:center;
    justify-content:center;
    & svg{
        ${props => props.wtype === 'text' && `
            height:18px;
        `}
        ${props => props.wtype === 'align' && `
            height:14px;
        `}
        ${props => props.wtype === 'attach&link' && `
            height:21px
        `}
    }

    ${props => props.isactive === 'true' && `
        background-color:${color.gray300};
        border-radius:4px;
    `}
`

export const WysiwygActionLabel = styled.label<{wtype:string,isactive?:string}>`
    cursor:pointer;
    margin-right:10px;
    margin-left:10px;
    width:24px;
    height:24px;
    display:flex;
    align-items:center;
    justify-content:center;
    & svg{
        ${props => props.wtype === 'text' && `
            height:18px;
        `}
        ${props => props.wtype === 'align' && `
            height:14px;
        `}
        ${props => props.wtype === 'attach&link' && `
            height:21px
        `}
    }

    ${props => props.isactive === 'true' && `
        background-color:${color.gray300};
        border-radius:4px;
    `}
`

export const AttachmentInput = styled.input`
    visibility:hidden;
    width:0px;
    height:0px;
`

export const Attachments = styled.div`
    overflow-y:auto;
    max-height:75px;
`

export const AttachmentsBox = styled.div`
    background-color:${color.white};
    border-radius:8px;
    padding:16px;
    width:100%;
`

export const AttachmentsHeader = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin-bottom:16px;
`

export const AttachmentsTitle = styled.div`
    font-size:14px;
    color:${color.black};
    font-weight:600;
    line-height:125%;

    & span{
        color:${color.blue500};
        margin-left:4px;
    }
`

export const AttachmentsAction = styled.div`
    cursor:pointer;
    color:${color.gray400};
    font-size:12px;
    line-height:125%;
    font-weight:600;

    & svg{
        height:10px;
        margin-left:6px;
    }

    &:hover{
        color:${color.gray500};
    }
`

export const Attachment = styled.div`
    background-color: ${color.gray100};
    min-width:245px;
    margin-right:16px;
    border-radius: 8px;
    border:1px solid ${color.gray200};
    overflow:hidden;
    white-space: nowrap;
    display:inline-block;
    height:60px;
`


export const AttachmentTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:8px;
`
export const AttachmentLeft = styled.div`
    display: flex;
    align-items: center;
`

export const AttachmentIcon = styled.div`
    border-radius: 50%;
    background-color: ${color.white};
    display: flex;
    align-items: center;
    justify-content: center;
    width:40px;
    height: 40px;
    margin-right: 16px;
    & svg{
        height: 18px;
    }
`

export const AttachmentDetails = styled.div`

`

export const AttachmentName = styled.div`
    font-weight: 600;
    line-height: 100%;
    font-size: 14px;
    color: ${color.black};
`

export const AttachmentSize = styled.div`
    font-size: 12px;
    line-height: 100%;
    color: ${color.gray500};
    font-weight: 500;
    margin-top: 8px;
`

export const AttachmentCancel = styled.div`
    color: ${color.gray500};
    padding:8px 4px 8px 8px;
    cursor:pointer;
    & svg{
        height: 10px;
    }

    &:hover{
        color: ${color.gray600};
    }
`

export const AttachmentProgress = styled.div`
    width:100%;
    position:relative;
    height:2px;
`

export const AttachmentProgressValue = styled.div`
    position:absolute;
    height:100%;
    width:25%;
    animation:blindloading 1s linear infinite;
    left:0px;
    background-color:${color.blue300};
    border-radius:4px;

    @keyframes blindloading {
        0% {
            left:-25%;
        }
        100% {
            left:125%;
        }
    }
`

export const AttachmentError = styled.div`
    width:100%;
    height:2px;
    background-color:${color.red500};
`

export const AttachmentErrorTitle = styled.div`
    font-weight: 600;
    line-height: 100%;
    font-size: 14px;
    color: ${color.black};
    & svg{
        color:${color.red500};
        height:12px;
    }
`

export const MailActions = styled.div`
    
    display:flex;
    align-items:center;

    & > *:nth-child(1) {
        margin-right:8px;
        width:192px;
    }

    & button{
        white-space:nowrap;
        margin-left:8px;
        width:170px;
    }

    & > *:nth-child(1) > div:nth-child(3) {
        padding:2px 0px 2px 14px;
    }
`

export const MailActionsSeperator = styled.div`
    width:1px;
    height:34px;
    background-color:${color.gray300};
`