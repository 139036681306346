export function IconAttach() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 15"
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M10.98 2.788a1.667 1.667 0 00-2.357 0l-6.01 6.01a2.833 2.833 0 004.006 4.007l6.01-6.01a.667.667 0 11.944.943l-6.01 6.01A4.167 4.167 0 111.67 7.856l6.01-6.01a3 3 0 014.243 4.242l-5.775 5.775A1.833 1.833 0 013.555 9.27l5.068-5.068a.667.667 0 01.943.943l-5.068 5.068a.5.5 0 10.707.707l5.775-5.775c.65-.65.65-1.706 0-2.357z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export function IconEnvelope() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 22 18"
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M5.759 0H16.24c.805 0 1.47 0 2.01.044.563.046 1.08.145 1.565.392a4 4 0 011.748 1.748c.239.468.339.966.387 1.505a1 1 0 01.03.5c.02.45.02.971.02 1.57v6.482c0 .805 0 1.47-.045 2.01-.046.563-.145 1.08-.392 1.565a4 4 0 01-1.748 1.748c-.485.247-1.002.346-1.564.392-.541.044-1.206.044-2.01.044H5.758c-.805 0-1.47 0-2.01-.044-.563-.046-1.08-.145-1.565-.392a4 4 0 01-1.748-1.748C.19 15.331.09 14.814.044 14.252 0 13.71 0 13.046 0 12.242V5.758c0-.599 0-1.12.018-1.57a1 1 0 01.031-.5c.048-.54.148-1.037.387-1.505A4 4 0 012.184.436C2.67.189 3.186.09 3.748.044 4.29 0 4.954 0 5.758 0zM2 5.92v6.28c0 .857 0 1.439.038 1.889.035.438.1.663.18.819a2 2 0 00.874.874c.156.08.38.145.82.18C4.361 16 4.943 16 5.8 16h10.4c.857 0 1.439 0 1.889-.038.439-.035.663-.1.82-.18a2 2 0 00.873-.874c.08-.156.145-.38.18-.819.037-.45.038-1.032.038-1.889V5.92l-6.591 4.615a42.26 42.26 0 00-.116.08c-.544.383-1.023.719-1.567.855a3 3 0 01-1.452 0c-.544-.136-1.022-.472-1.567-.854a42.326 42.326 0 00-.115-.081L2 5.92zm17.917-2.383l-7.655 5.36c-.73.51-.884.598-1.02.632a1 1 0 01-.484 0c-.136-.034-.29-.123-1.02-.633L2.083 3.537c.036-.207.082-.34.135-.445a2 2 0 01.874-.874c.156-.08.38-.145.82-.18C4.361 2 4.943 2 5.8 2h10.4c.857 0 1.439 0 1.889.038.439.035.663.1.82.18a2 2 0 01.873.874c.053.104.1.238.135.445z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export function IconMailForward() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 12 12"
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M7.529.195c.26-.26.682-.26.942 0l3.334 3.334c.26.26.26.682 0 .942L8.47 7.805a.667.667 0 11-.942-.943l2.195-2.195H7.067c-1.131 0-1.94 0-2.573.052-.626.051-1.022.149-1.34.311-.628.32-1.138.83-1.457 1.457-.162.318-.26.714-.311 1.34-.052.634-.053 1.442-.053 2.573v.933a.667.667 0 11-1.333 0v-.963C0 9.275 0 8.413.057 7.72c.058-.709.178-1.3.452-1.838a4.667 4.667 0 012.039-2.039c.538-.274 1.129-.394 1.837-.452.694-.057 1.557-.057 2.652-.057h2.687L7.529 1.138a.667.667 0 010-.943z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export function IconPaperplane() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 22 20"
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M3.039.82l.033.016 16.75 7.537c.229.103.458.206.636.306.166.094.486.287.669.656a1.5 1.5 0 010 1.33c-.183.369-.503.562-.669.655-.178.1-.407.204-.635.306l-16.778 7.55c-.235.106-.468.21-.665.28-.18.061-.544.176-.946.064a1.5 1.5 0 01-.994-.9c-.152-.39-.074-.762-.03-.948.048-.203.13-.445.21-.689l2.326-6.946-2.32-6.993C.62 3.033.617 3.021.613 3.01a8.651 8.651 0 01-.209-.687c-.044-.186-.12-.558.032-.947a1.5 1.5 0 01.994-.897c.402-.112.765.002.945.065.197.068.43.172.663.278zm-.372 2.026L4.709 9H9.5a1 1 0 010 2H4.733l-2.059 6.15L18.564 10 2.667 2.846z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export function IconMailReply() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 12 12"
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M4.471.195c.26.26.26.683 0 .943L2.276 3.333h2.687c1.095 0 1.958 0 2.652.057.708.058 1.3.178 1.837.452a4.667 4.667 0 012.04 2.04c.273.537.393 1.128.451 1.837C12 8.413 12 9.275 12 10.37v.963a.667.667 0 01-1.333 0V10.4c0-1.131 0-1.94-.053-2.573-.05-.626-.149-1.022-.31-1.34A3.334 3.334 0 008.846 5.03c-.319-.162-.715-.26-1.34-.311-.634-.052-1.443-.052-2.574-.052H2.276l2.195 2.195a.667.667 0 01-.942.943L.195 4.47a.667.667 0 010-.942L3.53.195c.26-.26.682-.26.942 0z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export function IconMailReplyAll() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 14 13"
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M7.132.186a.635.635 0 010 .898L5.04 3.174H7.6c1.043 0 1.23 0 1.89.055.675.055 1.238.17 1.75.43a4.444 4.444 0 011.942 1.942c.261.512.376 1.075.43 1.75.055.661.055 1.483.055 2.526v1.583a.635.635 0 11-1.27 0V9.905c0-1.077 0-1.847-.05-2.45-.049-.597-.142-.974-.296-1.277a3.174 3.174 0 00-1.387-1.388c-.303-.154-.681-.247-1.277-.296-.603-.05-.738-.05-1.816-.05h-2.53l2.09 2.091a.635.635 0 11-.897.898L3.059 4.258a.635.635 0 010-.897L6.234.186a.635.635 0 01.898 0z"
          clipRule="evenodd"
        ></path>
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M4.778.183a.491.491 0 010 .77L1.867 3.81l2.911 2.88a.491.491 0 010 .77.712.712 0 01-.897 0L.52 4.194a.491.491 0 010-.77L3.88.184a.712.712 0 01.898 0z"
          clipRule="evenodd"
        ></path>
      </svg>
    );
  }