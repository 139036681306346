import { WalletProvider } from "models/interfaces/walletProvider"
import { WalletProviderDetails } from "models/interfaces/walletProviderDetails"
import bs58 from "bs58"

export class Solflare extends WalletProvider {

    static provider = WalletProviderDetails.solflare

    static isInstalled(): boolean {
        return (window as any).solflare && (window as any).solflare.isSolflare
    }

    getProvider(): any {
        return (window as any).solflare
    }

    static isConnected(): boolean {
        return Solflare.isInstalled() && (window as any).solflare.isConnected
    }

    async connect(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            if (!Solflare.isInstalled())
                return false

            try {
                let conn = await this.getProvider().connect()

                if (conn) {
                    return resolve(true)
                } else {
                    return reject(false)
                }
            }
            catch (err) {
                return  resolve(false)
            }
        })
    }

    async disconnect(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            if (!Solflare.isInstalled())
                return reject(false)

            try {
                await this.getProvider().disconnect()
            }
            catch (err) {
                return reject(false)
            }

            resolve(true)
        })
    }

    setOnDisconnect(callback: () => void): void {
        this.getProvider().on('disconnect', callback)
    }

    async address(): Promise<string[]> {
        return new Promise(async (resolve, reject) => {
            if (!Solflare.isInstalled() || !Solflare.isConnected())
                return ""

            resolve([this.getProvider().publicKey.toString()])
        })
    }

    async signMessage(message: string, nonce : string): Promise<string> {
        return new Promise((resolve, reject) => {
            if (!Solflare.isInstalled() || !Solflare.isConnected()) {
                return reject(false)
            }

            let encoded = new TextEncoder().encode(message + nonce)

            this.getProvider().signMessage(encoded, 'utf8').then((signature: any) => {
                resolve(bs58.encode(signature.signature))
            }).catch(() => {
                reject(false)
            })
        })
    }

    install(): void {
        window.open('https://solflare.com/download', '_blank')
    }

    signTransaction(transaction: any): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!Solflare.isInstalled() || !Solflare.isConnected()) {
                return reject(false)
            }

            try {
                const tx= (window as any).solflare.signTransaction(transaction)
                resolve(tx)
            } catch (e) {
                reject(e)
            }

        })
    }

    signAllTransactions(transactions: any[]): Promise<any[]> {
        return new Promise((resolve, reject) => {
            if (!Solflare.isInstalled() || !Solflare.isConnected()) {
                return reject(false)
            }

            try {
                const signatures = (window as any).solflare.signAllTransactions(transactions)
                resolve(signatures)
            } catch (e) {
                reject(e)
            }

        })
    }
}