import styled from "styled-components"
import { deviceMax } from "styles/media"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"

export const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: ${Hex2Rgba(color.black, 0.8)};
    z-index: 21;
    display:flex;
`

export const Overlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index:1;
`

export const Dialog = styled.div`
    position: relative;
    width:700px;
    max-width:100%;
    max-height:100%;
    z-index:2;
    padding:24px;
    border-radius: 8px;
    background: ${color.white};
    overflow-y: auto;
    display:flex;
    flex-direction:column;
    overflow:auto;
    margin:auto;
    
    @media ${deviceMax.sm}{
        width:100%;
    }
`

export const DialogHeader = styled.div`
    display: flex;
    align-items: center;
    cursor:default;
    user-select:none;
`
export const DialogTitle = styled.div`
    font-size:14px;
    display:flex;
`
export const DialogClose = styled.div`
    position: absolute;
    cursor: pointer;
    font-size: 29px;
    right: 24px;
    top: 24px;
    padding-right:0px;
    color: ${color.black};
`
export const DialogBody = styled.div`
    flex:1;
    display:flex;
    flex-direction:column;
    transition:none;
`