import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import * as C from "./style"
import { getLocalData } from "utils/localData"
import { useSelector } from "react-redux"
import { sendMail } from "services/api/mail"
import nacl from 'tweetnacl'
import bs58 from 'bs58'
import { unlockCloudWallet } from "modules/mail/helpers"
import { getCryptoPair } from "modules/mail/asymetric"
import { toast } from "react-hot-toast"
import { Button } from "components/ui/button"
import { IconAlignCenter, IconAlignJustify, IconAlignLeft, IconAlignRight, IconBold, IconItalic, IconLink } from "utils/icons/wysiwyg"
import { Editor, createEditor } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';
import { editorSerializeToHtml, getActiveStylesEditor, isBlockActiveEditor, renderElement, renderLeaf, toggleBlockEditor, toggleStyleEditor } from "components/other/editor"
import { withHistory } from "slate-history"
import { Input } from "components/ui/input"
import { useNavigate } from "react-router-dom"
import { IconAttach } from "utils/icons/mail"
import { deleteFile, uploadFile } from "services/api/drive"
import axios from "axios"
import { faFile, faArrowDown, faExclamation, faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconTimes } from "utils/icons/common"
import { parseFileSize } from "utils/helpers"

const mailTypes = [
    { value: "mail", label: "Secure Mail" },
    { value: "tpp", label: "XMail" },
]

const Compose = () => {

    //const [body, setBody] = useState("")
    const [value, setValue] = useState([
        {
            type: 'paragraph',
            children: [{ text: '' }],
        },
    ]);
    const [subject, setSubject] = useState("")
    const [to, setTo] = useState("")
    const [recipients, setRecipients] = useState<any[]>([])
    const [tpp, setTpp] = useState(false)
    const emailAddressRef = useRef<any>(null)

    const [attachments, setAttachments] = useState<any>([])
    const [attachmentFiles, setAttachmentFiles] = useState<any>([])

    const currentAccount = useSelector((state: any) => state.user.currentAccount)
    const serverPublicKey = useSelector((state: any) => state.mail.serverPublicKey)
    const user = useSelector((state: any) => state.user.user)

    const navigate = useNavigate()
    const page = useSelector((state: any) => state.mail.page)

    const [sendingMail, setSendingMail] = useState(false)

    const onSend = () => {

        if (sendingMail)
            return;

        setSendingMail(true);

        (new Promise((resolve, reject) => {
            let body = editorSerializeToHtml(value)

            if (recipients.length === 0)
                return toast.error("Please enter a recipient")

            if (recipients.filter((r) => r.valid === false).length > 0)
                return toast.error("Please enter a valid recipient")

            if (subject === "")
                return toast.error("Please enter a subject")

            if (body === "")
                return toast.error("Please enter a body")

            if (body === '<p><br></p>' || body === '<p></p>')
                return toast.error("Please enter a body")

            if (attachments.filter((a: any) => a.status === "in-queue").length > 0)
                return toast.error("Please wait for attachments to upload")

            let key: any = new TextDecoder().decode(bs58.decode(getLocalData("key")))

            let cloudWallet = unlockCloudWallet(user.cloudWallet, key.toString())
            let cryptoPair = getCryptoPair(cloudWallet)

            key = nacl.box(new TextEncoder().encode(key), Buffer.from(user.cloudWallet.salt, 'hex'), bs58.decode(serverPublicKey), cryptoPair.secretKey)

            sendMail(currentAccount, recipients.map((r) => r.address), subject, body, bs58.encode(key), tpp).then((res) => {
                toast.success("Mail sent successfully")
                navigate("/mail/" + page)
                resolve(true)
            }).catch((err) => {
                console.log(err)
                toast.error("Something went wrong, please try again")
                reject()
            })

        })).finally(() => setSendingMail(false))

    }

    const ontppChange = () => {
        setTpp(!tpp)
    }

    useEffect(() => {

        if (to.trim() === "")
            return;

        let text = to.trim()
        let rest = ""

        if (text.includes(" ")) {
            let items = text.split(" ")
            if (items[1] === '')
                return;

            if (items[1][0] === " ") {
                if (items[1].length === 1)
                    return;
            }

            text = items[0]
            rest = items[1]

            let expression = /.+@.+/g
            let regex = new RegExp(expression)

            if (!text.match(regex))
                return;

            let newRecipients = [...recipients]
            newRecipients.push({ address: text, valid: true })

            setRecipients(newRecipients)

            emailAddressRef.current.value = rest
            setTo(rest)
        }

    }, [to])

    const onEmailFocusOut = () => {
        if (to.trim() === "")
            return;

        let text = to.trim()

        let expression = /.+@.+/g
        let regex = new RegExp(expression)

        let isValid = text.match(regex)

        let newRecipients = [...recipients]
        newRecipients.push({ address: text, valid: isValid })

        setRecipients(newRecipients)
        setTo("")
        emailAddressRef.current.value = ""
    }

    const removeRecipient = (index: number) => {
        let newRecipients = [...recipients]
        newRecipients.splice(index, 1)
        setRecipients(newRecipients)
    }

    //editor
    const editor = useMemo(() => withHistory(withReact(createEditor())), []);

    const onChangeEditor = useCallback(
        (doc: any) => {
            setValue(doc);
        },
        [editor]
    );

    const onMailTypeChange = (e: any) => {
        if (e.value === "tpp")
            setTpp(true)
        else
            setTpp(false)
    }



    const onAttach = (e: any) => {
        var file = e.target.files[0]

        if (!file) return;

        if (file.size > 10000000) {
            toast.error("File size is too large! Max 10MB")
            return;
        }


        let _attachments = [...attachments]
        let key = (Math.random() * 1000000).toFixed()
        _attachments.push({
            key,
            name: file.name,
            size: parseFileSize(file.size),
            status: "in-queue",
            cancelToken: axios.CancelToken.source(),
        })
        setAttachments(_attachments)
        let _attachmentFiles = [...attachmentFiles, { key, file }]
        setAttachmentFiles(_attachmentFiles)
    }

    useEffect(() => {
        if (attachmentFiles.length === 0)
            return;

        let _attachments = [...attachments]
        let index = _attachments.findIndex((a) => a.status === "in-queue")
        if (index === -1)
            return;

        let uploadingCheck = _attachments.filter((a) => a.status === "uploading")
        if (uploadingCheck.length > 0)
            return;

        //upload next attachment
        //uploadAttachment(_attachments, attachmentFiles[index].file)

    }, [attachmentFiles])

    const uploadAttachment = (attachment: any, file: any) => {
        var formData = new FormData()
        formData.append("file", file)

        let _attachments = [...attachments]
        let index = _attachments.findIndex((a) => a.key === attachment.key)
        _attachments[index].status = "uploading"
        setAttachments(_attachments)

        uploadFile(formData, attachment.cancelToken.token).then((res) => {
            let _attachments = [...attachments]
            let index = _attachments.findIndex((a) => a.key === attachment.key)
            _attachments[index].status = "uploaded"
            _attachments[index].id = res.data.id
            setAttachments(_attachments)
        }).catch((err) => {
            console.log(err)
            let _attachments = [...attachments]
            let index = _attachments.findIndex((a) => a.key === attachment.key)
            _attachments[index].status = "error"
            setAttachments(_attachments)
        })
    }

    const cancelAttachment = (key: string) => {

        let _attachments = [...attachments]
        let index = _attachments.findIndex((a) => a.key === key)

        if (index === -1)
            return;

        let attachment = _attachments[index]

        if (attachment.status === "in-queue" || attachment.status === "error") {
            _attachments.splice(index, 1)
            setAttachments(_attachments)
            return;
        }

        if (attachment.status === "uploading") {
            _attachments[index].cancelToken.cancel()
            _attachments.splice(index, 1)
            setAttachments(_attachments)
            return;
        }

        if (attachment.status === "uploaded") {
            deleteFile(attachment.id) //delete from server
            _attachments.splice(index, 1)
            setAttachments(_attachments)
            return;
        }
    }

    return (
        <C.Compose>
            <C.Header>
                <C.Title>Compose</C.Title>
                <C.Close onClick={() => navigate("/mail/" + page)} >&times;</C.Close>
            </C.Header>
            <C.Body>
                <C.Recipients>
                    <C.InputLabel>Send to</C.InputLabel>
                    <C.RecipientsArea empty={recipients.length === 0 ? "true" : "false"}>
                        {recipients.map((recipient, index) => (
                            <C.RecipientLabel isvalid={recipient.valid ? "true" : "false"} key={index}>{recipient.address} <span onClick={() => removeRecipient(index)}>&times;</span></C.RecipientLabel>
                        ))}
                        <C.RecipientsInput placeholder={recipients.length === 0 ? "people@mailx" : ""} onBlur={onEmailFocusOut} ref={emailAddressRef} autoFocus type="text" onChange={(e) => setTo(e.target.value)} />
                    </C.RecipientsArea>
                </C.Recipients>
                <C.Subject>
                    <C.InputLabel>Subject</C.InputLabel>
                    <C.SubjectInput placeholder="mail title" type="text" onChange={(e) => setSubject(e.target.value)} />
                </C.Subject>
                <C.Text>
                    <Slate editor={editor} initialValue={value} onChange={onChangeEditor}>
                        <Editable
                            renderLeaf={renderLeaf}
                            renderElement={renderElement}
                        />
                    </Slate>
                </C.Text>

                {attachments.length > 0 && (
                    <C.AttachmentsBox>
                        <C.AttachmentsHeader>
                            <C.AttachmentsTitle>Attachments <span>3</span></C.AttachmentsTitle>
                            <C.AttachmentsAction>Delete All <IconTimes /></C.AttachmentsAction>
                        </C.AttachmentsHeader>
                        <C.Attachments>
                            {attachments.map((attachment: any, index: number) => (
                                <C.Attachment key={index}>
                                    <C.AttachmentTop>
                                        <C.AttachmentLeft>
                                            <C.AttachmentIcon>
                                                <FontAwesomeIcon icon={faFile} />
                                            </C.AttachmentIcon>
                                            <C.AttachmentDetails>
                                                {attachment.status !== "error" && (
                                                    <C.AttachmentName>
                                                        {attachment.name}
                                                    </C.AttachmentName>
                                                )}
                                                {attachment.status === "error" && (
                                                    <C.AttachmentErrorTitle>
                                                        <FontAwesomeIcon icon={faExclamationCircle} /> {attachment.name}
                                                    </C.AttachmentErrorTitle>
                                                )}
                                                <C.AttachmentSize>
                                                    {attachment.size}
                                                </C.AttachmentSize>
                                            </C.AttachmentDetails>
                                        </C.AttachmentLeft>
                                        <C.AttachmentCancel onClick={() => cancelAttachment(attachment.key)}>
                                            <IconTimes />
                                        </C.AttachmentCancel>
                                    </C.AttachmentTop>

                                    {(attachment.status === "in-queue" || attachment.status === "uploading") && (
                                        <C.AttachmentProgress>
                                            <C.AttachmentProgressValue />
                                        </C.AttachmentProgress>
                                    )}

                                    {attachment.status === "error" && (
                                        <C.AttachmentError></C.AttachmentError>
                                    )}
                                </C.Attachment>
                            ))}
                        </C.Attachments>
                    </C.AttachmentsBox>
                )}

                <C.Footer>
                    <C.WysiwygActions>

                        {/* <select
                            defaultValue="16px"
                        >
                            <option value="12px">12px</option>
                            <option value="14px">14px</option>
                            <option value="16px">16px</option>
                            <option value="18px">18px</option>
                            <option value="20px">20px</option>
                        </select>

                        <C.WysiwygActionSeperator />*/}

                        <C.WysiwygAction wtype="text" isactive={getActiveStylesEditor(editor).has("bold") ? "true" : "false"} onMouseDown={(e) => { e.preventDefault(); toggleStyleEditor(editor, "bold") }}>
                            <IconBold />
                        </C.WysiwygAction>
                        <C.WysiwygAction wtype="text" isactive={getActiveStylesEditor(editor).has("italic") ? "true" : "false"} onMouseDown={(e) => { e.preventDefault(); toggleStyleEditor(editor, "italic") }}>
                            <IconItalic />
                        </C.WysiwygAction>

                        <C.WysiwygActionSeperator />

                        <C.WysiwygAction wtype="align" isactive={isBlockActiveEditor(editor, "left", "align") ? "true" : "false"} onMouseDown={(e) => { e.preventDefault(); toggleBlockEditor(editor, "left") }}>
                            <IconAlignLeft />
                        </C.WysiwygAction>
                        <C.WysiwygAction wtype="align" isactive={isBlockActiveEditor(editor, "right", "align") ? "true" : "false"} onMouseDown={(e) => { e.preventDefault(); toggleBlockEditor(editor, "right") }}>
                            <IconAlignRight />
                        </C.WysiwygAction>
                        <C.WysiwygAction wtype="align" isactive={isBlockActiveEditor(editor, "center", "align") ? "true" : "false"} onMouseDown={(e) => { e.preventDefault(); toggleBlockEditor(editor, "center") }}>
                            <IconAlignCenter />
                        </C.WysiwygAction>
                        <C.WysiwygAction wtype="align" isactive={isBlockActiveEditor(editor, "justify", "align") ? "true" : "false"} onMouseDown={(e) => { e.preventDefault(); toggleBlockEditor(editor, "justify") }}>
                            <IconAlignJustify />
                        </C.WysiwygAction>

                        <C.WysiwygActionSeperator />

                        <C.WysiwygAction wtype="attach&link">
                            <IconLink />
                        </C.WysiwygAction>

                        <C.WysiwygActionSeperator />

                        <C.WysiwygActionLabel wtype="attach&link">
                            <C.AttachmentInput type="file" onChange={onAttach} />
                            <IconAttach />
                        </C.WysiwygActionLabel>

                    </C.WysiwygActions>

                    <C.MailActions>

                        <Input options={mailTypes} onChange={onMailTypeChange} isSearchable={false} menuPlacement="top" defaultValue={mailTypes[0]} type="select" />
                        <C.MailActionsSeperator />
                        <Button disabled={sendingMail} variant="blue" size="small" onClick={onSend}>Send Mail</Button>
                    </C.MailActions>
                </C.Footer>
            </C.Body>
        </C.Compose>
    )

}

export default Compose