import styled from 'styled-components'
import { color } from 'styles/theme'

export const Box = styled.div`
    width: 468px;
    border-right:2px solid ${color.gray100};
    height: calc(100vh - 88px);
    overflow-y: hidden;
    position: relative;
    
`
export const BoxHeader = styled.div`
    padding: 24px;
    display: flex;
    justify-content: space-between;
    background-color: ${color.white};
`

export const BoxHeaderLeft = styled.div`
    display: flex;
    align-items: center;
`

export const MailboxTypes = styled.div`
    display: flex;
    background-color: ${color.gray100};
    padding:8px;
    border-radius: 8px;
    height: 48px;
`

export const MailboxType = styled.div<{ active?: string }>`
    padding: 0 12px;
    align-items: center;
    display: flex;
    background-color: ${props => props.active === 'true' ? color.white : color.gray100};
    border-radius: 8px;
    font-size: 14px;
    &:not(:last-child) {
        margin-right: 8px;
    }
    font-weight: bold;
    cursor: pointer;
    color: ${props => props.active === 'true' ? color.black : color.gray500};
    transition: all .1s ease-in-out;
    &:hover {
        color: ${color.black};
    }
`

export const Refresh = styled.div`
    display: flex;
    background-color: ${color.gray100};
    border-radius: 8px;
    align-items: center;
    width: 48px;
    justify-content: center;

    & svg{
        height: 17px;
    }
`


export const SelectedMails = styled.div`
    padding: 0 24px;
    height: 96px;
    display: flex;
    justify-content: space-between;
    background-color: ${color.white};
    align-items: center;
`

export const SelectedMailsLeft = styled.div`
    display: flex;
    align-items: center;
`

export const SelectedMailsCount = styled.div`
    background-color: ${color.gray100};
    padding: 0 16px;
    border-radius: 8px;
    & span {
        color: ${color.blue500};
        margin-right: 4px;
    }
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
`

export const SelectedMailsActions = styled.div`
    display: flex;
    align-items: center;
`

export const SelectedMailsAction = styled.div<{ atype?:string, isdisabled?: string }>`
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    background-color: ${color.gray100};
    border-radius: 8px;
    justify-content: center;
    
    
    cursor: pointer;
    color: ${color.black};

    &:not(:last-child) {
        margin-right: 16px;
    }

    ${props => props.isdisabled === "true" && `
        cursor: not-allowed;
        opacity: .5;
    `}

    ${props => props.atype === "mark-read" && `
        & svg{
            height:20px;
        }
    `}
    ${props => props.atype === "delete" && `
        & svg{
            height:22px;
        }
    `}
    ${props => props.atype === "cancel" && `
        & svg{
            height:14px;
        }
    `}

`

export const Mails = styled.div`
    height: calc(100vh - 184px);
`

export const MailSenderPP = styled.div`
    width: 48px;
    height: 48px;
    margin-right: 16px;
    & img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
`

export const MailCheckbox = styled.div<{ state?: string }>`
    width: 48px;
    height: 48px;
    margin-right: 16px;
    display: none;
    align-items: center;
    justify-content: center;
    & >div{
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius:4px;
    }
    
    & svg {
        width: 16px;
    }
   
    ${props => props.state === 'checked' && `
        & >div{
            background-color: ${color.black};
            border: 1px solid ${color.black};
            color: ${color.white};
        }
        & div>div:nth-child(2) {
            display: none;
        }
        & svg {
            width: 16px;
        }
    `}
    ${props => props.state === 'unchecked' && `
        & >div{
            border: 1px solid ${color.black};
            & svg {
                display: none;
            }
        }
    `}
    &:hover {
        ${props => props.state === 'checked' && `
            & div>div:nth-child(1) {
                display: none;
            }
            & div>div:nth-child(2) {
                display: block;
            }
            
            & svg {
                width: 12px;
            }
        `}
        ${props => props.state === 'unchecked' && `
            & div>div:nth-child(1) {
                display: none;
            }
            & div>div:nth-child(2) {
                display: block;
            }
            
            & svg {
                width: 16px;
            }
        `}
    }
    position: relative;
    z-index: 2;
`

export const MailContent = styled.div`
    flex:1;
`

export const MailSender = styled.div`
    font-weight: 600;
    line-height: 100%;
    margin-bottom: 8px;
    color: ${color.gray600};
`

export const MailSubject = styled.div`
    color: ${color.gray600};
    line-height: 100%;
    font-weight: 600;
    display: flex;
    align-items: center;
    & svg{
        height:16px;
        margin-right: 4px;
    }
`

export const MailDate = styled.div`
    color: ${color.blue500};
    font-size: 12px;
    font-weight: 600;
    line-height: 100%;
`

export const Mail = styled.div<{ read: string, focus: string, isselected: string }>`
    display: flex;
    position: relative;
    padding:24px;
    background-color: ${color.gray50};
    ${props => props.read === 'false' && `
        background-color: ${color.white};
        & ${MailSender} {
            color: ${color.black};
        }
        & ${MailSubject} {
            color: ${color.blue500};
        }
    `}
    cursor: pointer;
    border-bottom: 1px solid ${props => props.read === 'false' ? color.gray100 : color.gray200};

    ${props => props.focus === 'true' && `
        background-color: ${color.blue100};
        & ${MailSender} {
            color: ${color.black};
        }
        & ${MailSubject} {
            color: ${color.black};
        }
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 4px;
            background-color: ${color.blue500};
        }
    `}

    ${props => props.isselected === 'true' && `
        & ${MailSenderPP} {
            display: none;
        }
        & ${MailCheckbox} {
            display: flex;
        }
    `}

    &:hover {
        background-color: ${color.gray100};

        & ${MailSenderPP} {
            display: none;
        }
        & ${MailCheckbox} {
            display: flex;
        }
    }
`

export const MailOverlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    top:0;
    left:0;
`

export const SelectAll = styled.div`
    margin-right: 16px;
`

export const SelectAllCheckbox = styled.div<{ state?: string }>`
    border-radius: 4px;
    border: 1px solid ${color.black};
    width: 16px;
    height: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    ${props => props.state === 'true' && `
        background-color: ${color.black};
        color: ${color.white};
        & svg {
            width:9px;
        }
    `}

`