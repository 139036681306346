import { useEffect, useRef, useState } from "react"
import * as C from "./style"
import { useSelector } from "react-redux"
import { faChevronDown, faChevronUp, faUser } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNavigate } from "react-router-dom"
import { IconUser } from "utils/icons/common"
import { IconLogout } from "utils/icons"
import { generateProfilePicture } from "utils/helpers"

const AccountDropdown = () => {

    const currentAccount = useSelector((state: any) => state.user.currentAccount)
    const user = useSelector((state: any) => state.user.user)

    const [open, setOpen] = useState(false)

    const navigate = useNavigate()


    const dropdownRef = useRef<any>(null);

    const handleClickOutside = (event: any) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    const onItemClick = (e: any, destination: string) => {
        e.preventDefault()
        setOpen(false)

        navigate(destination)
    }

    return (
        <C.Account ref={dropdownRef}>
            <C.VisibleSide onClick={() => setOpen(!open)}>
                <C.AccountLeft>
                    <C.AccountAvatar>
                    <img src={generateProfilePicture(user.accounts.find((item: any) => item.address === currentAccount).name)} alt="avatar" />
                    </C.AccountAvatar>
                    <C.AccountTexts>
                        <C.AccountName>
                            {
                                user.accounts.find((item: any) => item.address === currentAccount).name
                            }
                        </C.AccountName>
                        <C.AccountEmail>
                            {currentAccount}
                        </C.AccountEmail>
                    </C.AccountTexts>
                </C.AccountLeft>
                <C.AccountRight>
                    {!open && (<FontAwesomeIcon icon={faChevronDown} />)}
                    {open && (<FontAwesomeIcon icon={faChevronUp} />)}
                </C.AccountRight>
            </C.VisibleSide>

            {open && (
                <C.Dropdown>
                    <C.DropdownItem to="/dashboard" onClick={(e) => onItemClick(e, "/dashboard")}> <IconUser/> Dashboard</C.DropdownItem>
                    {/*<C.DropdownItem to="/dashboard" onClick={(e) => onItemClick(e, "/dashboard")}> <FontAwesomeIcon icon={faUser} /> My Plan</C.DropdownItem>
                    <C.DropdownItem to="/dashboard" onClick={(e) => onItemClick(e, "/dashboard")}> <FontAwesomeIcon icon={faUser} /> Settings</C.DropdownItem>*/}
                    <C.DropdownItem to="/logout" onClick={(e) => onItemClick(e, "/logout")}> <IconLogout /> Logout</C.DropdownItem>
                </C.Dropdown>
            )}
        </C.Account>
    )

}

export default AccountDropdown