import { getEmitterAddressEth, getForeignAssetSolana, hexToUint8Array, parseSequenceFromLogEth, transferFromEth, tryNativeToHexString } from "@certusone/wormhole-sdk"
import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import { getNetworkWormholeDetails } from "utils/helpers"
import { getAssociatedTokenAccount } from "modules/solana/spltoken";
import { PublicKey } from "@solana/web3.js";
import { SOLANA_CONNECTION } from "modules/solana/connection";
import { parseUnits, zeroPad } from "ethers/lib/utils";


export const depositUsdcCrossChain = async (mxbankAddress:string, amount:string, sourceChain:'bsc'|'avalanche', wallet:any) => {


    const provider = new ethers.providers.Web3Provider(wallet.getProvider())

    let signer = provider.getSigner()

    let sourceChainDetails = getNetworkWormholeDetails(sourceChain)
    let targetChainDetails = getNetworkWormholeDetails('solana')

    const solanaMintKey = new PublicKey(
        (await getForeignAssetSolana(
            SOLANA_CONNECTION,
            new PublicKey(targetChainDetails.tokenBridge),
            sourceChain,
            hexToUint8Array(tryNativeToHexString(sourceChainDetails.tokens.USDC, sourceChain) || ""),
        )) || ""
    )

    const recipientAddress = await getAssociatedTokenAccount(new PublicKey(mxbankAddress), solanaMintKey)

    const tx = await transferFromEth(
        sourceChainDetails.tokenBridge,
        signer,
        sourceChainDetails.tokens.USDC,
        parseUnits(amount, sourceChain === 'bsc' ? 18 : 6),
        'solana',
        zeroPad(recipientAddress.toBytes(), 32),
    )

    console.log(tx)
    
    const sequence = parseSequenceFromLogEth(tx, sourceChainDetails.bridge)

    return sequence
}