import { useEffect, useRef, useState } from "react"
import * as P from "./style"
import { Container } from "styles"
import Logo from "components/ui/logo"
import { Button } from "components/ui/button"
import { showModal } from "utils/modal"
import { useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import { preloadImagesHome } from "utils/helpers"
import HeaderHome from "components/parts/other/headerHome"
import { Input, InputGroup } from "components/ui/input"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IsDomainExists } from "modules/domain/solana"
import { toast } from "react-hot-toast"
import Loading from "components/parts/other/loading"

const DomainPage = () => {

    const user = useSelector((state: any) => state.user.user)
    const navigate = useNavigate()

    const params = useSearchParams()
    const [domain, setDomain] = useState('')
    const [domainStatus, setDomainStatus] = useState<any>({})

    const landingSearchRef = useRef<any>(null)

    const [refresh, setRefresh] = useState(false)

    const onChangeLandingSearch = (e: any) => {

        let q = e.target.value

        //remove spaces, special chars
        q = q.replace(/[^a-zA-Z0-9]/g, '')

        landingSearchRef.current.value = q

    }

    const onSearchLandingFormSubmit = (e: any) => {
        e.preventDefault()

        let q = landingSearchRef.current.value

        //remove spaces, special chars
        q = q.replace(/[^a-zA-Z0-9]/g, '')

        if (q) {
            navigate(`/domains?q=${q}`)
            setLoading(true)
            setRefresh(!refresh)
        }
    }

    const [loading, setLoading] = useState(true)

    useEffect(() => {

        let q = params[0].get('q')

        if (q !== null)
            q = q.replace(/[^a-zA-Z0-9]/g, '')

        if (q) {
            setDomain(q)
            checkDomain(q)
        } else {
            setDomain('')
            setDomainStatus({})
            setLoading(false)
        }



    }, [refresh])

    const checkDomain = (q: string) => {
        IsDomainExists(q).then((res: any) => {
            setDomainStatus({
                available: !res
            })
            setLoading(false)
        }).catch((err: any) => {
            toast.error("Error checking domain")
        })
    }

    const searchRef = useRef<any>(null)

    const onChangeSearch = (e: any) => {

        let q = e.target.value

        //remove spaces, special chars
        q = q.replace(/[^a-zA-Z0-9]/g, '')

        searchRef.current.value = q
    }

    const onSearchFormSubmit = (e: any) => {
        e.preventDefault()

        let q = searchRef.current.value

        //remove spaces, special chars
        q = q.replace(/[^a-zA-Z0-9]/g, '')

        if (q) {
            navigate(`/domains?q=${q}`)
            setLoading(true)
            setRefresh(!refresh)
        }
    }

    const buyDomain = () => {
        showModal("DomainPaymentOptions", {
            domain: domain
        }, {
            refresh: () => {
                setRefresh(!refresh)
            }
        })
    }

    if (!domain) {

        return (
            <P.DomainPage>
                <HeaderHome />

                <P.DomainHeroContainer>
                    <P.DomainHero>
                        <P.Promo>Starts from $10</P.Promo>
                        <P.Title>Make it easier for people to reach you</P.Title>
                        <P.Title>by getting a MailX domain</P.Title>

                        <P.HeroTexts>
                            <P.HeroText>
                                <P.HeroTextIcon></P.HeroTextIcon>
                                <P.HeroTextContent>It's yours for life, no transfer fees or renewals</P.HeroTextContent>
                            </P.HeroText>
                            <P.HeroText>
                                <P.HeroTextIcon></P.HeroTextIcon>
                                <P.HeroTextContent>Elevate your brand to a more professional position</P.HeroTextContent>
                            </P.HeroText>
                            <P.HeroText>
                                <P.HeroTextIcon></P.HeroTextIcon>
                                <P.HeroTextContent>Create your own private mail addresses with your domain</P.HeroTextContent>
                            </P.HeroText>
                            <P.HeroText>
                                <P.HeroTextIcon></P.HeroTextIcon>
                                <P.HeroTextContent>Store, sell or transfer domains</P.HeroTextContent>
                            </P.HeroText>

                            <P.HeroDomainSearch onSubmit={onSearchLandingFormSubmit}>
                                <InputGroup settings={{
                                    icon: <FontAwesomeIcon icon={faSearch} />
                                }}>
                                    <Input xref={landingSearchRef} type="input" placeholder="Search for a domain" onChange={onChangeLandingSearch} />
                                    <Button type="submit" variant="primary" size="small">Search</Button>
                                </InputGroup>
                            </P.HeroDomainSearch>
                        </P.HeroTexts>
                    </P.DomainHero>
                </P.DomainHeroContainer>
            </P.DomainPage>
        )

    } else {

        return (
            <P.DomainSearhPage>
                <HeaderHome />

                <Container>
                    <P.DomainSearch>
                        <P.DomainSearchInput onSubmit={onSearchFormSubmit}>
                            <InputGroup settings={{
                                icon: <FontAwesomeIcon icon={faSearch} />
                            }}>
                                <Input xref={searchRef} type="input" placeholder="Search for a domain" defaultValue={domain} onChange={onChangeSearch} />
                                <Button type="submit" variant="primary" size="small">Search</Button>
                            </InputGroup>
                        </P.DomainSearchInput>

                        {!loading && (
                            <P.DomainSearchResult>
                                <P.DomainResultLeft>
                                    <P.DomainResultIcon isavailable={domainStatus.available ? 'true' : 'false'}>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </P.DomainResultIcon>
                                    <P.DomainResultContent>
                                        <P.DomainResultDomain>
                                            {domain}
                                        </P.DomainResultDomain>
                                        <P.DomainResultText>
                                            {domainStatus.available ? 'available for purchase' : 'not available'}
                                        </P.DomainResultText>
                                    </P.DomainResultContent>
                                </P.DomainResultLeft>
                                <P.DomainResultRight>
                                    <P.DomainResultPricePart>
                                        <P.DomainResultPrice>
                                            $10
                                        </P.DomainResultPrice>
                                        <P.DomainResultPriceText>
                                            /for life
                                        </P.DomainResultPriceText>
                                    </P.DomainResultPricePart>
                                    <Button disabled={!domainStatus.available} variant="blue" size="large" onClick={buyDomain}>Buy This Domain</Button>
                                </P.DomainResultRight>
                            </P.DomainSearchResult>
                        )}
                    </P.DomainSearch>

                    {loading && (
                            <P.Loading>
                                <Loading />
                            </P.Loading>
                        )}
                </Container>
            </P.DomainSearhPage>
        )

    }

}

export default DomainPage