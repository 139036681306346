import React, { useMemo } from 'react'
import * as C from './style'
import Logo from 'components/ui/logo'
import { useYupValidationResolver } from 'hooks/useYupValidationResolver'
import { useForm, Controller } from "react-hook-form"
import * as yup from "yup"
import { InputGroup } from 'components/ui/input'
import { Input } from 'components/ui/input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { Button } from 'components/ui/button'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

const Password = (props: any) => {

    const schema = useMemo(
        () =>
            yup.object({
                password: yup.string().required(),
            }),
        []
    )

    const resolver = useYupValidationResolver(schema)
    const { handleSubmit, control, formState: { errors } } = useForm({ resolver })

    const onSubmit = (data: any) => {
        props.onPasswordFinish(data.password)
    }

    return (
        <C.Password>

            <C.BackButton onClick={props.back}>
                <FontAwesomeIcon icon={faArrowLeft} />
            </C.BackButton>

            <C.Title>
                Login to your account
            </C.Title>
            <C.Description>
                Enter your MailX password to login to <span>{props.mailxAddress}</span> account.
            </C.Description>

            <C.Form onSubmit={handleSubmit((data) => onSubmit(data))}>

                <InputGroup settings={{
                    icon: <FontAwesomeIcon icon={faEnvelope} />,
                    label: "Password",
                    hint: errors.password?.message as string,
                    state: errors.password ? "error" : "default"
                }}>
                    <Controller name="password" control={control} render={({ field }) =>
                        <Input onBlur={field.onBlur} onChange={field.onChange} checked={field.value} xref={field.ref} type='password' placeholder='your password' autoFocus />}
                    />
                </InputGroup>

                <Button type="submit" variant="primary" size="large" loading={props.loading} disabled={props.loading} block>Login</Button>

            </C.Form>

        </C.Password>
    )

}

export default Password