import { Network } from "../enums/network";

export interface WalletProviderDetail{
    name: string
    network: Network
}

export const WalletProviderDetails : any = {
    phantom:{
        name: 'phantom',
        network: Network.Solana
    } as WalletProviderDetail,
    solflare : {
        name: 'solflare',
        network: Network.Solana
    } as WalletProviderDetail,
    backpack : {
        name: 'backpack',
        network: Network.Solana
    } as WalletProviderDetail,
    metamask : {
        name: 'Metamask',
        network: Network.Evm
    } as WalletProviderDetail,
}