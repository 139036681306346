import { useEffect, useRef, useState } from "react"
import * as C from "./style"
import Logo from "components/ui/logo"
import { color } from "styles/theme"
import { Button } from "components/ui/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faEnvelope, faPaperPlane } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { IconPlus } from "utils/icons/common"
import { IconEnvelope, IconPaperplane } from "utils/icons/mail"

const Sidebar = () => {

    const isNavActive = (...path: any) => {

        let location = window.location.pathname

        // if one of the paths is active, return active
        for (let i = 0; i < path.length; i++) {
            //remove last slash
            if (path[i][path[i].length - 1] === "/")
                path[i] = path[i].slice(0, -1)

            if (location[location.length - 1] === "/")
                location = location.slice(0, -1)

            if (location === path[i])
                return "true"

            //if path contains * then it is a wildcard
            if (path[i].includes("*")) {
                path[i] = path[i].replace("*", "")
                if (location.includes(path[i]))
                    return "true"
            }
        }

        return "false"
    }

    const navigate = useNavigate()
    const page = useSelector((state: any) => state.mail.page)

    const onNavClick = (e: any, to: string) => {

        if (e.button === 0)
            navigate(to)
        if (e.button === 1)
            window.open(to, "_blank")
    }

    return (
        <C.Sidebar>
            <C.TopLogo>
                <Logo color={color.white} />
            </C.TopLogo>

            <C.NewMailButton>
                <Button variant="blue" size="large" block onClick={(e) => onNavClick(e, "/mail/" + (page === "inbox" ? "inbox/compose" : "sent/compose"))}>
                    New Mail <IconPlus/>
                </Button>
            </C.NewMailButton>

            <C.Menu>
                <C.MenuItem onMouseDown={(e) => onNavClick(e, "/mail/inbox")} active={isNavActive("/mail", "/mail/inbox", "/mail/inbox/*")}>
                    <C.MenuIcon>
                        <IconEnvelope/>
                    </C.MenuIcon>
                    <C.MenuText>
                        Inbox
                    </C.MenuText>
                </C.MenuItem>
                <C.MenuItem onMouseDown={(e) => onNavClick(e, "/mail/sent")} active={isNavActive("/mail/sent", "/mail/sent/*")}>
                    <C.MenuIcon>
                        <IconPaperplane/>
                    </C.MenuIcon>
                    <C.MenuText>
                        Sent
                    </C.MenuText>
                </C.MenuItem>
            </C.Menu>


        </C.Sidebar>
    )
}

export default Sidebar