import { WalletProvider } from "models/interfaces/walletProvider"
import { WalletProviderDetails } from "models/interfaces/walletProviderDetails"

export class Metamask extends WalletProvider {

    constructor() {
        super()
    }

    static provider = WalletProviderDetails.metamask

    static isInstalled(): boolean {
        return (window as any).ethereum !== undefined
    }

    getProvider(): any {
        return (window as any).ethereum
    }

    static isConnected(): boolean {
        return Metamask.isInstalled() && (window as any).ethereum.isConnected()
    }

    async connect(network: any = null): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            if (!Metamask.isInstalled())
                return reject("metamask is not installed");
    
            try {
                await this.getProvider().request({ method: "eth_requestAccounts" });
    
                if (network !== null) {
    
                    try {
                        // Attempt to switch to the desired network
                        await this.getProvider().request({
                            method: "wallet_switchEthereumChain",
                            params: [{ chainId: network.chainId }]
                        });
                    } catch (switchError) {
                        // If switching failed, try to add the network
                        try {
                            await this.getProvider().request({
                                method: 'wallet_addEthereumChain',
                                params: [{
                                    ...network,
                                }]
                            });
    
                            // After adding, attempt to switch again
                            await this.getProvider().request({
                                method: "wallet_switchEthereumChain",
                                params: [{ chainId: network.chainId }]
                            });
                        } catch (addError) {
                            return reject("failed to add or switch to the desired network");
                        }
                    }
                }
            } catch (err) {
                return reject("failed to connect");
            }
    
            resolve(true);
        });
    }
    


    async disconnect(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            if (!Metamask.isInstalled())
                return reject(false)

            resolve(true)
        })
    }

    setOnDisconnect(callback: () => void): void {
        this.getProvider().on('disconnect', callback)
    }

    async address(): Promise<string[]> {
        return new Promise(async (resolve, reject) => {
            if (!Metamask.isInstalled() || !Metamask.isConnected())
                return reject(false)

            resolve([this.getProvider().selectedAddress])
        })
    }

    async signMessage(message: string, nonce: string): Promise<string> {
        return new Promise((resolve, reject) => {
            if (!Metamask.isInstalled() || !Metamask.isConnected()) {
                return reject(false)
            }

            let encoded = '0x' + Buffer.from(message + nonce, "utf8").toString("hex")

            this.getProvider().request({
                method: 'personal_sign',
                params: [encoded, this.getProvider().selectedAddress],
            }).then((result: any) => {
                resolve(result)
            }).catch((err: any) => {
                reject(err)
            })
        })
    }

    install(): void {
        window.open('https://metamask.io/download.html', '_blank')
    }

    signTransaction(transaction: any): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!Metamask.isInstalled() || !Metamask.isConnected()) {
                return reject(false)
            }

            try {
                const tx = (window as any).ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [transaction],
                })
                resolve(tx)
            } catch (e) {
                reject(e)
            }

        })
    }

    signAllTransactions(transactions: any[]): Promise<any[]> {
        return new Promise((resolve, reject) => {
            if (!Metamask.isInstalled() || !Metamask.isConnected()) {
                return reject(false)
            }

            try {
                const signatures = transactions.map((transaction) => {
                    return (window as any).ethereum.request({
                        method: 'eth_sendTransaction',
                        params: [transaction],
                    })
                })
                resolve(signatures)
            } catch (e) {
                reject(e)
            }

        })

    }
}