export default {
    API:{
        URL: 'http://localhost:27015/api/',
        DRIVE: 'http://localhost:27014/',
        IPFS: 'https://crimson-territorial-anteater-987.mypinata.cloud/ipfs/'
    },
    SECRET:{
        LOCAL_STORAGE: 'pbuVY7Ds37T3sUz'
    },
    WEB3:{
        SOLANA:{
            RPC: 'https://api.devnet.solana.com',
            MX_PROGRAM:"BPy4zvEQNQAhWP1emjWB6X7cjH2ueWhNNYg4YkewzigF",
            USDC_PK: "3x9H95aybMSgPyFAmaoQrNsCq5cCDxx1kiP2sihfNYqw",
            USDC_MINTS:[
                "ApAmUBDwBtryRt4g6ZmMd5TE1XC2QDSVBzbNYVjbAKgg"
            ]
        },
        EVM:{
            BSC:{
                CHAIN_ID: 97,
                RPC: 'https://data-seed-prebsc-2-s1.binance.org:8545/',
                SYMBOL: 'tBNB',
                DECIMALS: 18,
                NAME: 'Binance Smart Chain Testnet',
                BLOCK_EXPLORER_URL: 'https://testnet.bscscan.com'
            },
            ETH:{ //avax
                CHAIN_ID: 43113,
                RPC: 'https://api.avax-test.network/ext/bc/C/rpc',
                SYMBOL: 'AVAX',
                DECIMALS: 18,
                NAME: 'Avalanche FUJI Testnet',
                BLOCK_EXPLORER_URL: 'https://testnet.explorer.avax.network/'
            }
        },
        WORMHOLE:{
            VAA_RPC:"https://wormhole-v2-testnet-api.certus.one",
            BSC:{
                TOKEN_BRIDGE: "0x9dcF9D205C9De35334D646BeE44b2D2859712A09",
                BRIDGE:"0x68605AD7b15c732a30b1BbC62BE8F2A509D74b4D",
                NAME:"bsc",
                TOKENS:{
                    USDC: "0x64544969ed7EBf5f083679233325356EbE738930",
                }
            },
            SOLANA:{
                TOKEN_BRIDGE: "DZnkkTmCiFWfYTfT41X3Rd1kDgozqzxWaHqsw6W4x2oe",
                BRIDGE:"3u8hJUVTA4jH1wYAyUur7FFZVQ8H635K3tSHHF4ssjQ5",
                NAME:"solana",
                TOKENS:{
                    USDC: "3x9H95aybMSgPyFAmaoQrNsCq5cCDxx1kiP2sihfNYqw",
                }
            },
            ETH:{ //avax
                TOKEN_BRIDGE: "0x61E44E506Ca5659E6c0bba9b678586fA2d729756",
                BRIDGE:"0x7bbcE28e64B3F8b84d876Ab298393c38ad7aac4C",
                NAME:"avalanche",
                TOKENS:{
                    USDC: "0x5425890298aed601595a70ab815c96711a31bc65",
                }
            }
        },
        RPC:{
            BSC:"https://data-seed-prebsc-2-s1.binance.org:8545/"
        }
    }   
}