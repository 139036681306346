import { Logo } from "components/ui/logo/style"
import styled from "styled-components"
import { color } from "styles/theme"
import { Hex2Rgba } from "utils/helpers"

export const Login = styled.div`
    padding:48px;
`

export const TopLogo = styled.div`
    ${Logo} {
        width: 78px;
    }
    margin-bottom:16px;
    display: flex;
    justify-content: center;
`

export const Title = styled.div`
    font-size: 24px;
    font-weight: 700;
    color: ${color.black};
    text-align: center;
    margin-bottom: 8px;
    line-height: 125%;
`

export const Description = styled.div`
    font-size: 14px;
    color: ${color.gray600};
    font-weight: 500;
    text-align: center;
    line-height: 125%;

    & span{
        color: ${color.blue500};
    }

`

export const DescriptionWallet = styled.div`
    font-size: 14px;
    color: ${color.gray600};
    font-weight: 500;
    text-align: center;
    line-height: 125%;

    & span{
        color: ${color.blue500};
    }

    margin-bottom: 16px;
`

export const Form = styled.form`

    margin-top:24px;

    & button{
        margin-top: 16px;
    }

`

export const BackButton = styled.div`
    position: absolute;
    cursor: pointer;
    font-size: 16px;
    left: 24px;
    top: 24px;
    padding-left:0px;
    color: ${color.black};
`


export const Address = styled.div`

`

export const Password = styled.div`

`


export const Wallets = styled.div`

`

export const Wallet = styled.div<{which:string}>`
    width: 100%;
    border-radius: 8px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    color: ${color.white};
    text-align: center;
    transition: all .1s ease-in-out;
    &:hover{
        opacity: 0.8;
    }

    ${props => props.which === "phantom" && `
        background: ${color.phantom};
    `}
    ${props => props.which === "backpack" && `
        background: ${color.backpack};
    `}
    ${props => props.which === "solflare" && `
        background: ${color.solflare};
    `}

    &:not(:last-child){
        margin-bottom: 16px;
    }
`

export const WalletIcon = styled.div`
    position: absolute;
    left: 16px;
    width: 32px;
    height: 32px;

    & img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
`

export const WalletName = styled.div`
    font-weight: 600;
    line-height: 100%;
    letter-spacing: 0.01em;
`

export const WalletOr = styled.div`
    display: flex;
    margin:32px 0;
    padding:0 40px;
    align-items: center;
`

export const WalletOrLine = styled.div`
    flex:1;
    height: 2px;
    background: ${color.gray300};
`

export const WalletOrText = styled.div`
    margin:0 16px;
    font-weight: 600;
    line-height: 100%;
    color: ${color.gray300};
`

export const WalletsBottom = styled.div`
    display: flex;
    justify-content: center;

    & button{
        flex:1
    }

    & button:first-child{
        margin-right: 8px;
    }
`

export const WalletPage = styled.div``
export const PageLoading = styled.div`
    background: ${Hex2Rgba(color.white, 0.64)};
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    & svg{
        font-size: 18px;
        color: ${color.blue500};
    }
`