import React, { useMemo } from 'react'
import * as C from './style'
import Logo from 'components/ui/logo'
import { useYupValidationResolver } from 'hooks/useYupValidationResolver'
import { useForm, Controller } from "react-hook-form"
import * as yup from "yup"
import { InputGroup } from 'components/ui/input'
import { Input } from 'components/ui/input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { Button } from 'components/ui/button'

const Address = (props:any) => {

    const schema = useMemo(
        () =>
            yup.object({
                mailxAddress: yup.string().required().default(props.mailxAddress),
            }),
        []
    )

    const resolver = useYupValidationResolver(schema)
    const { handleSubmit, control, formState: { errors } } = useForm({ resolver })

    const onSubmit = (data: any) => {
        props.onAddressFinish(data.mailxAddress)
    }

    return (
        <C.Address>
            <C.TopLogo>
                <Logo />
            </C.TopLogo>
            <C.Title>
                Welcome Back
            </C.Title>
            <C.Description>
                Welcome to the secure messaging part of the internet,
            </C.Description>
            <C.Description>
                enter the MailX account you want to log in.
            </C.Description>

            <C.Form onSubmit={handleSubmit((data) => onSubmit(data))}>

                <InputGroup settings={{
                    icon: <FontAwesomeIcon icon={faEnvelope} />,
                    label: "Email",
                    hint: errors.mailxAddress?.message as string,
                    state: errors.mailxAddress ? "error" : "default"
                }}>
                    <Controller name="mailxAddress" control={control} render={({ field }) =>
                        <Input onBlur={field.onBlur} onChange={field.onChange} checked={field.value} defaultValue={props.mailxAddress} xref={field.ref} type='input' placeholder='your mailx account' autoFocus />}
                    />
                </InputGroup>

                <Button type="submit" variant="primary" size="large" block>Continue</Button>

            </C.Form>

        </C.Address>
    )

}

export default Address