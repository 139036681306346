import React, { useState } from 'react'
import * as C from './style'
import { Button } from 'components/ui/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { SolanaWallets } from 'modules/wallet/solana'
import { CreateRegisterWalletVerification, VerifyRegisterWallet } from 'services/api/auth'
import { toast } from 'react-hot-toast'

const WalletPage = (props: any) => {

    const [loading, setLoading] = useState(false)

    const connect = (wallet: "phantom" | "solflare") => {

        const walletClass = SolanaWallets[wallet]

        if (walletClass) {
            const wallet = new walletClass()

            if (!walletClass.isInstalled()) {
                wallet.install()
                return;
            }


            wallet.connect().then(async () => {

                if (walletClass.isConnected()) {

                    props.onWalletFinish(wallet)

                }
            }).catch((err: any) => {
                console.log(err)
                setLoading(false)
            })
        }

    }

    return (
        <C.WalletPage>

            <C.BackButton onClick={props.back}>
                <FontAwesomeIcon icon={faArrowLeft} />
            </C.BackButton>

            <C.TitleWallet>
                Connect Your Wallet
            </C.TitleWallet>
            <C.Description>
                Select the wallet you want to pay with
            </C.Description>

            <C.Wallets>
                <C.Wallet which='phantom' onClick={() => connect('phantom')}>
                    <C.WalletIcon>
                        <img src="/images/wallets/phantom.png" alt="phantom" />
                    </C.WalletIcon>
                    <C.WalletName>
                        Phantom Wallet
                    </C.WalletName>
                </C.Wallet>
                <C.Wallet which='backpack'>
                    <C.WalletIcon>
                        <img src="/images/wallets/backpack.png" alt="backpack" />
                    </C.WalletIcon>
                    <C.WalletName>
                        Backpack Wallet
                    </C.WalletName>
                </C.Wallet>
                <C.Wallet which='solflare' onClick={() => connect('solflare')} >
                    <C.WalletIcon>
                        <img src="/images/wallets/solflare.png" alt="solflare" />
                    </C.WalletIcon>
                    <C.WalletName>
                        Solflare Wallet
                    </C.WalletName>
                </C.Wallet>
            </C.Wallets>

            <C.WalletOr>
                <C.WalletOrLine></C.WalletOrLine>
                <C.WalletOrText>or</C.WalletOrText>
                <C.WalletOrLine></C.WalletOrLine>
            </C.WalletOr>

            <C.WalletsBottom>
                <Button variant="secondary" size="medium" block>Connect Ledger</Button>
            </C.WalletsBottom>

        </C.WalletPage>
    )

}

export default WalletPage