import config from "config";


export const Hex2Rgba = (hex: string, alpha: number) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export const shortenPublicKey = (publicKey: string, len?: number) => {
    if (len) {
        return publicKey.slice(0, len)
    }
    return publicKey.slice(0, 4) + "..." + publicKey.slice(-4);
}

export const shortenText = (text: string, len?: number) => {
    if (len) {
        return text.slice(0, len)
    }
    return text.slice(0, 4) + "..." + text.slice(-4);
}

export const preloadImagesHome = () => {

    const images = [
        "/images/register_Bg.png",
        "/images/empty-content.png",
        "/images/wallets/phantom.png",
        "/images/wallets/solflare.png",
        "/images/wallets/backpack.png",
    ]

    images.forEach((image) => {
        const img = new Image();
        img.src = image;
    })

}

export function isBase58EncodedMailAddress(str: string) {
    const regex = /^[1-9A-HJ-NP-Za-km-z]+$/;
    return regex.test(str) && str.length === 44
}


export const deserializeUint8Array = (base64: string) => {
    return Buffer.from(base64, 'base64');
}

export const sleep = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const generateProfilePicture = (name: string) => {

    //if two words, take first letter of each word if one word, take first two letters
    const words = name.split(" ");
    let initials = "";
    try {
        if (words.length === 1) {
            initials = words[0].slice(0, 2);
        } else {
            initials = words[0].slice(0, 1) + words[1].slice(0, 1);
        }
    } catch (err) {
        initials = ""
    }

    //generate random color based on name
    const colors = ["#101828"]

    const colorBasedOnName = colors[name.length % colors.length];

    //generate base64 image
    const canvas = document.createElement('canvas');
    canvas.width = 150;
    canvas.height = 150;
    const context: any = canvas.getContext('2d');
    context.fillStyle = colorBasedOnName;
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.font = "48px Manrope, sans-serif";
    context.fillStyle = "#ffffff";
    context.textAlign = "center";
    context.textBaseline = "middle";
    context.fillText(initials.toUpperCase(), 75, 75 + 3);
    const dataURL = canvas.toDataURL();
    return dataURL;
}

export const getEvmNetworkDetails = (network: 'bsc' | 'avalanche') => {
    switch (network) {
        case 'bsc':
            return {
                chainId: "0x" + config.WEB3.EVM.BSC.CHAIN_ID.toString(16),
                chainName: config.WEB3.EVM.BSC.NAME,
                rpcUrls: [config.WEB3.EVM.BSC.RPC],
                blockExplorerUrls: [config.WEB3.EVM.BSC.BLOCK_EXPLORER_URL],
                nativeCurrency: {
                    name: config.WEB3.EVM.BSC.SYMBOL,
                    symbol: config.WEB3.EVM.BSC.SYMBOL,
                    decimals: config.WEB3.EVM.BSC.DECIMALS,
                },
            }
        case 'avalanche':
            return {
                chainId: "0x" + config.WEB3.EVM.ETH.CHAIN_ID.toString(16),
                chainName: config.WEB3.EVM.ETH.NAME,
                rpcUrls: [config.WEB3.EVM.ETH.RPC],
                blockExplorerUrls: [config.WEB3.EVM.ETH.BLOCK_EXPLORER_URL],
                nativeCurrency: {
                    name: config.WEB3.EVM.ETH.SYMBOL,
                    symbol: config.WEB3.EVM.ETH.SYMBOL,
                    decimals: config.WEB3.EVM.ETH.DECIMALS,
                },
            }
    }
}

export const getNetworkWormholeDetails = (network: 'bsc' | 'solana' | 'avalanche') => {
    switch (network) {
        case 'bsc':
            return {
                tokenBridge: config.WEB3.WORMHOLE.BSC.TOKEN_BRIDGE,
                bridge: config.WEB3.WORMHOLE.BSC.BRIDGE,
                name: config.WEB3.WORMHOLE.BSC.NAME,
                tokens: config.WEB3.WORMHOLE.BSC.TOKENS
            }
        case 'solana':
            return {
                tokenBridge: config.WEB3.WORMHOLE.SOLANA.TOKEN_BRIDGE,
                bridge: config.WEB3.WORMHOLE.SOLANA.BRIDGE,
                name: config.WEB3.WORMHOLE.SOLANA.NAME,
                tokens: config.WEB3.WORMHOLE.SOLANA.TOKENS
            }
        case 'avalanche':
            return {
                tokenBridge: config.WEB3.WORMHOLE.ETH.TOKEN_BRIDGE,
                bridge: config.WEB3.WORMHOLE.ETH.BRIDGE,
                name: config.WEB3.WORMHOLE.ETH.NAME,
                tokens: config.WEB3.WORMHOLE.ETH.TOKENS
            }
    }
}

export const getRpcForNetwork = (network: 'bsc'|"avalanche") => {
    switch (network) {
        case 'bsc':
            return config.WEB3.RPC.BSC
        case 'avalanche':
            return config.WEB3.EVM.ETH.RPC
    }
}

export const parseFileSize = (bytes: number) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes == 0) return '0 Byte'
    const i = Math.floor(Math.log(bytes) / Math.log(1024))
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i]
}