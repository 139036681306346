import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default (props:React.PropsWithChildren<any>) => {

    const user = useSelector((state: any) => state.user.user)

    if (user !== null) {
        return props.children
    }

    return <Navigate to="/?login" />

}