import styled from 'styled-components'
import { color } from 'styles/theme'

export const Mail = styled.div`
    flex:1;
    overflow-y: auto;
    max-height: calc(100vh - 88px);
`

export const Header = styled.div`
    display: flex;
    padding:24px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${color.gray200};
`

export const HeaderLeft = styled.div`
    display: flex;
    align-items: center;
`

export const HeaderBack = styled.div`
    margin-right: 10px;
    padding-right:26px;
    padding-top:16px;
    padding-bottom:16px;
    & svg{
        height:16px;
    }
    cursor: pointer;
    display: flex;
    align-items: center;
`


export const HeaderRight = styled.div`

`

export const HeaderRightBottom = styled.div`
    display: flex;
    align-items: center;

    & > *:not(:last-child){
        &:after{
            content: '';
            display: inline-block;
            width: 1px;
            height: 14px;
            background-color: ${color.gray500};
            margin: 0 8px;
        }
    }
`

export const HeaderRightBottomItem = styled.div<{itemType:string}>`
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    ${(props) => props.itemType === 'mailtype-default' && `
        color: ${color.gray800};
    `}
    ${(props) => props.itemType === 'mailtype-tpp' && `
        color: ${color.blue500};
    `}

    ${(props) => props.itemType === 'date' && `
        color: ${color.blue900};
    `}
`

export const HeaderRightTopItemA = styled.a`
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    color: ${color.gray500};
    text-decoration: none;
    &:hover{
        color: ${color.blue500};
    }
`

export const HeaderRightTop = styled.div`
    margin-bottom: 16px;
    display: flex;
    justify-content: flex-end;
`

export const MailAction = styled.div<{actiontype:string}>`
    & svg{
        height:16px;
    }
    cursor: pointer;

    ${(props) => props.actiontype === 'delete' && `
        &:hover{
            color: ${color.red500};
        }
    `}

    ${(props) => props.actiontype !== 'delete' && `
        &:hover{
            color: ${color.blue500};
        }
    `}

    &:not(:last-child){
        margin-right: 16px;
    }
`

export const Content = styled.div`
    padding: 24px 32px;
`


export const FromTo = styled.div`
    display: flex;
    align-items: center;
    
`

export const FromPP = styled.div`
    width: 56px;
    height: 56px;
    margin-right: 16px;
    & > img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
`

export const FromToContent = styled.div`

`

export const FromDetails = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
`

export const FromName = styled.div`
    font-size: 20px;
    color: ${color.black};
    font-weight: 600;
    line-height: 100%;
`

export const FromAddress = styled.div`
    color: ${color.gray600};
    font-size: 14px;
    font-weight: 500;
    margin-left: 8px;
`

export const ToName = styled.div`
    font-size: 14px;
    color: ${color.gray500};
    font-weight: 500;
    line-height: 100%;
`

export const ToTppStatus = styled.div<{status:"waiting"|"rejected"|"accepted"}>`
    width: 8px;
    height: 8px;
    border-radius: 50%;

    ${(props) => props.status === 'waiting' && `background-color: ${color.orange500};`}
    ${(props) => props.status === 'rejected' && `background-color: ${color.red500};`}
    ${(props) => props.status === 'accepted' && `background-color: ${color.green500};`}
    display: inline-block;
    margin-left: 2px;
`

export const Subject = styled.div`
    font-size: 20px;
    font-weight: bold;
    line-height: 125%;
`

export const Body = styled.div`
    position: relative;
    padding-top: 12px;
`

export const FooterActions = styled.div`
    margin-top: 32px;
     & button{
        width:164px;
     }
`

export const Attachments = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;
    & > *:not(:last-child){
        margin-right: 16px;
    } 
`

export const Attachment = styled.div`
    padding:8px;
    background-color: ${color.gray200};
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width:245px;
    border-radius: 8px;
    cursor: pointer;

    &:hover{
        background-color: ${color.gray300};
    }
`

export const AttachmentLeft = styled.div`
    display: flex;
    align-items: center;
`

export const AttachmentIcon = styled.div`
    border-radius: 50%;
    background-color: ${color.white};
    display: flex;
    align-items: center;
    justify-content: center;
    width:40px;
    height: 40px;
    margin-right: 16px;
    & svg{
        height: 18px;
    }
`

export const AttachmentDetails = styled.div`

`

export const AttachmentName = styled.div`
    font-weight: 600;
    line-height: 100%;
    font-size: 14px;
    color: ${color.black};
`

export const AttachmentSize = styled.div`
    font-size: 12px;
    line-height: 100%;
    color: ${color.gray500};
    font-weight: 500;
    margin-top: 8px;
`

export const AttachmentDownload = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background-color: ${color.gray400};
    border-radius: 8px;
    color: ${color.white};
`

export const Notification = styled.div`
    & button{
        width:167px;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${color.orange100};
    padding:24px;
`

export const NotificationText = styled.div`
    font-weight: 500;
    line-height: 125%;
    color: ${color.black};
    & > span{
        color: ${color.orange900};
        font-weight: 600;
    }
`

export const Access = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;

    & img{
        height:136px;
        pointer-events: none;
        user-select: none;
    }
`

export const AccessTitle = styled.div`
    margin-top: 16px;
    font-weight: bold;
    font-size: 24px;
    line-height: 125%;
    color: ${color.black};
    margin-bottom: 8px;
    text-align: center;
`

export const AccessText = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 125%;
    color: ${color.gray600};
    text-align: center;
    & > span{
        color: ${color.blue500};
    }
`

export const AccessActions = styled.div`
    display: flex;
    margin-top: 32px;
    & > *:not(:last-child){
        margin-right: 16px;
    }
    & button{
        width:200px;
    }
`


export const ThreadContainer = styled.div`
    padding:32px;
`

export const Thread = styled.div`
    
`

export const TMContent = styled.div`

`

export const TMHeader = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin-bottom:24px;
`
export const TMHeaderRightActions = styled.div`
    display:flex;
`

export const ThreadMail = styled.div<{iscollapsed?:string}>`
    padding:24px;
    background-color:${color.gray100};
    border-radius:8px;

    &:not(:last-child){
        margin-bottom: 24px;
    }

    ${(props) => props.iscollapsed === 'true' && `
        & ${TMContentBody}{
            display:none;
        }

        & ${Attachments}{
            display:none;
        }

        & ${TMHeader}{
            margin-bottom:0px;
        }

        & ${TMHeaderRightActions}{
            display:none;
        }

        cursor:pointer;
    `}
`



export const TMHeaderLeft = styled.div`
    display:flex;
    align-items:center;
`

export const TMHeaderLeftAvatar = styled.div`
    width:40px;
    height:40px;

    & img{
        width:100%;
        height:100%;
        border-radius:50%;
    }

    margin-right:16px;
`

export const TMHeaderFromToContent = styled.div`
    
`

export const TMHeaderFromDetails = styled.div`
    display:flex;
    align-items:center;
    margin-bottom: 8px;
`

export const TMHeaderFromName = styled.div`
    color:${color.black};
    font-weight:600;
    line-height:100%;
`

export const TMHeaderFromAddress = styled.div`
    color: ${color.gray600};
    font-size: 14px;
    font-weight: 500;
    margin-left: 8px;
`

export const TMHeaderToName = styled.div`
    font-size: 12px;
    color: ${color.gray500};
    font-weight: 500;
    line-height: 100%;
`

export const TMHeaderRight = styled.div`
    display:flex;
    align-items:center;
`


export const ThreadMailAction = styled.div<{actiontype:string}>`
    & svg{
        height:14px;
    }
    cursor: pointer;

    ${(props) => props.actiontype === 'delete' && `
        &:hover{
            color: ${color.red500};
        }
    `}

    ${(props) => props.actiontype !== 'delete' && `
        &:hover{
            color: ${color.blue500};
        }
    `}

    &:not(:last-child){
        margin-right: 16px;
    }
`


export const TMHeaderSeperator = styled.div`
    width:1px;
    height:24px;
    background-color:${color.gray300};
    margin:0px 16px;
`

export const TMHeaderRightDate = styled.div`
    color:${color.blue500};
    font-size:12px;
    font-weight:500;
`



export const TMContentBody = styled.div`
    position: relative;
`

