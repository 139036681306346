import { useEffect, useRef, useState } from "react"
import * as C from "./style"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { useDispatch, useSelector } from "react-redux"
import { DeleteNotification, getNotifications, markAllNotificationsAsRead } from "services/api/notifications"
import { toast } from "react-hot-toast"
import moment from "moment"
import { hideModal } from "utils/modal"
import { setNotificationsCountForCurrentAccount } from "services/redux/slices/user"
import { generateProfilePicture } from "utils/helpers"

const Notifications = () => {

    const currentAccount = useSelector((state: any) => state.user.currentAccount)
    const user = useSelector((state: any) => state.user.user)
    const page = useSelector((state: any) => state.mail.page)
    const [loading, setLoading] = useState(false)
    const [notifications, setNotifications] = useState<any>([])

    const [markAllAsReadLoading, setMarkAllAsReadLoading] = useState(false)
    const dispatch = useDispatch()

    const [currentTab, setCurrentTab] = useState("all")

    useEffect(() => {

        getNotifications(currentAccount).then((res: any) => {

            let data = res.data.notifications

            for (let i = 0; i < data.length; i++) {
                data[i].user = (res.data.users.find((user: any) => user.accounts.find((account: any) => account.ownedAddress === data[i].party))).accounts.find((account: any) => account.ownedAddress === data[i].party)
            }

            setNotifications(data)
            dispatch(setNotificationsCountForCurrentAccount(res.data.notificationsCount))

        }).catch((err: any) => {
            console.log(err)
            toast.error("An error occured while getting notifications.")
        })

    }, [])

    const Notification = (props: any) => {

        let { notification, ...rest } = props

        if (notification.type === "tpp-request" || notification.type === "tpp-accepted" || notification.type === "tpp-rejected" || notification.type === "tpp-vote")
            return <C.NotificationLink {...rest} to={"/mail/" + (page === "inbox" ? "inbox/" : "sent/") + notification.content.mailAddress} />

        return <C.Notification {...rest} />
    }

    const onDeleteNotification = (e: any, id: any) => {
        e.preventDefault()
        e.stopPropagation()

        DeleteNotification(currentAccount, id).catch((err: any) => {
            console.log(err)
        })

        //remove from state
        setNotifications(notifications.filter((n: any) => n._id !== id))
    }

    const markAllAsRead = () => {

        if (markAllAsReadLoading)
            return;

        if (notifications.length === 0)
            return;

        if (notifications.filter((n: any) => !n.read).length === 0)
            return;

        setNotifications(notifications.map((n: any) => {
            n.read = true
            return n
        }))

        dispatch(setNotificationsCountForCurrentAccount(0))

        setMarkAllAsReadLoading(true)

        markAllNotificationsAsRead(currentAccount).catch((err: any) => {
            console.log(err)
        }).finally(() => {
            setMarkAllAsReadLoading(false)
        })
    }

    const onNotificationClick = (e: any) => {
        hideModal("notifications")
    }

    return (
        <C.Notifications>
            <C.Header>
                <C.Title>
                    Notifications
                </C.Title>
                <C.MarkAllAsRead onClick={markAllAsRead}>
                    Mark all as read
                </C.MarkAllAsRead>
            </C.Header>
            <C.TabsContainer>
                <C.Tabs>
                    <C.Tab active={currentTab === "all" ? "true" : "false"} onClick={() => setCurrentTab("all")}>
                        All
                    </C.Tab>
                    <C.Tab active={currentTab === "xmail" ? "true" : "false"} onClick={() => setCurrentTab("xmail")}>
                        XMail's
                    </C.Tab>
                    <C.Tab active={currentTab === "notices" ? "true" : "false"} onClick={() => setCurrentTab("notices")}>
                        Notices
                    </C.Tab>
                </C.Tabs>
            </C.TabsContainer>
            {/*
            <C.EmptyContent>
                <img src="/images/no-notifications.png" alt="no notifications" />
                <C.EmptyContentText>
                    You have no notifications
                </C.EmptyContentText>
            </C.EmptyContent>
            */}

            <C.Content>
                {currentTab !== "notices" && notifications.map((notification: any, i: number) => (
                    <Notification notification={notification} read={notification.read ? "true" : "false"} key={i} onClick={onNotificationClick}>
                        <C.NotificationAvatar>
                            <img src={generateProfilePicture(typeof notification.user !== "undefined" ? notification.user.name : "")} alt="profile picture" />
                        </C.NotificationAvatar>
                        <C.NotificationContent>
                            {notification.type === "tpp-request" && (
                                <C.NotificationText>
                                    <C.NThighlight>{notification.user.name}</C.NThighlight> wants to send <C.NTblue>XMail</C.NTblue> to you {notification.content.recipientCount > 1 && (<C.NThighlight>and {notification.content.recipientCount - 1} others</C.NThighlight>)}: “<C.NTblack>{notification.content.subject}</C.NTblack>”
                                </C.NotificationText>
                            )}
                            {notification.type === "tpp-accepted" && (
                                <C.NotificationText>
                                    <C.NThighlight>{notification.user.name}</C.NThighlight> <C.NTgreen>accepted</C.NTgreen> your XMail on: “<C.NTblack>{notification.content.subject}</C.NTblack>”
                                </C.NotificationText>
                            )}
                            {notification.type === "tpp-rejected" && (
                                <C.NotificationText>
                                    <C.NThighlight>{notification.user.name}</C.NThighlight> <C.NTred>rejected</C.NTred> your XMail on: “<C.NTblack>{notification.content.subject}</C.NTblack>”
                                </C.NotificationText>
                            )}
                            {notification.type === "tpp-vote" && (
                                <C.NotificationText>
                                    <C.NThighlight>{notification.user.name}</C.NThighlight> wants to start a deletion vote of <C.NTblue>XMail</C.NTblue> you are on: “<C.NTblack>{notification.content.subject}</C.NTblack>”
                                </C.NotificationText>
                            )}


                            <C.NotificationActions>
                                {/*notification.type === "tpp-request" && (
                                    <C.NotificationActionsLeft>
                                        <C.NotificationAction actiontype="reject">
                                            <span>Reject</span>
                                        </C.NotificationAction>
                                        <C.NotificationAction actiontype="accept">
                                            <span>Accept</span>
                                        </C.NotificationAction>
                                    </C.NotificationActionsLeft>
                                )*/}
                                <div></div>
                                <C.NotificationActionsRight>
                                    <C.NotificationTime>
                                        {moment(notification.createdAt).fromNow()}
                                    </C.NotificationTime>
                                    <C.NotificationActionsDelete onClick={(e) => onDeleteNotification(e, notification._id)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </C.NotificationActionsDelete>
                                </C.NotificationActionsRight>
                            </C.NotificationActions>
                        </C.NotificationContent>
                    </Notification>
                ))}

            </C.Content>
        </C.Notifications>
    )

}



export default Notifications