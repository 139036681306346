import React, { useEffect, useState } from 'react'
import * as C from './style'
import Address from './address'
import LoginMethod from './loginMethod'
import Password from './password'
import Name from './name'
import Loading from './loading'
import { register } from 'services/api/auth'
import { setLocalData } from 'utils/localData'
import { useNavigate } from 'react-router-dom'
import { setUser, setCurrentAccount } from 'services/redux/slices/user'
import { useDispatch } from 'react-redux'
import WalletPage from './wallet'
import { setServerPublicKey } from 'services/redux/slices/mail'
import bs58 from 'bs58'

const Register = (props: any) => {

    const [mailxAddress, setMailxAddress] = useState("")
    const [password, setPassword] = useState("")
    const [name, setName] = useState("")
    const [loadingFinished, setLoadingFinished] = useState(false)
    const [loadingError, setLoadingError] = useState("")

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const onAddressFinish = (address: string) => {
        props.setStep(1)
        setMailxAddress(address)
    }

    useEffect(() => {
        if (props.step === 0) {
            setLoadingError("")
            setLoadingFinished(false)
        }
    }, [props.step])

    const back = () => {
        if (props.step === 1) {
            props.setStep(0)
        } else if (props.step === 2) {
            props.setStep(1)
        } else if (props.step === 3 || props.step === 4) {
            props.setStep(2)
            setPassword("")
        } else if (props.step === 5) {
            props.setStep(0)
        }
    }

    const onLoginMethodFinish = (method: string) => {
        if (method === "password") {
            props.setStep(3)
        } else {
            props.setStep(4)
        }
    }

    const onNameFinish = (name: string) => {
        props.setStep(2)
        setName(name)
    }

    const onPasswordFinish = (password: string) => {
        props.setStep(5)
        setPassword(password)

        register(mailxAddress, password, name).then((res) => {
            //setLoadingFinished(true)
            setLocalData("token", res.data.token)
            setLocalData("key", bs58.encode(new TextEncoder().encode(password)))
            setLocalData("selectedAccount", res.data.accounts[0].address)

            dispatch(setCurrentAccount(res.data.accounts[0].address))
            dispatch(setUser({
                accounts: res.data.accounts,
                names: res.data.names,
                cloudWallet: res.data.cloudWallet,
            }))

            dispatch(setServerPublicKey(res.data.c))

            navigate("/mail")
            props.hide()

        }).catch((err) => {

            //if 409
            if (err.response.status === 409) {
                setLoadingError("This email is already registered")
                return;
            }

            console.log(err)
            setLoadingError("Something went wrong, please try again")
        })
    }

    const onWalletFinish = (address: string, signature: string) => {
        props.setStep(5)
        setPassword("")
        register(mailxAddress, password, name, address).then((res) => {
            //setLoadingFinished(true)
            setLocalData("token", res.data.token)
            setLocalData("key", bs58.encode(new TextEncoder().encode(signature)))
            setLocalData("selectedAccount", res.data.accounts[0].address)

            dispatch(setCurrentAccount(res.data.accounts[0].address))

            dispatch(setUser({
                accounts: res.data.accounts,
                names: res.data.names,
                cloudWallet: res.data.cloudWallet,
            }))

            dispatch(setServerPublicKey(res.data.c))

            navigate("/mail")
            props.hide()

        }).catch((err) => {
            //if 409
            if (err.response.status === 409) {
                setLoadingError("This email is already registered")
                return;
            }

            console.log(err)
            setLoadingError("Something went wrong, please try again")
        })

    }

    const launch = () => {
        props.hide()
        navigate("/mail")
    }

    return (
        <C.Register>

            {props.step === 0 && <Address mailxAddress={mailxAddress} onAddressFinish={onAddressFinish} />}
            {props.step === 1 && <Name onNameFinish={onNameFinish} name={name} back={back} />}
            {props.step === 2 && <LoginMethod mailxAddress={mailxAddress} back={back} onLoginMethodFinish={onLoginMethodFinish} />}
            {props.step === 3 && <Password onPasswordFinish={onPasswordFinish} back={back} />}
            {props.step === 4 && <WalletPage onWalletFinish={onWalletFinish} back={back} />}
            {props.step === 5 && <Loading launch={launch} loadingFinished={loadingFinished} back={back} loadingError={loadingError} />}


        </C.Register>
    )

}

export default Register
