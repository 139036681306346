import { Sidebar } from 'components/parts/mail/sidebar/style'
import styled from 'styled-components'
import { color } from 'styles/theme'

export const Mail = styled.div`
    display: flex;
    & ${Sidebar} {
        
    }
`

export const Main = styled.div`
    flex: 1;
`

export const Content = styled.div`
    display: flex;
    background-color: ${color.gray50};
`