import axios from 'axios'
import config from 'config'
import { getLocalData } from 'utils/localData'

export const getAccounts = () => {

    return axios.get(config.API.URL + "user/account/get-accounts", {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}