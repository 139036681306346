import { configureStore } from "@reduxjs/toolkit"
import dataReducer from "./slices/data"
import userReducer from "./slices/user"
import mailReducer from "./slices/mail"

export const store = configureStore({
    reducer: {
        data: dataReducer,
        user: userReducer,
        mail: mailReducer
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch