import { useEffect, useRef, useState } from "react"
import * as C from "./style"
import { Input, InputGroup } from "components/ui/input"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBank, faCheck, faChevronDown, faFilter, faSearch } from "@fortawesome/free-solid-svg-icons"
import { faBell } from "@fortawesome/free-regular-svg-icons"
import { useDispatch, useSelector } from "react-redux"
import Notifications from "../notifications"
import { hideModal, isModalActive, showModal } from "utils/modal"
import AccountDropdown from "components/parts/other/accountDropdown"
import { setFilters, setMails, setRefreshBox } from "services/redux/slices/mail"
import { IconBell, IconFilter } from "utils/icons"
import BankAccounts from "../bankAccounts"

const Header = () => {

    const dispatch = useDispatch()
    const currentAccount = useSelector((state: any) => state.user.currentAccount)
    const user = useSelector((state: any) => state.user.user)
    const notificationsVisible = isModalActive(useSelector((state: any) => state.data.modalStates), "notifications")
    const bankAccountsVisible = isModalActive(useSelector((state: any) => state.data.modalStates), "bankAccounts")

    const [filtersOpen, setFiltersOpen] = useState(false)
    const [filstersSortOpen, setFiltersSortOpen] = useState(false)

    const filtersDropdownRef = useRef<any>(null);

    const handleFiltersClickOutside = (event: any) => {
        if (filtersDropdownRef.current && !filtersDropdownRef.current.contains(event.target)) {
            setFiltersOpen(false)
            setFiltersSortOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleFiltersClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleFiltersClickOutside);
        };
    });

    const filters = useSelector((state: any) => state.mail.filters)

    const onFilterClick = (filter: string) => {
        let _filters = { ...filters }

        if (_filters[filter] === undefined)
            _filters[filter] = true
        else
            delete _filters[filter]

        dispatch(setFilters(_filters))
        dispatch(setMails([]))
        dispatch(setRefreshBox(true))
    }

    const onSortClick = (sort: "newest" | "oldest") => {
        let _filters = { ...filters }

        if (sort === "oldest")
            _filters.sort = "oldest"
        else
            delete _filters.sort

        dispatch(setFilters(_filters))
        dispatch(setMails([]))
        dispatch(setRefreshBox(true))
    }

    const isAnyFilterActive = () => {
        if (filters["unread"] || filters["labeled"] || filters["attachments"] || filters["sort"])
            return true
        else
            return false
    }


    return (
        <>

            {notificationsVisible && (
                <C.NotificationsOverlay onClick={() => hideModal("notifications")} ></C.NotificationsOverlay>
            )}
            {bankAccountsVisible && (
                <C.BankAccountsOverlay onClick={() => hideModal("bankAccounts")} ></C.BankAccountsOverlay>
            )}

            <C.Header>


                <C.Search>
                    <InputGroup
                        settings={{
                            icon: <FontAwesomeIcon icon={faSearch} />,
                            state: "default"
                        }}
                    >
                        <Input type="text" placeholder="Search mail" />
                    </InputGroup>

                    <C.Filter ref={filtersDropdownRef}>
                        {isAnyFilterActive() && (
                            <C.FiltersBubble></C.FiltersBubble>
                        )}
                        <C.FilterIcon onClick={() => setFiltersOpen(!filtersOpen)}>
                            <IconFilter />
                        </C.FilterIcon>
                        {filtersOpen && (
                            <C.FilterDropdown>
                                <C.FilterDropdownItem onClick={() => onFilterClick("unread")}>
                                    <C.FilterDropdownItemCheckbox active={filters["unread"] ? "true" : "false"}>
                                        <FontAwesomeIcon icon={faCheck} />
                                    </C.FilterDropdownItemCheckbox>
                                    <C.FilterDropdownItemText>Unread</C.FilterDropdownItemText>
                                </C.FilterDropdownItem>
                                <C.FilterDropdownItem onClick={() => onFilterClick("labeled")}>
                                    <C.FilterDropdownItemCheckbox active={filters["labeled"] ? "true" : "false"} >
                                        <FontAwesomeIcon icon={faCheck} />
                                    </C.FilterDropdownItemCheckbox>
                                    <C.FilterDropdownItemText>Labeled</C.FilterDropdownItemText>
                                </C.FilterDropdownItem>
                                <C.FilterDropdownItem onClick={() => onFilterClick("attachments")}>
                                    <C.FilterDropdownItemCheckbox active={filters["attachments"] ? "true" : "false"} >
                                        <FontAwesomeIcon icon={faCheck} />
                                    </C.FilterDropdownItemCheckbox>
                                    <C.FilterDropdownItemText>Attachments</C.FilterDropdownItemText>
                                </C.FilterDropdownItem>

                                <C.FilterDropdownSort>
                                    <C.FilterDropdownSortText onClick={() => setFiltersSortOpen(!filstersSortOpen)}>
                                        <span>Sort by</span>
                                        <FontAwesomeIcon icon={faChevronDown} />
                                    </C.FilterDropdownSortText>
                                    {filstersSortOpen && (
                                        <C.FilterDropdownSortContent>
                                            <C.FilterDropdownSortContentItem onClick={() => onSortClick("newest")}>
                                                <C.FilterDropdownItemCheckbox active={filters["sort"] ? "false" : "true"} >
                                                    <FontAwesomeIcon icon={faCheck} />
                                                </C.FilterDropdownItemCheckbox>
                                                Newest
                                            </C.FilterDropdownSortContentItem>
                                            <C.FilterDropdownSortContentItem onClick={() => onSortClick("oldest")}>
                                                <C.FilterDropdownItemCheckbox active={filters["sort"] ? "true" : "false"} >
                                                    <FontAwesomeIcon icon={faCheck} />
                                                </C.FilterDropdownItemCheckbox>
                                                Oldest
                                            </C.FilterDropdownSortContentItem>
                                        </C.FilterDropdownSortContent>
                                    )}
                                </C.FilterDropdownSort>

                            </C.FilterDropdown>
                        )}
                    </C.Filter>
                </C.Search>

                <C.Right>

                    <C.BankAccounts>
                        <C.BankAccountsIcon onClick={() => showModal("bankAccounts")}>
                            <FontAwesomeIcon icon={faBank} />
                        </C.BankAccountsIcon>
                        {bankAccountsVisible && (
                            <BankAccounts />
                        )}
                    </C.BankAccounts>

                    <C.Notifications>
                        <C.NotificationsIcon onClick={() => showModal("notifications")}>
                            <IconBell />
                            {user.accounts.find((item: any) => item.address === currentAccount).notificationsCount > 0 && (
                                <C.NotificationsCount>
                                    {user.accounts.find((item: any) => item.address === currentAccount).notificationsCount}
                                </C.NotificationsCount>
                            )}
                        </C.NotificationsIcon>
                        {notificationsVisible && (
                            <Notifications />
                        )}
                    </C.Notifications>

                    <AccountDropdown />
                </C.Right>

            </C.Header>
        </>
    )
}

export default Header