import { useEffect, useRef, useState } from "react"
import * as P from "./style"
import { Container } from "styles"
import Logo from "components/ui/logo"
import { Button } from "components/ui/button"
import { showModal } from "utils/modal"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { preloadImagesHome } from "utils/helpers"
import HeaderHome from "components/parts/other/headerHome"

const Home = () => {

    const user = useSelector((state: any) => state.user.user)
    const navigate = useNavigate()
    //const [show, setShow] = useState(false)

    useEffect(() => {

        /*if (user !== null) {
            navigate("/mail")
        } else {
            setShow(true)*/

            // preload images
            preloadImagesHome()

            //if ?login=true
            const params = new URLSearchParams(window.location.search)
            if (params.get("login") !== null) {
                showModal("login")
                //remove ?login=true
                window.history.replaceState({}, document.title, window.location.pathname);
            }
        //}

    }, [])

    /*if (!show) {
        return (<></>)
    }*/

    return (
        <P.Home>
            <HeaderHome theme="home" />

            <P.Content>
                <P.Hero>
                    <Container>
                        <P.HeroTitle first="true">
                            Welcome to hub of
                        </P.HeroTitle>
                        <P.HeroTitle>
                            Secure & Private Messaging
                        </P.HeroTitle>
                        <P.HeroDesc first="true">
                            MailX is a blockchain-supported, end-to-end encrypted
                        </P.HeroDesc>
                        <P.HeroDesc>
                            message in mail structure platform.
                        </P.HeroDesc>
                        <P.HeroImage>
                            <img src="/images/hero.png" alt="hero" />
                        </P.HeroImage>
                    </Container>
                </P.Hero>
            </P.Content>
        </P.Home>
    )

}

export default Home