import * as M from "./style"
import { useSelector } from "react-redux"
import { hideModal, isModalActive, getModalProps, getModalCallbacks } from "utils/modal"
import BaseModal from "../../base"
import { Button } from "components/ui/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { useEffect, useState } from "react"

const ConfirmationModal = () => {

    const show = isModalActive(useSelector((state: any) => state.data.modalStates), "confirmation")
    const props = getModalProps(useSelector((state: any) => state.data.modalStates), "confirmation")

    const [data, setData] = useState<any>(null)

    useEffect(() => {
        if (props !== null)
            setData(props)
    }, [props])

    return (
        <M.Modal>
            <BaseModal
                header={false}
                hide={() => hideModal("confirmation")}
                show={show}
            >
                {data !== null && (
                    <M.Confirmation>
                        {data.icon && (
                            <M.Icon color="red">
                                {data.icon === "delete" && <FontAwesomeIcon icon={faTrashAlt} />}
                            </M.Icon>
                        )}

                        <M.Title>
                            {data.title}
                        </M.Title>

                        <M.Description>
                            {data.description}
                        </M.Description>

                        <M.Buttons>
                            {data.buttons.map((button: any, index: number) => (
                                <Button variant={button.variant} onClick={() => getModalCallbacks("confirmation")[button.onClick]()} key={index} size="large"> {button.label} </Button>
                            ))}
                        </M.Buttons>

                    </M.Confirmation>
                )}


            </BaseModal>
        </M.Modal>
    )

}

export default ConfirmationModal