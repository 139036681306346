import * as M from "./style"
import { useSelector } from "react-redux"
import { hideModal, isModalActive } from "utils/modal"
import BaseModal from "../../base"
import Login from "components/parts/auth/login"

const LoginModal = () => {

    const show = isModalActive(useSelector((state: any) => state.data.modalStates), "login")

    return (
        <M.Modal>
            <BaseModal
                header={false}
                hide={() => hideModal("login")}
                show={show}
            >
                <Login hide={() => hideModal("login")} />
            </BaseModal>
        </M.Modal>
    )

}

export default LoginModal