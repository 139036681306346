import { Logo } from 'components/ui/logo/style'
import styled from 'styled-components'
import { color } from 'styles/theme'

export const Sidebar = styled.div`
    width: 337px;
    height: 100vh;
    background-color: ${color.black};
    color: ${color.white};
    padding: 24px;
`

export const TopLogo = styled.div`
    & ${Logo} {
        width: 95px;
    }
`

export const NewMailButton = styled.div`
    margin-top: 32px;
    & button {
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        & svg {
            margin-left: 8px;
            height: 14px;
        }
    }
`

export const Menu = styled.div`
    margin-top: 24px;
`

export const MenuItem = styled.div<{active?:string}>`
    padding: 16px 24px;
    cursor: pointer;
    color: ${color.gray500};
    transition: all .1s ease-in-out;
    display: flex;
    align-items: center;
    border-radius: 8px;
    ${props => props.active === 'true' && `
        color: ${color.white};
        background-color: ${color.gray800};
    `}
    &:hover {
        color: ${color.white};
        background-color: ${color.gray800};
    }

    &:not(:last-child) {
        margin-bottom: 8px;
    }
`

export const MenuIcon = styled.div`
    display: flex;
    align-items: center;
    & svg {
        height:18px;
    }
    margin-right: 18px;
`

export const MenuText = styled.div`
    font-weight: 600;
    line-height: 100%;
`