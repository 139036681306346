import axios, { CancelToken } from 'axios'
import config from 'config'
import { getLocalData } from 'utils/localData'

export const getBox = (account:string, box: 'inbox' | 'sent', cancelToken?:CancelToken, filters?:any, after?: string, type?: 'mail' | 'tpp') => {
    return axios.post(config.API.URL + "mail/get-box", { account, box, type, after, filters }, {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        },
        cancelToken
    })
}

export const getMail = (emailAddress:string, mailAddress: string) => {
    return axios.post(config.API.URL + "mail/get-mail", {
        emailAddress,
        mailAddress
    }, {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}

export const sendMail = (from:string, recipients: any[], subject: string, content: string, key:string, tpp:boolean, reply?:string) => {

    return axios.post(config.API.URL + "mail/send", {
        from,
        recipients,
        subject,
        content,
        key,
        tpp,
        reply
    }, {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })

}

export const ipfsGet = (hash: string) => {
    return axios.get(config.API.IPFS + hash)
}

export const accessTpp = (from:string, mailAddress: string, key: string, accepted:boolean) => {

    return axios.post(config.API.URL + "mail/access-tpp", {
        from,
        mailAddress,
        key,
        accepted
    }, {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}

export const voteTpp = (from:string,mailAddress: string, key: string, requestType: string) => {

    return axios.post(config.API.URL + "mail/vote-tpp", {
        from,
        mailAddress,
        key,
        requestType
    }, {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}

export const deleteNormalMail = (from:string, mailAddress: string) => {
    return axios.post(config.API.URL + "mail/delete-mail", {
        from,
        mailAddress,
    }, {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}

export const bulkDeleteMails = (from:string, mailAddresses: string[]) => {
    return axios.post(config.API.URL + "mail/bulk-delete", {
        from,
        mailAddresses,
    }, {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}

export const bulkMarkMailsAsRead = (from:string, mailAddresses: string[]) => {
    return axios.post(config.API.URL + "mail/bulk-mark-as-read", {
        from,
        mailAddresses,
    }, {
        headers: {
            Authorization: "Bearer " + getLocalData("token")
        }
    })
}